$a: '.bk-double-pics';

#{$a} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media('>=tablet') {
        flex-direction: row;
    }

    figure {
        margin: rem($spacer-s);

        img {
            border-radius: rem(12px);

            @include media('>=tablet') {
                margin: 0 rem($spacer-s);
            }
        }

        figcaption {
            p {
                text-align: left;
                margin: 0;

                @include media('>=tablet') {
                    text-align: center;
                }
            }
        }
    }
}
