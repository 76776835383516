$c: '.bk-image';

#{$c} {
    figure {
        margin: 0 auto;

        img {
            display: block;
            width: 100%;
        }

        figcaption {
            font-size: rem(12px);
            line-height: normal;
            color: $light-grey;
            text-align: right;
        }
    }

}
