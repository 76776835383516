$a: '.bk-list';

#{$a} {
    h2 {
        margin-bottom: rem($spacer-m2);
    }

    &.horizontal {
        max-width: rem($sc-max-width);
        margin: 0 auto;
        padding: rem($spacer-s) rem($sc-x-padding);

        h2 {
            margin-bottom: rem($spacer-l);
        }

        ul {
            display: flex;
            flex-direction: column;
            margin: 0;

            @include media('>=tablet') {
                flex-direction: row;
                justify-content: space-between;
            }

            li {
                &:before {
                    color: $blue;
                    font-size: rem(16px);
                }

                &.checked {
                    padding-left: rem(40px);
                    margin-bottom: rem($spacer-m);

                    @include media('>=tablet') {
                        margin-bottom: rem($spacer-xs);
                    }

                    &:before {
                        @include icomoon;
                        content: $icon-checked;
                        color: $white;
                        background-color: $color-secondary;
                        height: rem(28px);
                        width: rem(28px);
                        line-height: rem(28px);
                        font-size: rem(18px);
                        text-align: center;
                    }
                }

                a {
                    margin-bottom: rem($spacer-xxs);
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}
