/* stylelint-disable selector-max-id */
/*
 * Drupal styles
 */

#admin-menu, //Drupal 7
#toolbar-administration, //Drupal 8
#wpadminbar { //Wordpress
    z-index: 9999;

    #admin-menu-wrapper {
        overflow: inherit;
    }

    ul {
        @extend %no-styled-list;
    }
}


/* ============================== */
/* Admin tabs */
/* ============================== */
.tabs-primary {
    &.tabs {
        &.primary {
            @extend %no-styled-list;
            text-align: center;

            li {
                display: inline-block;
            }

            a {
                @extend %btn;
            }
        }
    }
}


/* ============================== */
/* AJAX */
/* ============================== */
.ajax-progress {
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin: 0 0 0 -20px;
    width: 40px;
    height: 40px;
    background-color: rgb(153, 153, 153);
    border-radius: 100%;
    animation: sk-scaleout 1s infinite ease-in-out;

    .throbber {
        display: none;
    }

    &.ajax-progress-fullscreen {
        // background: blue;
        background-position: center;
        background-size: auto;
        background-image: url('../../assets/svg/logo-X.svg');
        animation: rotate .8s infinite ease-in-out;
    }
}
// <div class='ajax-progress ajax-progress-fullscreen'><div class='throbber'></div></div>

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes sk-scaleout {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.js-pager__items {
    width: 100%;

    .grid & {
        padding-left: $gutter-w;
    }

    li {
        padding-left: 0;
        margin-left: 0;

        &:before {
            display: none;
        }
    }
}

// Drupal pagination
.pager {
    a {
        text-decoration: none;
    }

    li {
        margin: 0 rem($spacer-xs);

        @include media('>=tablet') {
            margin: 0 rem($spacer-s);
        }
    }

    .is-active {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-primary;
        color: $white;
        height: rem(32px);
        width: rem(32px);
        padding: 0;
        border-radius: 50%;
        border: 0;
        flex-shrink: 0;

        @include media('>=tablet') {
            margin: 0 rem($spacer-xxs);
        }
    }

    &__item {
        &--first,
        &--last {
            display: none;
        }

        &--previous,
        &--next {
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }

                span {
                    &:last-of-type {
                        @include media('<tablet') {
                            display: none;
                        }
                    }
                }
            }
        }

        &--previous {
            a {
                &::before {
                    @include icomoon;
                    content: $icon-arrow-l;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: rem(45px);
                    width: rem(25px);
                    padding: 0;
                    margin: 0;
                    font-size: rem(28px);

                    @include media('>=tablet') {
                        margin-right: rem($spacer-xs);
                    }
                }
            }
        }

        &--next {
            a {
                &::after {
                    @include icomoon;
                    content: $icon-arrow-r;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: rem(30px);
                    width: rem(30px);
                    padding: 0;
                    margin: 0;
                    font-size: rem(28px);

                    @include media('>=tablet') {
                        margin-left: rem($spacer-xs);
                    }
                }
            }
        }
    }

    &__items {
        @extend %no-styled-list;
        display: flex;
        justify-content: center;
        align-items: center;

        // li {
        //     &[class*='pager__item--ellipsis'] {
        //         &:not(:last-of-type) {
        //             display: none;
        //         }
        //         &:last-of-type {
        //             display: list-item;
        //         }
        //     }
        // }
    }
}

// Tel input
.iti {
    &__country-list {
        @extend %no-styled-list
    }
}

.wpml-ls {
    @extend %no-styled-list;

    &-item {
        a {
            padding: rem(5px) rem(10px) !important;
        }
    }

    &-item-toggle {
        .wpml-ls-native {
            color: $color-primary !important;
        }

        &:after {
            display: none !important;
        }
    }

    &-legacy-dropdown,
    &-legacy-dropdown-click {
        width: auto !important;

        &:hover {
            .wpml-ls-item-toggle {
                background-color: transparent !important;
            }
        }
    }

    &-sub-menu {
        margin-top: rem($spacer-s) !important;
        visibility: visible !important;
        position: relative !important;
        border: 0 !important;

        li {
            a {
                border: 0 !important;
                color: $dark-grey !important;

                &:hover {
                    background-color: transparent !important;
                    color: inherit !important;
                }

                span {
                    color: $dark-grey !important;
                }
            }
        }
    }

    ul {
        margin-bottom: 0 !important;

        li {
            a {
                border: 0 !important;
                font-size: rem(14px) !important;
                text-decoration: none !important;
                text-transform: capitalize !important;

                &:hover {
                    background-color: transparent !important;
                    color: inherit !important;
                }
            }
        }
    }
}

.is-search-form {
    justify-content: flex-start;
    border-radius: 50px;
    border: 1px solid #ccf;
    margin-bottom: rem($spacer-m2);

    label {
        .is-search-input {
            border-radius: 50px !important;
            padding: rem($spacer-s) rem($spacer-m) !important;
            border: 0 !important;
            background: transparent !important;
            border-color: transparent !important;
            color: $color-text !important;
        }
    }

    button {
        margin-left: rem(-35px);
        z-index: 1;

        .is-search-icon {
            background: transparent;
            border: 0;
        }
    }
}

/* stylelint-disable selector-class-patern */
.wpcf7 {

    &-spinner {
        display: none !important;
    }

    &-not-valid {
        border: 1px solid #dc3232 !important;

        .kc_captcha & {
            border: none !important;

            .captcha-image {
                border: 1px solid #dc3232 !important;
            }
        }

        &-tip {
            font-family: $default-fonts;
        }
    }

    &-response-output {
        font-weight: 700;
        border: none !important;
        color: #dc3232 !important;
        text-align: right;
    }

    &-form {
        &.sent {
            .wpcf7-response-output {
                color: $color-success !important;
            }
        }
    }

    .form-captcha {
        position: relative;
        float: right;

        .captcha-image {
            margin-bottom: rem($spacer-m);
        }

        .has-spinner {
            margin-bottom: rem($spacer-m);

            @include media('>=tablet') {
                float: right;
            }
        }
    }

    &-list-item {
        &.first {
            margin-left: 0;
        }

        &-label {
            font-weight: 400;
            font-family: $default-fonts;
            color: $color-text;
        }
    }

    &-form {
        .upper-form {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            @include media('>=tablet') {
                flex-flow: row wrap;
            }

            label {
                flex: 0 1 48%;
            }
        }

        input {
            margin-top: rem(10px);

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }

            &[type='radio'],
            &[type='checkbox'] {
                margin-top: 0;
            }

            &[type='text'],
            &[type='tel'],
            &[type='number'],
            &[name='your-ref'],
            &[type='email'] {
                padding: rem(20px) rem($spacer-m);
                border-radius: rem(8px);
            }
        }

        textarea {
            margin-top: rem(10px);
        }

        label {
            margin-bottom: rem($spacer-l);

            &[for='your-account'] {
                margin-bottom: rem($spacer-m);
            }
        }

        .wpcf7-submit {
            align-items: center;
            justify-content: center;
            padding: rem(17px) rem(40px) rem(16px) rem(40px);
            background-color: $color-primary;
            border: 0;
            border-radius: rem($btn-radius);
            color: $white;
            font-size: rem(14px);
            line-height: 1.2;
            text-decoration: none;
            text-align: center;
            transition: .2s;
            appearance: none;
            font-family: $secondary-fonts;
            font-weight: 600;
            min-width: none;

            @include media('>=tablet') {
                min-width: rem(272px);
            }

            @include media('>=medium') {
                min-width: rem(307px);
                font-size: rem(16px);
            }

            &:hover,
            &:focus {
                &:not([disabled]) {
                    color: $white;
                    background-color: #3351E0;
                }
            }
        }
    }
}

.trustpilot-widget {
    margin-bottom: rem(24px);

    iframe {
        width: auto !important;

        #tp-widget-wrapper,
        .tp-widget-wrapper {
            margin: 0 !important;
            text-align: left !important;
        }
    }
}

#tp-widget-wrapper,
.tp-widget-wrapper {
    margin: 0 !important;
    text-align: left !important;
}

.is-ajax-search-details,
.is-ajax-search-post {
    border-bottom: 0 !important;
}

.is-ajax-search-details .is-title a,
.is-ajax-search-post .is-title a {
    font-family: $default-fonts !important;
    text-transform: none !important;
    color: $color-text !important;
    font-weight: 400 !important;

    .is-highlight {
        font-weight: 700 !important;
        font-size: 14px !important;
        background-color: transparent !important;
        color: $color-text !important;
    }
}

.is-ajax-search-items {
    border: none !important;
    box-shadow: 0px 12px 24px 0px rgba(102, 109, 146, .2) !important;
    border-radius: 8px !important;
}

div.is-ajax-search-details,
div.is-ajax-search-result {
    padding: 0 !important;
    background: none !important;
}

.is-show-more-results {
    margin-top: 0 !important;
    border-radius: 8px !important;
}

.bk-nl {
    h2 {
        margin-bottom: rem($spacer-m) !important;
    }

    .description {
        margin-bottom: rem($spacer-m);
    }

    .formulaire {
        .hs-form-field {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            label {
                font-size: rem(14px);
                margin-bottom: rem($spacer-xxs);
            }

            .input {
                width: 100%;
            }
        }

        .legal-consent-container {
            margin-top: rem($spacer-m);

            li {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }

            .hs-richtext {
                * {
                    font-family: $default-fonts;
                    font-size: rem(14px);
                    text-align: left;
                }
            }
        }
    }

    .hs-error-msgs {
        margin: 0;
        text-align: center;
        width: 100%;

        .hs-main-font-element {
            margin: rem($spacer-s) 0;
        }

        label {
            font-size: rem(16px);
            padding: rem(8px) rem(40px);
            color: #FF8BA2;
        }

        li {
            padding-left: 0;

            &:before {
                display: none;
            }
        }
    }

    .hs-submit {
        .actions {
            margin-top: rem($spacer-m);

            input[type='submit'] {
                cursor: pointer;
                border-radius: rem(100px);
                font-family: $secondary-fonts;
                font-size: rem(16px);
                font-weight: 600;

                &.hs-button {
                    padding: rem(17px) rem(40px) rem(16px) rem(40px) !important;
                    background-color: $color-primary;
                    color: $white;
                    border: 1px solid $color-primary;
                    width: 100%;

                    &:hover,
                    &:focus {
                        &:not([disabled]) {
                            color: $white !important;
                            background-color: #3351E0 !important;
                        }
                    }
                }

            }
        }
    }
}

/* stylelint-enable selector-class-patern */

.archive-description {
    text-align: center !important;
}
