$c: '.bk-quote';

#{$c} {
    margin-left: rem(-$spacer-m);

    &__author {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: rem(10px);

        &-name,
        &-job {
            margin-bottom: 0;
        }

        &-name {
            font-weight: bold;
        }

        &-job {
            margin-top: 0;
            font-style: italic;
        }
    }

    &__txt {
        blockquote {
            border: 0;
            text-align: left;

            @include media('>tablet') {
                text-align: right;
            }
        }
    }

    &--small {
        margin-left: auto;

        .bk-quote__img {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .bk-quote__author {
            align-items: center;

        }

        .bk-quote__txt {
            blockquote {
                text-align: center;
            }
        }
    }

    &--vignette,
    &--vignette--nopic {
        margin-left: auto;

        .bk-quote__txt {
            blockquote {
                text-align: left;
            }
        }

        .bk-quote__img {
            &--vignette {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: rem(10px);

                .bk-quote__author {
                    margin-left: rem($spacer-s);
                }
            }
        }
    }

    &--vignette--nopic {
        .bk-quote__author {
            align-items: flex-start;
            padding: rem(10px);
        }
    }
}
