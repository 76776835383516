.bk-img-text {
    @include bk-type(m);
    @include clearfix;

    .img-wrapper {
        max-width: 50%;
        margin-bottom: rem(20px);

        .legend {
            font-style: italic;
        }
    }

    ul,
    ol {
        overflow: hidden;
    }

    &:not(.reversed) {
        .img-wrapper {
            margin-right: rem(30px);
            float: left;
        }
    }

    &.reversed {
        .img-wrapper {
            margin-left: rem(30px);
            float: right;
        }
    }

    &.-flex-mode {
        display: flex;
        align-items: center;

        &.reversed {
            flex-direction: row-reverse;
        }

        .img-wrapper {
            flex-shrink: 0;
        }
    }

}
