.inputfile {
    &__field {
        @extend %out;

        &:focus {
            +label {
                [data-whatinput='keyboard'] & {
                    @include outline
                }

                .inputfile__wrapper-result {
                    border-color: $black;
                }
            }
        }

        &+label {
            display: flex;
            text-overflow: ellipsis;
            cursor: pointer;
            font-size: rem(16px);
            font-weight: normal;
            border-radius: $input-radius;

            .inputfile__wrapper-result {
                position: relative;
                width: 100%;
                padding: rem($spacer-xxs) rem($spacer-s);
                border: 1px solid $input-border-color;
                background-color: $white;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                border-top-left-radius: $input-radius;
                border-bottom-left-radius: $input-radius;
                text-transform: none;

                .inputfile__result {
                    &:after {
                        content: '|';
                        opacity: 0;
                    }
                }
            }

            .inputfile__text {
                display: flex;
                align-items: center;
                padding: rem($spacer-xxs) rem($spacer-xs);
                text-align: center;
                border-radius: 0;
                border-top-right-radius: $input-radius;
                border-bottom-right-radius: $input-radius;
            }

            .inputfile__delete {
                position: absolute;
                display: none;
                background: none;
                border: 0;
                top: 50%;
                transform: translate(0, -50%);
                right: 20px;

                &::before {
                    content: 'X';
                    display: block;
                    color: $color-primary;
                    text-indent: 0;
                }
            }
        }
    }
}
