$a: '.bk-card';

#{$a} {
    @include bk-type;
    border: 1px solid $color-border;
    border-radius: $global-radius;
    overflow: hidden;

    &__img {
        img {
            display: block;
        }
    }

    &__content {
        background-color: $white;
        border-top: 0;
        padding: $spacer-xs;

        div {
            margin-bottom: $spacer-xxs;
        }
    }

    &__typo {
        border-left: 4px solid $black;
        padding-left: $spacer-xxs;
    }

    &__title {
        font-size: rem(18px);
        margin: rem($spacer-s) 0;
    }

    &__taxo {
        color: $color-primary;
        text-transform: uppercase;
    }

    &--xl {
        @include media('>=tablet') {
            #{$a} {
                &__img {
                    img {
                        @include object-fit('cover');
                        height: 100%;
                    }
                }

                &__title {
                    font-size: rem(20px);
                }
            }
        }
    }

    &-pro {
        border: 1px solid $blue20;
        border-radius: rem(12px);
        margin-bottom: rem($spacer-s);
        align-self: stretch;

        .grid__cell & {
            height: 100%;
        }

        a {
            padding: rem($spacer-s) rem($spacer-m);
            width: 100%;
            text-decoration: none;

            ul {
                margin: 0;
                margin-top: rem($spacer-s) ;

                li {
                    @extend %body-small;
                }
            }
        }

        .text-content {
            @extend %body-small;
        }

        &:hover,
        &:focus {
            &:not([disabled]) {
                span {
                    &[class*='icon-arrow-'] {
                        transform: translateX(8px);
                        transition-duration: .5s;
                    }
                }
            }
        }

        .bk-card__img {
            margin-bottom: rem($spacer-xs);
        }

        &__title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            h4 {
                margin-bottom: 0;
            }

            span {
                &[class*='icon-arrow-'] {
                    font-size: rem(28px);
                    color: $color-primary;
                    transition-duration: .5s;
                }
            }
        }
    }

    &-avantages {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: rem($spacer-s) 0;

        @include media('>=tablet') {
            margin: 0 rem($spacer-m2);
            flex-basis: 33%;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &__img {
            position: relative;
            width: rem(48px);
            height: rem(48px);

            @include media('>=tablet') {
                width: rem(72px);
                height: rem(72px);
            }

            &.bordered {
                border: 1px solid $yellow;
                border-radius: 50px;
            }

            &.checked {
                width: rem(28px);
                height: rem(28px);
                background-color: $yellow;

                &:after {
                    @include icomoon;
                    content: $icon-checked;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $white;
                    font-size: 12px;
                }

                img {
                    display: none;
                }
            }

            img {
                margin-bottom: rem($spacer-xs);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        h4,
        .like-h4 {
            margin-top: rem($spacer-s);
            margin-bottom: rem($spacer-xxs);
            font-size: rem(18px);
            font-weight: 600;
            font-family: $secondary-fonts;
            color: $color-primary;

            @include media('>=tablet') {
                font-size: rem(22px);
            }
        }

        p,
        a {
            @extend %body-medium;
            margin-top: 0;
            margin-bottom: rem($spacer-xxs);
        }

        .savoir-plus {
            font-weight: 600;
            color: $color-primary !important;
            text-decoration: underline;

            span {
                margin-left: rem($spacer-xxs);
                font-size: rem(16px);
            }
        }

        &--logo {
            padding: rem(40px);
            border: 1px solid $blue20;
            border-radius: rem(12px);

            @include media('>=tablet') {
                margin: 0 rem($spacer-s);
            }

            p {
                line-height: rem(25px);

                .int {
                    font-weight: 500;
                    font-size: rem(20px);
                }

                &:last-of-type {
                    margin-bottom: rem($spacer-m2);
                }
            }

            a {
                font-size: rem(16px);
                line-height: rem(20px);
            }

            #{$a}-avantages {
                &__img {
                    border: 0;
                    width: auto;
                    height: auto;

                    img {
                        position: relative;
                        top: auto;
                        left: auto;
                        transform: none;
                    }
                }
            }
        }
    }

    &--large {
        border: 1px solid $blue50;
        border-radius: rem(12px);
        background-color: $white;
        padding: rem($spacer-m);

        @include media('>=medium') {
            padding: rem($spacer-m2) rem($spacer-l);
        }

        a {
            width: 100%;
            text-decoration: none;
        }

        &:hover {
            border: 1px solid $color-primary;
            cursor: pointer;

            .title {
                span,
                img {
                    transition-duration: 500ms;
                    transform: translateX(-10px);
                }
            }
        }

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            h4 {
                margin-bottom: 0;
                text-transform: capitalize;
            }

            p {
                font-family: $default-fonts;
            }

            img,
            span {
                transition-duration: 500ms;
            }

            .icon-arrow-line-r {
                font-size: 32px;
            }
        }
    }

    &--icon {
        text-align: center;
        text-decoration: none;
        background-color: $white;
        border: 1px solid $blue20;
        border-radius: rem(12px);
        padding: rem($spacer-m);

        @include media('>=tablet') {
            padding: rem($spacer-m2) rem($spacer-xxl);
        }

        h4 {
            margin-bottom: rem($spacer-xxs);
        }

        p {
            font-family: $default-fonts;
            font-size: rem(20px);
            font-weight: 400;
            color: $color-text;
            margin: 0;

            @include media('>=tablet') {
                margin-top: 0;
                margin-bottom: auto;
            }
        }

        &__img {
            position: relative;
            border: 1px solid $yellow;
            border-radius: 50px;
            width: rem(72px);
            height: rem(72px);
            margin: 0 auto;

            img {
                margin-bottom: rem($spacer-xs);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &-select {
        padding: rem($spacer-l) rem($spacer-m);
        background: linear-gradient(135deg, $blue03 85%, $blue 85%);
        border-radius: 0;
        position: relative;

        a {
            text-decoration: none;
        }

        .bk-card--large {
            z-index: 2;
            margin-top: rem($spacer-s);

            p {
                @extend %body-medium;
            }

            @include media('>=desktop') {
                margin-top: auto;
            }
        }

        @include media('>=tablet') {
            border-radius: rem(20px);
            padding: rem($spacer-xxl) rem($spacer-xxl) rem($spacer-l) rem(80px);
        }

        &__left {
            margin-bottom: 0;

            .js-tabs {
                padding-left: 0 !important;
                margin-left: rem(-$spacer-m);
                margin-right: rem(-$spacer-m);

                @include media('>=tablet') {
                    margin-left: auto;
                    margin-right: auto;
                }

                li {
                    font-family: $default-fonts;

                    &:hover {
                        background-color: #3351E0;
                    }

                    &[aria-selected='true'] {
                        color: $white;
                        background-color: $color-primary !important;
                    }
                }

                .slick-track {
                    display: flex;

                    .slick-slide {
                        margin-right: rem(12px);
                        display: flex;
                        height: auto;
                        align-items: center;
                        justify-content: center;
                    }
                }

                @include media('>=tablet') {
                    padding-left: rem($spacer-m);

                    .slick-slide {
                        margin-right: 0;
                    }
                }

            }

            p,
            h3 {
                max-width: none;

                @include media('>=desktop') {
                    max-width: rem(520px);
                }
            }

            h3 {
                font-size: rem(22px);
                margin-bottom: rem($spacer-m);

                @include media('>=medium') {
                    font-size: rem(28px);
                }
            }

            p {
                @extend %body-medium;
                margin-bottom: rem($spacer-m2);

                @include media('>=desktop') {
                    margin-bottom: rem(40px);
                }
            }
        }

        &__right {
            position: relative;
            margin-bottom: 0;

            .compare-btn {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                background-color: $white !important;
                margin: rem($spacer-m) 0 0 0 !important;
                font-family: $default-fonts;

                &:hover {
                    background-color: #3351E0 !important;
                }

                &:focus {
                    color: $color-primary;
                }
            }
        }

        .btn {
            min-width: 0;
            margin-bottom: rem($spacer-s);
            margin-right: rem($spacer-s);
            padding: rem($spacer-s);
            font-size: rem(16px);
            font-weight: 400;
            background-color: transparent;
            // width: auto !important;

            @include media('>=tablet') {
                padding: rem($spacer-s) rem($spacer-m);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &-security {
        padding: rem(40px) rem($spacer-m);
        background: linear-gradient(135deg, $blue 88%, $yellow 88%);
        border-radius: 0;
        position: relative;
        color: $white;

        .grid__cell {
            margin-bottom: 0 !important;
        }

        @include media('>=tablet') {
            border-radius: rem(20px);
            padding: rem($spacer-xxl) rem(80px);
        }

        &--logo {
            background: linear-gradient(135deg, $blue 80%, $yellow 80%);
        }

        p,
        h2,
        li {
            color: $white !important;
        }

        h2 {
            margin-bottom: rem($spacer-m);
        }

        p,
        li {
            @extend %body-xlarge;
        }

        // .checked,
        li {
            position: relative;
            padding-left: rem($spacer-m2);

            &.not-checked {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }

            &:before {
                @include icomoon;
                content: $icon-checked;
                background-color: transparent;
                position: absolute;
                left: 0;
                top: 8px;
                font-size: 20px;
                color: $white;
            }
        }

        picture {
            &.corner {
                position: absolute;
                bottom: rem($spacer-xxs);
                right: rem($spacer-xxs);

                @include media('>=tablet') {
                    bottom: rem($spacer-m);
                    right: rem($spacer-m);
                }
            }
        }

        .btn {
            margin-bottom: rem($spacer-m2);
            width: 100%;

            @include media('>=tablet') {
                width: auto;
            }

            @include media('>=desktop') {
                margin-bottom: auto;
            }
        }
    }

    &-actu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: rem($spacer-xxs);
        flex: 1;

        @include media('>=desktop') {
            margin-bottom: auto;
        }

        &__top {
            position: relative;
            margin-bottom: rem(20px);

            img {
                display: block;
                margin: 0 auto;
                border-radius: rem(8px);
                width: 100%;

                @include media('>=tablet') {
                    width: auto;
                }
            }

            a {
                text-decoration: none;
                border-bottom: 0;
            }

        }

        &__body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1 0 auto;

            &__date {
                font-size: rem(16px);
                color: $grey;
                margin-bottom: rem($spacer-xxs);
            }

            &__title {
                margin-bottom: 0;
                padding-bottom: rem($spacer-xs);
                text-decoration: none;
                font-size: rem(22px);
                font-family: $secondary-fonts;
                color: $color-primary;
                font-weight: 600;

                &:hover {
                    color: $blue;
                }
            }

            &__teaser {
                @extend %body-medium;
                color: $grey;
            }
        }
    }

    &-contact {
        padding: rem(40px) rem($spacer-m);
        background: linear-gradient(45deg, $yellow 12%, $blue 12%, $blue 88%, $yellow 88%);
        border-radius: 0;
        position: relative;
        color: $white;
        text-align: center;

        @include media('>=tablet') {
            border-radius: rem(20px);
            padding: rem($spacer-xl);
        }

        h2,
        p {
            max-width: rem(750px);
            color: $white;
            margin: rem(40px) auto;
        }

        p {
            margin-bottom: rem(24px);
            font-size: rem(16px);
            font-weight: 500;

            @include media('>=tablet') {
                font-size: rem(24px);
            }
        }

        .btn {
            width: 100%;

            @include media('>=tablet') {
                width: auto;
            }
        }
    }

    &-information {
        background-color: $blue03;
        padding: rem($spacer-m2);
        text-align: center;
        border-radius: rem(20px);

        .title {
            margin-bottom: rem($spacer-m);
        }

        .btn {
            border: 1px solid $blue;
        }

        @include media('>=tablet') {
            padding: rem($spacer-l);
        }

        .paragraph-full-width & {
            border-radius: 0;
        }
    }

    &-avis {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: rem($spacer-xxs);
        position: relative;

        @include media('>=tablet') {
            padding: rem($spacer-xxl) rem(180px);
        }

        &__id {
            position: relative;
            padding-left: rem(75px);
            margin-bottom: rem($spacer-m);

            &:before {
                @include media('>=tablet') {
                    content: '';
                    position: absolute;
                    left: 0;
                    background-color: $yellow;
                    border-radius: rem(20px);
                    width: rem(60px);
                    height: rem(60px);
                }
            }

            &:after {
                @include media('>=tablet') {
                    content: '“';
                    position: absolute;
                    display: block;
                    top: 14px;
                    left: 14px;
                    color: $white;
                    font-family: $secondary-fonts;
                    font-size: rem(60px);
                    font-weight: bold;
                    line-height: 1em;
                }
            }

            @include media('>=tablet') {
                margin-bottom: rem($spacer-m2);
            }

            .name {
                color: $color-text;
                margin-bottom: rem(calc($spacer-xxs / 2));
            }

            .function {
                margin: 0;
                color: $grey;
            }
        }

        .content {
            @extend %body-xlarge;
            padding-right: rem($spacer-xs);
        }

        &__img {
            position: absolute;
            left: 0;
            top: rem(15px);

            @include media('>=tablet') {
                position: relative;
                padding: rem(50px);
                left: auto;
                top: auto;
                flex-shrink: 0;
            }

            img {
                transform: scale(.4);

                @include media('>=tablet') {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%) scale(1);
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                border: 1px solid $light-grey;
                border-radius: rem(20px);
                box-shadow: 2px 2px 0 $yellow;
                width: rem(60px);
                height: rem(60px);

                @include media('>=tablet') {
                    width: rem(160px);
                    height: rem(160px);
                    box-shadow: 10px 10px 0 $yellow;
                }
            }
        }
    }

    &-tarif {
        padding: 0 rem($spacer-m);
        border: 1px solid $yellow;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &.blue {
            border: 1px solid $blue20;
        }

        &__descr,
        &__list {
            padding: rem($spacer-m) 0;
            display: flex;
            flex-direction: column;
        }

        &__descr {
            border-bottom: 1px solid $lightest-grey;
            justify-content: space-between;
        }

        &__list {
            justify-content: space-between;
            flex: 1 0 auto;
        }

        p {
            margin: 0;
        }

        .btn {
            min-width: 0;

            @include media('>=desktop') {
                width: 100%;
            }
        }

        .like-h5 {
            margin-bottom: rem($spacer-xxs) ;
        }

        .subtitle {
            margin-bottom: rem($spacer-xxs);
            font-size: rem(14px);
            font-weight: 400;
        }

        .tarif {
            margin: rem($spacer-xxs) 0 0;
            font-size: rem(25px);
            font-weight: 600;
            color: $blue;
            font-family: $secondary-fonts;

            span {
                font-size: rem(18px);
                font-weight: 500;
                text-transform: uppercase;
                padding-left: rem($spacer-xxs);
            }
        }

        .ttc {
            font-size: rem(16px);
            font-weight: 600;
            text-align: left;
            margin-top: 0;
            margin-bottom: rem($spacer-s);
            font-family: $secondary-fonts;
            color: $grey;

            &:after {
                content: ' TTC';
                font-size: rem(12px);
            }
        }

        .list-title {
            margin-bottom: rem($spacer-xxs);
            font-size: rem(16px);
            font-weight: 500;
        }

        ul {
            margin-top: rem(16px);

            li {
                font-size: rem(14px);
                color: $grey;
                padding-left: rem($spacer-m2);
                margin-bottom: rem($spacer-s);

                a {
                    font-size: rem(14px);
                    color: $grey;
                    font-weight: 400;
                    font-family: $default-fonts;
                }

                &:before {
                    @include icomoon;
                    content: $icon-checked;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: rem($spacer-m);
                    width: rem($spacer-m);
                    height: rem($spacer-m);
                }
            }
        }

        .savoir-plus {
            text-align: center;
            font-size: rem(14px);
        }

        &.solo {
            @include media('>=tablet') {
                position: relative;
                display: flex;
                flex-direction: row;

                .bk-card-tarif__descr {
                    border-bottom: 0;
                    margin-right: rem($spacer-m);
                    justify-content: flex-start;

                    .subtitle {
                        margin-bottom: rem($spacer-m);
                    }

                    .btn {
                        max-width: rem(245px);
                    }
                }

                .bk-card-tarif__list {
                    ul {
                        max-height: rem(95px);
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        align-content: flex-start;
                        flex-wrap: wrap;

                        li {
                            margin-right: rem($spacer-s);
                        }
                    }
                }

                .tarif,
                .ttc,
                .savoir-plus {
                    margin: 0;
                    position: absolute;
                    right: rem($spacer-m);
                    top: rem($spacer-m);
                }

                .ttc {
                    top: rem(65px);
                }

                .savoir-plus {
                    top: auto;
                    bottom: rem($spacer-m);
                }

                &--thirds {
                    .bk-card-tarif__descr {
                        max-width: rem(265px);
                    }
                }

                &--bigger {
                    min-height: rem(200px);

                    .bk-card-tarif__descr {
                        .tarif {
                            top: calc(50% - 24px);
                            transform: translateY(-50%);
                            font-size: rem(40px);
                            max-width: rem(245px);
                            right: auto;
                            left: calc(100% - 269px);

                            &:before {
                                content: 'à partir de:';
                                position: absolute;
                                top: rem(-22px);
                                font-family: $default-fonts;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;
                                letter-spacing: 0em;
                            }
                        }

                        .btn {
                            position: absolute;
                            top: auto;
                            right: rem($spacer-m);
                            bottom: rem($spacer-m);
                        }
                    }

                    .bk-card-tarif__list {
                        p,
                        ul {
                            display: none;
                        }

                        .savoir-plus {
                            left: rem($spacer-m);
                            right: auto;
                        }
                    }
                }
            }
        }
    }

    &-offre {
        padding: rem($spacer-m);
        border: 1px solid $yellow;
        border-radius: 8px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: rem($spacer-m);

        &[class*='bg'] {
            background-color: $blue03;
            border-color: $blue20;
        }

        p {
            margin: 0;
            font-size: rem(14px);
        }

        .title {
            margin-bottom: rem($spacer-xxs);
            font-size: rem(18px);
        }

        .subtitle {
            font-family: $secondary-fonts;
            font-weight: 600;
            margin-bottom: rem($spacer-l);
        }

        .tarif {
            font-size: rem(25px);
            font-weight: 600;
            color: $blue;
            position: relative;
            margin-bottom: rem(4px);
            font-family: $secondary-fonts;

            &:before {
                content: 'crédit de:';
                position: absolute;
                text-align: center;
                font-weight: 400;
                font-size: rem(14px);
                left: 50%;
                top: -16px;
                transform: translateX(-50%);
            }

            span {
                font-size: rem(18px);
                font-weight: 500;
                text-transform: uppercase;
                padding-left: rem($spacer-xxs);
            }

            &-subtitle {
                margin-bottom: rem($spacer-m);
                font-weight: 600;
                color: $additional-blue;
            }
        }

        .ttc {
            font-size: rem(16px);
            font-weight: 600;
            text-align: center;
            margin-bottom: rem(4px);
            font-family: $secondary-fonts;
            color: $grey;

            &:after {
                content: ' TTC';
                font-size: rem(12px);
            }

            &-small {
                font-weight: 500;
                text-align: right;
                font-size: rem(10px);

                &:after {
                    font-size: rem(10px);
                }
            }

        }

        .offre {
            line-height: 19px;
            margin-bottom: rem($spacer-m);
            text-align: left;

            &-ita {
                font-style: italic;
            }
        }

        .list-title {
            font-weight: 600;
        }

        .list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            font-size: rem(14px);
            margin-bottom: rem(14px);

            .list-price {
                font-family: $secondary-fonts;
                font-weight: 600;

                &:after {
                    content: 'HT';
                    font-size: rem(12px);
                    font-weight: 500;
                    margin-left: rem(4px);
                }
            }

            .list-descr {
                max-width: rem(107px);
            }
        }


        .btn {
            min-width: 0;
            margin-top: rem(40px);

            @include media('>=desktop') {
                width: 100%;
            }
        }
    }

    &-third {
        background-color: $white;
        border: 1px solid $light-grey;
        border-radius: 8px;
        padding: rem($spacer-m2) rem($spacer-m);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: rem($spacer-s);
        position: relative;

        p {
            @extend %body-medium;
            margin: 0;
            margin-bottom: rem($spacer-m);
        }

        .title {
            font-weight: 600;
            color: $blue;
            font-size: rem(22px);

            &--out {
                position: absolute;
                left: 0;
                bottom: 100%;
                margin-bottom: rem($spacer-xs);
            }
        }

        .tarif {
            font-size: rem(22px);
            font-weight: 600;
            color: $blue;
            margin-bottom: rem($spacer-xxs);

            span {
                font-size: rem(18px);
                font-weight: 500;
                text-transform: uppercase;
                padding-left: rem($spacer-xxs);
            }
        }

        .legend {
            font-weight: 400;
            font-size: rem(14px);
            margin-bottom: 0;
            color: $grey;
        }
    }
}
