$a: '.bk-mail-comparaison';

#{$a} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include media('>=tablet') {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }

    &__elem {
        text-align: left;
        padding: rem($spacer-l) 0;

        &:last-of-type {
            padding-top: 0;

            @include media('>mobile') {
                padding: rem($spacer-l) 0;
            }

            @include media('>=tablet') {
                padding: rem($spacer-l);
            }
        }

        @include media('>mobile') {
            text-align: center;
        }

        @include media('>=tablet') {
            padding: rem($spacer-l);
        }

        &__title {
            font-weight: 600;
            font-size: rem(22px);
            color: $blue;
            font-family: $secondary-fonts;
            margin-bottom: rem($spacer-s);

            @include media('>=tablet') {
                margin-bottom: rem($spacer-m);
            }

            @include media('>=medium') {
                margin-bottom: rem($spacer-m2);
            }
        }
    }
}
