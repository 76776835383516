/* stylelint-disable */
$font-family: $default_fonts;
$color-allow: $color-success;
$color-deny: $color-error;
$color-personalize: $color-secondary;
$radius-btn: 0;

// $bg-popin: $white;
$bg-popin: #333333;
$border-popin: 2px solid $color-secondary;
$bg-services: $color-secondary;
$text-color-popin: $black;
$radius-popin: 0;
$overlay-bg_color: rgba($black, .5);

body {


  //GLOBAL
  #tarteaucitronRoot {
    * {
      font-family: $font-family !important;
      font-size: $base-font-size !important;
    }
  }

//POPIN
  #tarteaucitronRoot div#tarteaucitronAlertBig {
    box-sizing: border-box !important;
    background-color: $bg-popin !important;
    box-shadow: none !important;
    border-radius: $radius-popin !important;
    // border: $border-popin;
    border: 2px solid $bg-popin;
    width: 700px !important;
    max-width: 90% !important;
    max-height: 90% !important;
    overflow: auto;
    text-align: center;

    &:before {
      display: none !important;
    }

    #tarteaucitronDisclaimerAlert {
      margin: 0 0 25px !important;
      padding: 0;
      width: 100%;
    //   color: $text-color-popin !important;
      color: $white !important;
      max-height: 45vh;
      overflow: auto;
    }
  }

  div#tarteaucitronRoot.tarteaucitronBeforeVisible:before,
  #tarteaucitronRoot button#tarteaucitronBack {
    background-color: $overlay-bg_color !important;
    opacity: 1 !important;
  }

  div#tarteaucitronServices {
    box-shadow: none !important;
    margin-top: 30px !important;

    #tarteaucitronInfo {
      border: 0;
      background-color: transparent !important;
    }
  }

  //POPIN SERVICES
  #tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
    border-color: $bg-services !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine,
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie, #tarteaucitron #tarteaucitronClosePanel {
    // background-color: $bg-services !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
  }
  #tarteaucitronRoot .tarteaucitronH1 {
    font-weight: bold;
  }

  // POPIN COOKIES
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
    background-color: $bg-services;

    #tarteaucitronH2 {
      margin-top: 0 !important;
    }
  }


  //BUTTON STYLE
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow, #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny, .tac_activate .tarteaucitronAllow,
  #tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitronAlertBig #tarteaucitronPersonalize, #tarteaucitronAlertBig #tarteaucitronPersonalize2, .tarteaucitronCTAButton, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog, #tarteaucitronRoot .tarteaucitronDeny, #tarteaucitronRoot .tarteaucitronAllow  {
    border-radius: $radius-btn !important;
    padding: 10px !important;
    color: $white !important;

    .tarteaucitronCheck,
    .tarteaucitronCross {
      display: none !important;
    }
  }

  //ALLOWED
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow,
  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
  #tarteaucitronPersonalize2 {
    background-color: $color-allow !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
    border-color: $color-allow !important;
  }

  //DENIED
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny,
  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed,
  #tarteaucitronAllDenied2 {
    background-color: $color-deny !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
    border-color: $color-deny !important;
  }

  //PERSONALIZE
  #tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
    background-color: $color-personalize !important;
    color: $white !important;
  }

  //BUTTONS MARGIN
  #tarteaucitronAlertBig > button {
    &#tarteaucitronCloseAlert,
    &#tarteaucitronAllDenied2,
    &#tarteaucitronPersonalize2 {
      margin: 5px !important;
      display: inline-block !important;
    }
  }

  .tarteaucitronLine:before, li[id^="tarteaucitronServicesTitle_"]:before {
    display: none !important
  }
}
/* stylelint-enable */
