$c: '.bk-chiffres-cles';

#{$c} {
    background-color: $lightest-grey;

    &__part {
        padding: rem($spacer-l) 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        &__logo {
            span {
                &::before {
                    font-size: 48px;
                }
            }
        }

        &__num,
        &__title {
            text-align: center;
            font-weight: bolder;
        }

        &__num {
            font-size: rem(30px);
        }
    }


    &--nopic {
        background-color: $lightest-grey;

        #{$c} {
            &__logo {
                display: none;
            }
        }
    }
}
