$a: '.bk-expertise';

#{$a} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .bk-card-pro {
        flex-basis: 100%;

        @include media('>=tablet') {
            flex-basis: 22%;
        }
    }

}

#{$a}--duo {
    .bk-card-pro {
        margin-bottom: rem($spacer-m2);

        @include media('>=tablet') {
            flex-basis: 48%;
        }
    }
}
