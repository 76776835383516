

$c: '.bk-comparateur';

#{$c} {
    display: none;

    @include media('>=medium') {
        display: block;
    }

    table {
        margin-bottom: 0;

        thead,
        tbody {
            tr {
                th,
                td {
                    font-size: rem(16px);

                    &:not(:first-of-type) {
                        padding: rem($spacer-m) rem($spacer-s);
                        text-align: center;
                        width: 19.5%;
                    }

                    &:nth-child(even) {
                        background-color: $blue03;
                        color: $blue;
                    }

                    &:first-of-type {
                        padding: 0;
                        color: $color-text !important;
                        width: rem(300px);
                    }
                }
            }
        }

        thead {
            th {
                vertical-align: top;

                &.thtitle {
                    font-weight: 600;
                    font-size: rem(20px);
                    font-family: $secondary-fonts;
                    color: $color-primary !important;
                    padding-top: rem($spacer-m);
                }

                &.top {
                    position: relative;
                    padding-bottom: rem(70px) !important;

                    p {
                        font-weight: 600;
                        font-size: rem(14px);
                        margin-bottom: rem($spacer-xs);
                    }

                    .col-title {
                        font-family: $secondary-fonts;
                    }

                    .subtitle {
                        font-weight: 400;
                        font-size: rem($spacer-xs);
                        display: none;

                        @include media('>=medium') {
                            display: block;
                        }
                    }

                    .btn {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: calc(100% - 24px);
                        min-width: 0;
                        font-size: rem(14px);
                        font-family: $secondary-fonts;
                        padding: rem(14px) rem($spacer-m2);
                        color: $white !important;
                    }
                }
            }
        }

        tbody {
            .fold-title,
            .fold-item {
                border-bottom: 1px solid $lightest-grey;
            }

            .fold-title {
                font-size: rem(18px);
                font-weight: 500;
            }

            .icon-checked-comp {
                &:before {
                    font-size: rem(15px);
                }
            }

            .fold-item {
                a {
                    font-size: 12px;
                    font-weight: 400;
                    text-align: center;
                }

                &--price {
                    font-size: rem(14px);
                    font-weight: 700;

                    @include media('>=medium') {
                        font-size: rem(20px);
                    }
                }

                p {
                    margin-bottom: 0;
                }

                .ttc {
                    font-size: rem(14px);
                    font-weight: 400;
                    text-align: center;
                    margin-top: 0;
                    color: $grey;

                    &:after {
                        content: ' TTC';
                        font-size: rem(14px);
                    }
                }

                .ht {
                    font-size: rem(14px);
                    font-weight: 700;

                    @include media('>=medium') {
                        font-size: rem(20px);
                    }

                    &:after {
                        content: ' HT';
                    }
                }
            }
        }
    }
}

#{$c}--mobile {
    display: block;

    @include media('>=medium') {
        display: none;
    }

    .subtitles--mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .subtitle--mobile {
            flex: 0 1 49%;
            margin: 0 rem($spacer-xxs) rem($spacer-xxs);
            text-align: center;
            font-weight: 400;
            font-size: rem($spacer-xs);
        }
    }

    #{$c} {
        &__select {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .styled-select {
                flex: 0 1 49%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                margin-bottom: rem($spacer-s);

                &:before {
                    font-size: rem(22px);
                    max-width: rem($spacer-xs);
                    justify-content: flex-end;
                }

                &.--blue {
                    select {
                        border-color: $color-primary;
                    }

                    &:before {
                        color: $color-primary;
                    }
                }

                select {
                    white-space: break-spaces;
                }
            }
        }

        &__btns {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .btn {
                flex: 0 1 49%;
                font-size: rem(10px);
                padding: rem(10px) rem(18px);
                margin: 0 rem(4px) rem($spacer-l);
            }
        }
    }

    .view {
        .icon-accordion {
            color: $color-primary;
            font-size: rem(30px);
            transition: .2s;
            margin-left: rem(25px);

            &:before {
                content: $icon-arrow-b;
            }

            @include media('>=medium') {
                display: none;
            }
        }

        &.hidden {
            margin-bottom: rem($spacer-s);

            .icon-accordion {
                transform: rotate(180deg) !important;
            }
        }
    }


    .fold {
        display: block;
        margin-bottom: rem(40px);

        &.hidden {
            display: none;
        }

        p {
            margin: rem($spacer-s) 0 rem($spacer-xxs);
        }

        span {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 0;
            margin-bottom: rem($spacer-xxs);

            .fold-item-mobile {
                display: flex;
                flex-direction: column;
                align-self: stretch;
                align-items: center;
                justify-content: center;
                background-color: $grey10;
                padding: rem($spacer-xs) rem(10px);
                flex: 0 1 49%;
                font-size: rem(14px);
                text-align: center;
                border-radius: rem(4px);

                &:last-of-type {
                    background-color: $blue05;
                    color: $blue;
                }

                &-content {
                    margin: 0;

                    &.ht {
                        &:after {
                            content: ' HT';
                        }
                    }
                }

                &-ttc {
                    font-size: rem(14px);
                    font-weight: 400;
                    text-align: center;
                    margin-top: 0;
                    color: $grey;

                    &.empty {
                        display: none;

                        &:after {
                            display: none;
                        }
                    }

                    &:after {
                        content: ' TTC';
                        font-size: rem(14px);
                    }
                }
            }
        }
    }
}

#{$c}--mobile,
#{$c} {
    .view {
        border-bottom: 2px solid $light-grey;

        td {
            padding: rem($spacer-xs) 0 rem($spacer-s);
        }

        &-title {
            color: $color-primary;
            font-family: $secondary-fonts;
            font-size: rem(22px);
            font-weight: 600;
            padding-top: rem($spacer-m2);
            padding-bottom: rem($spacer-m);
            padding-left: 0;
            background: none;
            border: 0;

            &-mobile {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                text-align: left;
                color: $color-primary;
                font-family: $secondary-fonts;
                background: none;
                border: 0;
                padding: rem($spacer-xs) 0;

                &:after {
                    @include icomoon;
                    content: $icon-arrow-b;
                    color: $color-primary;
                    font-size: rem(30px);
                    transition: .2s;
                }

                &[aria-expanded='false'] {
                    &:after {
                        transform: rotate(180deg) !important;
                    }
                }
            }
        }
    }

    .description-mobile,
    .fold-item-mobile-ttc,
    .fold-item-mobile-content {
        display: none;

        &.visible {
            display: block;
            margin-bottom: 0;
        }
    }
}
