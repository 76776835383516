$a: '.bk-offer';

#{$a} {
    text-align: center;

    h2 {
        margin-bottom: rem($spacer-l);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media('>=tablet') {
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
        }
    }

    &__card {
        background-color: $blue03;
        border: 2px solid $blue10;
        border-radius: rem(12px);
        padding: rem($spacer-m);
        margin-bottom: rem($spacer-m2) !important;
        width: 100%;

        &:not(:last-of-type) {
            @include media('>=tablet') {
                margin-right: rem($spacer-m);
            }
        }

        @include media('>=tablet') {
            flex-basis: 50%;
            max-width: rem(580px);
            margin-bottom: rem($spacer-xxl) !important;
            padding: rem($spacer-xxl);
        }

        .intro {
            @extend %body-small;
        }

        .like-h1 {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 600;
        }

        .ttc {
            font-size: rem(16px);
            font-weight: 600;
            text-align: center;
            margin-top: rem($spacer-xxs);
            font-family: $secondary-fonts;
            color: $grey;

            // &:after {
            //     content: ' TTC';
            //     font-size: rem(12px);
            // }
        }
    }
}
