.parsley-errors-list {
    margin: rem(5px) 0 0;
    color: $color-error;
    font-size: rem(11px);

    li {
        padding-left: 0 !important;

        &::before {
            display: none;
        }
    }

    .no-error-msg & {
        display: none;
    }
}

// [data-parsley-error-wrapper] {
//     margin-bottom: rem(25px);

//     .radio-check & {
//         margin-bottom: 0;
//     }
// }

.radio-check + .parsley-errors-list {
    margin-top: 0;
}

[type='text'],
[type='email'],
[type='date'],
[type='datetime-local'],
[type='time'],
[type='month'],
[type='week'],
[type='password'],
[type='tel'],
[type='url'],
[type='number'],
[type='search'],
textarea,
select {
    .parsley-error & {
        border: 1px solid $color-error;
        color: $color-error;
    }
}

.inline-inputs {
    .parsley-error & {
        color: $color-error;
    }
}


.parsley-error {
    [type='checkbox'],
    [type='radio'] {
        + label {
            &::before {
                border-color: $color-error;
            }
        }
    }

    & + .radio-check {
        [type='checkbox'],
        [type='radio'] {
            + label {
                &::before {
                    border-color: $color-error;
                }
            }
        }
    }
}

.js-inputfile {
    &[type='file'] {
        .parsley-error & {
            & + label {
                .wrapper-result {
                    border-color: $color-error;
                }
            }
        }
    }
}
