@charset "UTF-8";
/*
 * app.scss
 */
/* ------------------------------------------------
- Ici, toutes les fonctions sass et les mixins
------------------------------------------------ */
/* ============================== */
/* Custom Functions */
/* ============================== */
.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.full-width {
  position: relative;
  left: 50%;
  width: 100vw;
  max-width: none;
  transform: translateX(-50%);
}

/*
 * vars.scss
 */
/* ============================== */
/* Colors */
/* ============================== */
/* ============================== */
/* Spacers */
/* ============================== */
/* ============================== */
/* Fonts
/* ============================== */
/* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
/* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
/* ============================== */
/* Breakpoints
/* ============================== */
/* ============================== */
/* Avalanche grid system
/* ============================== */
/* ------------------------------------------------
- Ici, les helpers
------------------------------------------------ */
/**
 * Automatically generate text color utilities class for each of our
 * defined contrib colors.
 *
 * <div class="color--green"></div>
 */
.color--black {
  color: #000 !important;
}

.color--white {
  color: #fff !important;
}

.color--dark-grey {
  color: #535353 !important;
}

.color--grey {
  color: #706F6F !important;
}

.color--light-grey {
  color: #E0E0E0 !important;
}

.color--primary {
  color: #000FFF !important;
}

.color--secondary {
  color: #FFCB05 !important;
}

/**
* Automatically generate background-color utilities class for each of our
 * defined contrib colors.
 *
 * <div class="bg--green"></div>
 */
.bg--black {
  background-color: #000 !important;
}

.bg--white {
  background-color: #fff !important;
}

.bg--dark-grey {
  background-color: #535353 !important;
}

.bg--grey {
  background-color: #706F6F !important;
}

.bg--light-grey {
  background-color: #E0E0E0 !important;
}

.bg--primary {
  background-color: #000FFF !important;
}

.bg--secondary {
  background-color: #FFCB05 !important;
}

.white-color {
  color: #fff;
}

.last-child-no-margin > *:last-child {
  margin-bottom: 0;
}

.bk-type-txt {
  margin-top: 2.4rem;
}
.bk-type-txt > *:last-child {
  margin-bottom: 0;
}

.bk-type,
.bk-info,
.table-responsive {
  margin-bottom: 3.2rem;
}
.bk-type > *:last-child,
.bk-info > *:last-child,
.table-responsive > *:last-child {
  margin-bottom: 0;
}

.bk-type-m {
  margin-bottom: m;
}
.bk-type-m > *:last-child {
  margin-bottom: 0;
}

.bk-type-l {
  margin-bottom: l;
}
.bk-type-l > *:last-child {
  margin-bottom: 0;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-m {
  margin-top: 2.4rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-xxs {
  margin-bottom: 0.8rem !important;
}

.mb-m {
  margin-bottom: 2.4rem !important;
}

.mb-l {
  margin-bottom: 4.8rem !important;
}

.mb-xl {
  margin-bottom: 5.6rem !important;
}

.mb-xxs {
  margin-bottom: 8px !important;
}

.mb-xs {
  margin-bottom: 12px !important;
}

.mb-s {
  margin-bottom: 16px !important;
}

.mb-m {
  margin-bottom: 24px !important;
}

.mb-m2 {
  margin-bottom: 32px !important;
}

.mb-l {
  margin-bottom: 48px !important;
}

.mb-xl {
  margin-bottom: 56px !important;
}

.mb-xxl {
  margin-bottom: 64px !important;
}

.big-text {
  font-size: 2.4rem;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

.ta-c {
  text-align: center;
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.uppercase {
  text-transform: uppercase;
}

.fw-bold {
  font-weight: bold;
}

.fw-normal {
  font-weight: 400 !important;
}

.ws-nowrap {
  white-space: nowrap;
}

.out,
.bk-slider-logo .slick-pause-text,
.bk-slider-logo .slick-play-text,
.bk-slider-logo .slick-autoplay-toggle-button,
.inputfile__field {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 0;
}

@media (max-width: 1365px) {
  .mob-hidden {
    display: none !important;
  }
}

.mob-visible {
  display: none;
}
@media (max-width: 1365px) {
  .mob-visible {
    display: block;
  }
}

@media (max-width: 1365px) {
  .mob-block {
    display: block;
  }
}

.lk-no-underline {
  text-decoration: none;
}
.lk-no-underline a {
  text-decoration: none;
}

.text-info {
  padding: 5px 10px;
  background-color: #fae692;
  border: 1px solid #e38e0e;
  color: #000;
}

.text-error {
  color: #c00;
}

.text-success {
  color: #007f00;
}

.bullet-list {
  text-align: left;
}
.bullet-list li {
  margin-bottom: 0.8rem;
  padding-left: 1.6rem;
  position: relative;
}
.bullet-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.2rem;
  transform: translate(0, -50%);
  width: 0.6rem;
  height: 0.6rem;
  color: #FFCB05;
  background-color: currentColor;
  border-radius: 50%;
}
.bullet-list li.checked:before {
  content: "v";
  background-color: transparent;
  top: 0;
  transform: translate(0);
}
.bullet-list li ul li:before {
  width: 0.4rem;
  height: 0.4rem;
  top: 1rem;
}

@media not print {
  .bg--primary .bullet-list li:before, .bg--black .bullet-list li:before {
    color: inherit;
  }
}

.blue-list li::before {
  color: #000FFF;
}
.blue-list li a {
  text-decoration: underline;
  font-weight: 400;
  font-size: 1.8rem;
  color: #535353;
}

.ol-list {
  text-align: left;
  counter-reset: ol-counter;
}
.ol-list > li {
  margin: 0 0 0.8rem 1.2rem;
  padding-left: 2.4rem;
  position: relative;
}
.ol-list > li:before {
  content: counter(ol-counter) ".";
  counter-increment: ol-counter;
  position: absolute;
  right: 98%;
  top: 0;
  color: #000FFF;
  display: inline-block;
  padding-right: 0.3rem;
  font-weight: bold;
}
.ol-list ul,
.ol-list ol {
  margin-bottom: 1.2rem;
}
.ol-list ul li,
.ol-list ol li {
  font-size: 1.4rem;
}
.ol-list ol {
  counter-reset: ol-counter-bis;
}
.ol-list ol li {
  counter-increment: ol-counter-bis;
}
.ol-list ol li:before {
  content: counter(ol-counter) "." counter(ol-counter-bis) ".";
}

.square-list li {
  padding-left: 1.5rem;
  position: relative;
}
.square-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.8rem;
  width: 0.6rem;
  border-top: 6px solid currentColor;
}
.square-list li li {
  padding-left: 1rem;
}
.square-list li li::before {
  width: 0.3rem;
  border-width: 0.3rem;
}

.bullet-list.-secondary li::before,
.square-list.-secondary li::before {
  color: #FFCB05;
}

.inline-list {
  letter-spacing: -0.31em;
}
.inline-list > li {
  display: inline-block;
  margin: 0 1.2rem 1.2rem 0;
  vertical-align: middle;
  letter-spacing: normal;
}
.inline-list > li:last-child {
  margin-right: 0;
}

.no-styled-list,
.bk-custom-slider__dots,
.bk-documents-list,
.bk-accordion__list,
.wpml-ls,
.iti__country-list,
.pager__items,
.tabs-primary.tabs.primary,
#admin-menu ul,
#toolbar-administration ul,
#wpadminbar ul,
.footer__top ul,
.footer__middle ul,
.footer__bottom ul {
  margin-bottom: 0;
}
.no-styled-list li,
.bk-custom-slider__dots li,
.bk-documents-list li,
.bk-accordion__list li,
.wpml-ls li,
.iti__country-list li,
.pager__items li,
.tabs-primary.tabs.primary li,
#admin-menu ul li,
#toolbar-administration ul li,
#wpadminbar ul li,
.footer__top ul li,
.footer__middle ul li,
.footer__bottom ul li {
  margin: 0;
  padding: 0;
  position: static;
}
.no-styled-list li:before, .no-styled-list li:after,
.bk-custom-slider__dots li:before,
.bk-documents-list li:before,
.bk-accordion__list li:before,
.wpml-ls li:before,
.iti__country-list li:before,
.pager__items li:before,
.tabs-primary.tabs.primary li:before,
#admin-menu ul li:before,
#toolbar-administration ul li:before,
#wpadminbar ul li:before,
.footer__top ul li:before,
.footer__middle ul li:before,
.footer__bottom ul li:before,
.bk-custom-slider__dots li:after,
.bk-documents-list li:after,
.bk-accordion__list li:after,
.wpml-ls li:after,
.iti__country-list li:after,
.pager__items li:after,
.tabs-primary.tabs.primary li:after,
#admin-menu ul li:after,
#toolbar-administration ul li:after,
#wpadminbar ul li:after,
.footer__top ul li:after,
.footer__middle ul li:after,
.footer__bottom ul li:after {
  display: none;
}

.of-cover {
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.of-contain {
  -o-object-fit: contain;
  object-fit: contain;
  font-family: "object-fit: contain";
}

.hidden {
  display: none !important;
}

@media (min-width: 1366px) {
  .hidden-from--desktop {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hidden-from--medium {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-from--tablet {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .off-mobile-container {
    display: none !important;
  }
}

@media (min-width: 480px) {
  .hidden-from--mobile {
    display: none !important;
  }
}

@media (max-width: 1365px) {
  .hidden-until--desktop {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .hidden-until--medium {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-until--tablet {
    display: none !important;
  }
}

@media (max-width: 479px) {
  .hidden-until--mobile {
    display: none !important;
  }
}

.wysiwyg-content > *:first-child {
  margin-top: 0;
  padding-top: 0;
}
.wysiwyg-content > *:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.paragraph {
  max-width: 120rem;
  margin: 6.4rem auto;
  padding: 0 0.8rem;
}
.paragraph.bg--blue {
  background-color: #FAFAFF;
}
.site-content .paragraph {
  max-width: none;
  padding: 0;
}
.paragraph .mt-xl {
  margin-top: 9rem !important;
}
.paragraph-full-width {
  position: relative;
  left: 50%;
  width: 100vw;
  max-width: none;
  margin: 6.4rem auto;
  transform: translateX(-50%);
}
.paragraph-full-width.bg--blue {
  background-color: #FAFAFF;
  padding: 3.2rem 2rem;
}
@media (min-width: 768px) {
  .paragraph-full-width.bg--blue {
    margin-top: -3.2rem;
  }
}
.paragraph-full-width > .paragraph {
  padding: 0;
  margin: 0;
}
.paragraph-full-width > h1:first-of-type, .paragraph-full-width > h2:first-of-type, .paragraph-full-width > h3:first-of-type {
  margin-left: auto;
  margin-right: auto;
  max-width: 120rem;
}
.paragraph-small {
  max-width: 81.6rem;
}
.paragraph-small > .entry-content a {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.paragraph-from-tablet {
  position: relative;
  left: 50%;
  width: 100vw;
  max-width: none;
  transform: translateX(-50%);
  margin: 3.2rem auto;
}
@media (min-width: 768px) {
  .paragraph-from-tablet {
    max-width: 120rem;
    margin: 6.4rem auto;
    padding: 0 0.8rem;
    transform: none;
    left: auto;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .paragraph {
    padding: 0 2rem;
  }
}
@media (max-width: 767px) {
  .paragraph {
    margin: 3.2rem auto;
  }
}

.reduced-content {
  max-width: 79.5rem !important;
  margin-left: auto;
  margin-right: auto;
}
.reduced-content-xs {
  max-width: 48rem !important;
}

.shadow-s {
  box-shadow: 0px 4px 8px 0px rgba(102, 109, 146, 0.08);
}
.shadow-m {
  box-shadow: 0px 12px 24px 0px rgba(102, 109, 146, 0.1);
}

.separator {
  border-bottom: 2px solid #FFCB05;
  width: 6.4rem;
  margin-bottom: 1.2rem;
}
.separator--large {
  border-bottom: 4px solid #FFCB05;
  width: 12.8rem;
  margin-bottom: 1.6rem;
}

.trc {
  background: linear-gradient(45deg, transparent 95%, #FFCB05 5%);
}

.bk-edito h2,
.bk-edito h3 {
  margin-bottom: 0.8rem !important;
}
@media (min-width: 1024px) {
  .bk-edito h2,
.bk-edito h3 {
    margin-bottom: 2.4rem !important;
  }
}
.bk-edito p {
  margin-bottom: 1.6rem !important;
}
.bk-edito p a {
  display: inline;
}
.bk-edito table {
  position: relative;
  width: 100%;
}
.site-content .bk-edito table {
  padding: 0;
}
.bk-edito table:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #CCCCFF;
  border-radius: 1.2rem;
  z-index: -1;
}
.bk-edito table tbody tr {
  margin: 4rem;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.bk-edito table tbody tr:not(:last-of-type) {
  border-bottom: 1px solid #CCCCFF;
}
@media (min-width: 768px) {
  .bk-edito table tbody tr {
    flex-direction: row;
  }
}
.bk-edito table tbody td {
  width: 100%;
}
.bk-edito table tbody td h5 {
  margin-bottom: 0.8rem;
  color: #535353;
}
.bk-edito table tbody td p {
  max-width: 22.5rem;
  font-size: 1.6rem;
  margin-top: 0;
}
.bk-edito table tbody td p:last-of-type {
  margin-bottom: 2.4rem;
}
.bk-edito table tbody td ul {
  margin-top: 0;
}
.bk-edito table tbody td ul:last-of-type {
  margin-bottom: 2.4rem;
}
.bk-edito table tbody td ul li {
  font-size: 1.6rem;
}
.bk-edito table tbody td ul li::before {
  color: #535353;
}

.body-small,
.bk-offer__card .intro,
.bk-card-pro .text-content,
.bk-card-pro a ul li {
  color: #535353;
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  .body-small,
.bk-offer__card .intro,
.bk-card-pro .text-content,
.bk-card-pro a ul li {
    font-size: 1.6rem;
  }
}

.body-medium,
.bk-steps__item .content,
.bk-steps-bar__item .content,
.bk-timeline-elem-text,
.bk-timeline a,
.bk-text-media__textblock__desc p,
.bk-text-media__textblock__desc li,
.bk-card-third p,
.bk-card-actu__body__teaser,
.bk-card-select__left p,
.bk-card-select .bk-card--large p,
.bk-card-avantages p,
.bk-card-avantages a,
.bk-accordion__list-item-content--list ul li a,
.bk-accordion__list-item-content ul li {
  color: #535353;
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .body-medium,
.bk-steps__item .content,
.bk-steps-bar__item .content,
.bk-timeline-elem-text,
.bk-timeline a,
.bk-text-media__textblock__desc p,
.bk-text-media__textblock__desc li,
.bk-card-third p,
.bk-card-actu__body__teaser,
.bk-card-select__left p,
.bk-card-select .bk-card--large p,
.bk-card-avantages p,
.bk-card-avantages a,
.bk-accordion__list-item-content--list ul li a,
.bk-accordion__list-item-content ul li {
    font-size: 1.8rem;
  }
}

.body-large {
  color: #535353;
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .body-large {
    font-size: 2rem;
  }
}

.body-xlarge,
.bk-timeline__note p,
.bk-card-avis .content,
.bk-card-security p,
.bk-card-security li,
.bk-accordion__list-item-content--list ul li .subtitle {
  color: #535353;
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .body-xlarge,
.bk-timeline__note p,
.bk-card-avis .content,
.bk-card-security p,
.bk-card-security li,
.bk-accordion__list-item-content--list ul li .subtitle {
    font-size: 2rem;
  }
}

.body-xlarge--plus {
  color: #000FFF;
  font-weight: 600;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .body-xlarge--plus {
    font-size: 2.2rem;
  }
}

.bk-nl {
  padding: 2.4rem;
}
@media (min-width: 768px) {
  .bk-nl {
    padding: 6.4rem;
  }
}
.bk-nl .ta-c {
  max-width: 60rem;
  margin: 0 auto;
}

/* stylelint-disable selector-class-patern */
.search_blog {
  margin: 4rem auto 0;
  max-width: 79.6rem;
}

/* stylelint-enable selector-class-patern */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
 * Vendors - Ici, on importe toute les css des plugins en prenant soin de rajouter le _ devant le nom de fichier et de renommer l'extension .css en .scss
 * Ne pas oublier de changer les chemins d'images (media/images/vendors/...)
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  height: auto !important;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.js-slider-multi--half .slick-list {
  padding: 0 20% 0 0 !important;
}

/* stylelint-disable */
body #tarteaucitronRoot * {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 18px !important;
}
body #tarteaucitronRoot div#tarteaucitronAlertBig {
  box-sizing: border-box !important;
  background-color: #333333 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 2px solid #333333;
  width: 700px !important;
  max-width: 90% !important;
  max-height: 90% !important;
  overflow: auto;
  text-align: center;
}
body #tarteaucitronRoot div#tarteaucitronAlertBig:before {
  display: none !important;
}
body #tarteaucitronRoot div#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
  margin: 0 0 25px !important;
  padding: 0;
  width: 100%;
  color: #fff !important;
  max-height: 45vh;
  overflow: auto;
}
body div#tarteaucitronRoot.tarteaucitronBeforeVisible:before,
body #tarteaucitronRoot button#tarteaucitronBack {
  background-color: rgba(0, 0, 0, 0.5) !important;
  opacity: 1 !important;
}
body div#tarteaucitronServices {
  box-shadow: none !important;
  margin-top: 30px !important;
}
body div#tarteaucitronServices #tarteaucitronInfo {
  border: 0;
  background-color: transparent !important;
}
body #tarteaucitron .tarteaucitronBorder, body #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, body #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, body #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, body #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-color: #FFCB05 !important;
}
body #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
}
body #tarteaucitronRoot .tarteaucitronH1 {
  font-weight: bold;
}
body #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  background-color: #FFCB05;
}
body #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle #tarteaucitronH2 {
  margin-top: 0 !important;
}
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow, body #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny, body .tac_activate .tarteaucitronAllow,
body #tarteaucitronAlertBig #tarteaucitronCloseAlert, body #tarteaucitronAlertBig #tarteaucitronPersonalize, body #tarteaucitronAlertBig #tarteaucitronPersonalize2, body .tarteaucitronCTAButton, body #tarteaucitron #tarteaucitronPrivacyUrl, body #tarteaucitron #tarteaucitronPrivacyUrlDialog, body #tarteaucitronRoot .tarteaucitronDeny, body #tarteaucitronRoot .tarteaucitronAllow {
  border-radius: 0 !important;
  padding: 10px !important;
  color: #fff !important;
}
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow .tarteaucitronCheck,
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow .tarteaucitronCross, body #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny .tarteaucitronCheck,
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny .tarteaucitronCross, body .tac_activate .tarteaucitronAllow .tarteaucitronCheck,
body .tac_activate .tarteaucitronAllow .tarteaucitronCross,
body #tarteaucitronAlertBig #tarteaucitronCloseAlert .tarteaucitronCheck,
body #tarteaucitronAlertBig #tarteaucitronCloseAlert .tarteaucitronCross, body #tarteaucitronAlertBig #tarteaucitronPersonalize .tarteaucitronCheck,
body #tarteaucitronAlertBig #tarteaucitronPersonalize .tarteaucitronCross, body #tarteaucitronAlertBig #tarteaucitronPersonalize2 .tarteaucitronCheck,
body #tarteaucitronAlertBig #tarteaucitronPersonalize2 .tarteaucitronCross, body .tarteaucitronCTAButton .tarteaucitronCheck,
body .tarteaucitronCTAButton .tarteaucitronCross, body #tarteaucitron #tarteaucitronPrivacyUrl .tarteaucitronCheck,
body #tarteaucitron #tarteaucitronPrivacyUrl .tarteaucitronCross, body #tarteaucitron #tarteaucitronPrivacyUrlDialog .tarteaucitronCheck,
body #tarteaucitron #tarteaucitronPrivacyUrlDialog .tarteaucitronCross, body #tarteaucitronRoot .tarteaucitronDeny .tarteaucitronCheck,
body #tarteaucitronRoot .tarteaucitronDeny .tarteaucitronCross, body #tarteaucitronRoot .tarteaucitronAllow .tarteaucitronCheck,
body #tarteaucitronRoot .tarteaucitronAllow .tarteaucitronCross {
  display: none !important;
}
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow,
body #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
body #tarteaucitronPersonalize2 {
  background-color: #007f00 !important;
}
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #007f00 !important;
}
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny,
body #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed,
body #tarteaucitronAllDenied2 {
  background-color: #c00 !important;
}
body #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #c00 !important;
}
body #tarteaucitronAlertBig #tarteaucitronCloseAlert, body #tarteaucitron #tarteaucitronPrivacyUrl, body #tarteaucitron #tarteaucitronPrivacyUrlDialog {
  background-color: #FFCB05 !important;
  color: #fff !important;
}
body #tarteaucitronAlertBig > button#tarteaucitronCloseAlert, body #tarteaucitronAlertBig > button#tarteaucitronAllDenied2, body #tarteaucitronAlertBig > button#tarteaucitronPersonalize2 {
  margin: 5px !important;
  display: inline-block !important;
}
body .tarteaucitronLine:before, body li[id^=tarteaucitronServicesTitle_]:before {
  display: none !important;
}

/* stylelint-enable */
/* stylelint-disable selector-class-pattern */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/* stylelint-enable selector-class-pattern */
img[data-lazy-src] {
  will-change: contents;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  right: 0;
  top: -44px;
  text-align: center;
  padding-right: 0;
  width: 40px;
  height: 40px;
  background: #000FFF;
  color: #fff;
  border-radius: 8px;
}
@media (min-width: 1024px) {
  .mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #535353;
    background: #fff;
    right: -64px;
    top: 0;
  }
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  border-radius: 12px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* stylelint-disable */
.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity;
}

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^=top] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^=left] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^=right] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}

/* stylelint-enable */
[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/* ------------------------------------------------
- Ici, tous les styles communs à toutes les pages du site.
- Bien penser en composants html MODULABLES, de manière à pouvoir déplacer un élément, une classe, un bloc d'une page à une autre sans problème.
- Découper un maximum les scss en composants (/components)
- Si ça n'est pas possible, utiliser ce fichier
- Aucun #Id ici !!!
------------------------------------------------ */
.grid--xl {
  margin-left: -6.4rem;
}
.grid--xl .grid__cell {
  padding-left: 6.4rem;
}
.grid__cell {
  margin-bottom: 2.4rem;
}

.footer__wrapper {
  background-color: #FAFAFF;
}
.footer__top, .footer__middle, .footer__bottom {
  padding: 5.6rem 2.4rem;
}
.footer__top .like-h4, .footer__middle .like-h4, .footer__bottom .like-h4 {
  margin-bottom: 1.2rem;
}
@media (min-width: 1024px) {
  .footer__top .like-h4, .footer__middle .like-h4, .footer__bottom .like-h4 {
    margin-bottom: 2.4rem;
  }
}
.footer__top ul a, .footer__middle ul a, .footer__bottom ul a {
  text-decoration: none;
  font-family: "Barlow", Arial, sans-serif;
}
.footer__top .inline-list li, .footer__middle .inline-list li, .footer__bottom .inline-list li {
  margin-right: 0.8rem;
}
@media (min-width: 768px) {
  .footer__top .inline-list li, .footer__middle .inline-list li, .footer__bottom .inline-list li {
    margin-right: 2.4rem;
  }
}
.footer__top .inline-list li:last-of-type, .footer__middle .inline-list li:last-of-type, .footer__bottom .inline-list li:last-of-type {
  margin-right: 0;
}
.footer__top .site-content > *:last-child, .footer__middle .site-content > *:last-child, .footer__bottom .site-content > *:last-child {
  margin-bottom: 0;
}
.footer__top, .footer__middle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  flex-direction: column;
}
.footer__top ul,
.footer__top p, .footer__middle ul,
.footer__middle p {
  margin-bottom: 1.6rem;
}
@media (min-width: 1024px) {
  .footer__top, .footer__middle {
    flex-direction: row;
  }
  .footer__top ul, .footer__middle ul {
    margin: 0;
  }
}
.footer__top ul li {
  margin-bottom: 1.2rem;
}
.footer__top ul li a {
  color: #535353;
}
.footer__top ul li a:not(.like-h4) {
  color: #535353;
  font-weight: 400;
}
.footer__top ul li a:active, .footer__top ul li a:hover {
  color: #000FFF;
}
@media (min-width: 768px) {
  .footer__top ul li {
    margin-bottom: 1.6rem;
  }
}
.footer__top .inline-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (min-width: 768px) {
  .footer__top .inline-list {
    width: auto;
  }
}
.footer__top .inline-list a {
  height: 6.4rem;
  width: 6.4rem;
  position: relative;
  border: 1px solid #E0E0E0;
  border-radius: 5rem;
  font-size: 2rem;
}
.footer__top .inline-list a:hover {
  cursor: pointer;
}
.footer__top .inline-list a span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000FFF;
}
.footer__top .inline-list a span.icon-youtube, .footer__top .inline-list a span.icon-twitter, .footer__top .inline-list a span.icon-facebook {
  font-size: 2.4rem;
}
.footer__middle {
  align-items: center;
}
.footer__middle ul {
  margin-bottom: 3.2rem;
}
.footer__middle ul li {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .footer__middle ul li {
    margin-bottom: 1.6rem;
  }
}
.footer__bottom a {
  font-weight: 400;
  font-size: 1.2rem;
  color: #535353 !important;
}
.footer__bottom a:active, .footer__bottom a:hover {
  color: #000FFF;
}
@media (min-width: 768px) {
  .footer__bottom a {
    font-size: 1.8rem;
  }
}

/* stylelint-disable selector-max-id */
/*
 * Drupal styles
 */
#admin-menu,
#toolbar-administration,
#wpadminbar {
  z-index: 9999;
}
#admin-menu #admin-menu-wrapper,
#toolbar-administration #admin-menu-wrapper,
#wpadminbar #admin-menu-wrapper {
  overflow: inherit;
}
/* ============================== */
/* Admin tabs */
/* ============================== */
.tabs-primary.tabs.primary {
  text-align: center;
}
.tabs-primary.tabs.primary li {
  display: inline-block;
}
/* ============================== */
/* AJAX */
/* ============================== */
.ajax-progress {
  position: fixed;
  bottom: 20px;
  left: 50%;
  margin: 0 0 0 -20px;
  width: 40px;
  height: 40px;
  background-color: rgb(153, 153, 153);
  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}
.ajax-progress .throbber {
  display: none;
}
.ajax-progress.ajax-progress-fullscreen {
  background-position: center;
  background-size: auto;
  background-image: url("../../assets/svg/logo-X.svg");
  animation: rotate 0.8s infinite ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.js-pager__items {
  width: 100%;
}
.grid .js-pager__items {
  padding-left: 24px;
}
.js-pager__items li {
  padding-left: 0;
  margin-left: 0;
}
.js-pager__items li:before {
  display: none;
}

.pager a {
  text-decoration: none;
}
.pager li {
  margin: 0 1.2rem;
}
@media (min-width: 768px) {
  .pager li {
    margin: 0 1.6rem;
  }
}
.pager .is-active {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000FFF;
  color: #fff;
  height: 3.2rem;
  width: 3.2rem;
  padding: 0;
  border-radius: 50%;
  border: 0;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .pager .is-active {
    margin: 0 0.8rem;
  }
}
.pager__item--first, .pager__item--last {
  display: none;
}
.pager__item--previous a, .pager__item--next a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.pager__item--previous a:hover, .pager__item--next a:hover {
  text-decoration: none;
}
@media (max-width: 767px) {
  .pager__item--previous a span:last-of-type, .pager__item--next a span:last-of-type {
    display: none;
  }
}
.pager__item--previous a::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e901";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 4.5rem;
  width: 2.5rem;
  padding: 0;
  margin: 0;
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  .pager__item--previous a::before {
    margin-right: 1.2rem;
  }
}
.pager__item--next a::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e903";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  width: 3rem;
  padding: 0;
  margin: 0;
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  .pager__item--next a::after {
    margin-left: 1.2rem;
  }
}
.pager__items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wpml-ls-item a {
  padding: 0.5rem 1rem !important;
}
.wpml-ls-item-toggle .wpml-ls-native {
  color: #000FFF !important;
}
.wpml-ls-item-toggle:after {
  display: none !important;
}
.wpml-ls-legacy-dropdown, .wpml-ls-legacy-dropdown-click {
  width: auto !important;
}
.wpml-ls-legacy-dropdown:hover .wpml-ls-item-toggle, .wpml-ls-legacy-dropdown-click:hover .wpml-ls-item-toggle {
  background-color: transparent !important;
}
.wpml-ls-sub-menu {
  margin-top: 1.6rem !important;
  visibility: visible !important;
  position: relative !important;
  border: 0 !important;
}
.wpml-ls-sub-menu li a {
  border: 0 !important;
  color: #535353 !important;
}
.wpml-ls-sub-menu li a:hover {
  background-color: transparent !important;
  color: inherit !important;
}
.wpml-ls-sub-menu li a span {
  color: #535353 !important;
}
.wpml-ls ul {
  margin-bottom: 0 !important;
}
.wpml-ls ul li a {
  border: 0 !important;
  font-size: 1.4rem !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
}
.wpml-ls ul li a:hover {
  background-color: transparent !important;
  color: inherit !important;
}

.is-search-form {
  justify-content: flex-start;
  border-radius: 50px;
  border: 1px solid #ccf;
  margin-bottom: 3.2rem;
}
.is-search-form label .is-search-input {
  border-radius: 50px !important;
  padding: 1.6rem 2.4rem !important;
  border: 0 !important;
  background: transparent !important;
  border-color: transparent !important;
  color: #535353 !important;
}
.is-search-form button {
  margin-left: -3.5rem;
  z-index: 1;
}
.is-search-form button .is-search-icon {
  background: transparent;
  border: 0;
}

/* stylelint-disable selector-class-patern */
.wpcf7-spinner {
  display: none !important;
}
.wpcf7-not-valid {
  border: 1px solid #dc3232 !important;
}
.kc_captcha .wpcf7-not-valid {
  border: none !important;
}
.kc_captcha .wpcf7-not-valid .captcha-image {
  border: 1px solid #dc3232 !important;
}
.wpcf7-not-valid-tip {
  font-family: "Barlow", Arial, sans-serif;
}
.wpcf7-response-output {
  font-weight: 700;
  border: none !important;
  color: #dc3232 !important;
  text-align: right;
}
.wpcf7-form.sent .wpcf7-response-output {
  color: #007f00 !important;
}
.wpcf7 .form-captcha {
  position: relative;
  float: right;
}
.wpcf7 .form-captcha .captcha-image {
  margin-bottom: 2.4rem;
}
.wpcf7 .form-captcha .has-spinner {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .wpcf7 .form-captcha .has-spinner {
    float: right;
  }
}
.wpcf7-list-item.first {
  margin-left: 0;
}
.wpcf7-list-item-label {
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
  color: #535353;
}
.wpcf7-form .upper-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .wpcf7-form .upper-form {
    flex-flow: row wrap;
  }
}
.wpcf7-form .upper-form label {
  flex: 0 1 48%;
}
.wpcf7-form input {
  margin-top: 1rem;
}
.wpcf7-form input::-webkit-outer-spin-button, .wpcf7-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.wpcf7-form input input[type=number] {
  -moz-appearance: textfield;
}
.wpcf7-form input[type=radio], .wpcf7-form input[type=checkbox] {
  margin-top: 0;
}
.wpcf7-form input[type=text], .wpcf7-form input[type=tel], .wpcf7-form input[type=number], .wpcf7-form input[name=your-ref], .wpcf7-form input[type=email] {
  padding: 2rem 2.4rem;
  border-radius: 0.8rem;
}
.wpcf7-form textarea {
  margin-top: 1rem;
}
.wpcf7-form label {
  margin-bottom: 4.8rem;
}
.wpcf7-form label[for=your-account] {
  margin-bottom: 2.4rem;
}
.wpcf7-form .wpcf7-submit {
  align-items: center;
  justify-content: center;
  padding: 1.7rem 4rem 1.6rem 4rem;
  background-color: #000FFF;
  border: 0;
  border-radius: 5rem;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.2;
  text-decoration: none;
  text-align: center;
  transition: 0.2s;
  appearance: none;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
  min-width: none;
}
@media (min-width: 768px) {
  .wpcf7-form .wpcf7-submit {
    min-width: 27.2rem;
  }
}
@media (min-width: 1024px) {
  .wpcf7-form .wpcf7-submit {
    min-width: 30.7rem;
    font-size: 1.6rem;
  }
}
.wpcf7-form .wpcf7-submit:hover:not([disabled]), .wpcf7-form .wpcf7-submit:focus:not([disabled]) {
  color: #fff;
  background-color: #3351E0;
}

.trustpilot-widget {
  margin-bottom: 2.4rem;
}
.trustpilot-widget iframe {
  width: auto !important;
}
.trustpilot-widget iframe #tp-widget-wrapper,
.trustpilot-widget iframe .tp-widget-wrapper {
  margin: 0 !important;
  text-align: left !important;
}

#tp-widget-wrapper,
.tp-widget-wrapper {
  margin: 0 !important;
  text-align: left !important;
}

.is-ajax-search-details,
.is-ajax-search-post {
  border-bottom: 0 !important;
}

.is-ajax-search-details .is-title a,
.is-ajax-search-post .is-title a {
  font-family: "Barlow", Arial, sans-serif !important;
  text-transform: none !important;
  color: #535353 !important;
  font-weight: 400 !important;
}
.is-ajax-search-details .is-title a .is-highlight,
.is-ajax-search-post .is-title a .is-highlight {
  font-weight: 700 !important;
  font-size: 14px !important;
  background-color: transparent !important;
  color: #535353 !important;
}

.is-ajax-search-items {
  border: none !important;
  box-shadow: 0px 12px 24px 0px rgba(102, 109, 146, 0.2) !important;
  border-radius: 8px !important;
}

div.is-ajax-search-details,
div.is-ajax-search-result {
  padding: 0 !important;
  background: none !important;
}

.is-show-more-results {
  margin-top: 0 !important;
  border-radius: 8px !important;
}

.bk-nl h2 {
  margin-bottom: 2.4rem !important;
}
.bk-nl .description {
  margin-bottom: 2.4rem;
}
.bk-nl .formulaire .hs-form-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.bk-nl .formulaire .hs-form-field label {
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}
.bk-nl .formulaire .hs-form-field .input {
  width: 100%;
}
.bk-nl .formulaire .legal-consent-container {
  margin-top: 2.4rem;
}
.bk-nl .formulaire .legal-consent-container li {
  padding-left: 0;
}
.bk-nl .formulaire .legal-consent-container li:before {
  display: none;
}
.bk-nl .formulaire .legal-consent-container .hs-richtext * {
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.4rem;
  text-align: left;
}
.bk-nl .hs-error-msgs {
  margin: 0;
  text-align: center;
  width: 100%;
}
.bk-nl .hs-error-msgs .hs-main-font-element {
  margin: 1.6rem 0;
}
.bk-nl .hs-error-msgs label {
  font-size: 1.6rem;
  padding: 0.8rem 4rem;
  color: #FF8BA2;
}
.bk-nl .hs-error-msgs li {
  padding-left: 0;
}
.bk-nl .hs-error-msgs li:before {
  display: none;
}
.bk-nl .hs-submit .actions {
  margin-top: 2.4rem;
}
.bk-nl .hs-submit .actions input[type=submit] {
  cursor: pointer;
  border-radius: 10rem;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}
.bk-nl .hs-submit .actions input[type=submit].hs-button {
  padding: 1.7rem 4rem 1.6rem 4rem !important;
  background-color: #000FFF;
  color: #fff;
  border: 1px solid #000FFF;
  width: 100%;
}
.bk-nl .hs-submit .actions input[type=submit].hs-button:hover:not([disabled]), .bk-nl .hs-submit .actions input[type=submit].hs-button:focus:not([disabled]) {
  color: #fff !important;
  background-color: #3351E0 !important;
}

/* stylelint-enable selector-class-patern */
.archive-description {
  text-align: center !important;
}

button {
  cursor: pointer;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
}

.btn,
.form-actions [type=submit],
.webform-multiple-add [type=submit],
.webform-multiple-table .tabledrag-toggle-weight-wrapper button,
.tabs-primary.tabs.primary a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.7rem 4rem 1.6rem 4rem;
  background-color: #000FFF;
  border: 0;
  border-radius: 5rem;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.2;
  text-decoration: none;
  text-align: center;
  transition: 0.2s;
  appearance: none;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
  min-width: none;
}
@media (min-width: 768px) {
  .btn,
.form-actions [type=submit],
.webform-multiple-add [type=submit],
.webform-multiple-table .tabledrag-toggle-weight-wrapper button,
.tabs-primary.tabs.primary a {
    min-width: 27.2rem;
  }
}
@media (min-width: 1024px) {
  .btn,
.form-actions [type=submit],
.webform-multiple-add [type=submit],
.webform-multiple-table .tabledrag-toggle-weight-wrapper button,
.tabs-primary.tabs.primary a {
    min-width: 30.7rem;
    font-size: 1.6rem;
  }
}
.btn:hover:not([disabled]), .btn:focus:not([disabled]),
.form-actions [type=submit]:hover:not([disabled]),
.webform-multiple-add [type=submit]:hover:not([disabled]),
.webform-multiple-table .tabledrag-toggle-weight-wrapper button:hover:not([disabled]),
.tabs-primary.tabs.primary a:hover:not([disabled]),
.form-actions [type=submit]:focus:not([disabled]),
.webform-multiple-add [type=submit]:focus:not([disabled]),
.webform-multiple-table .tabledrag-toggle-weight-wrapper button:focus:not([disabled]),
.tabs-primary.tabs.primary a:focus:not([disabled]) {
  color: #fff;
  background-color: #3351E0;
}
.btn:hover:not([disabled]) span[class*=icon-arrow-r], .btn:focus:not([disabled]) span[class*=icon-arrow-r],
.form-actions [type=submit]:hover:not([disabled]) span[class*=icon-arrow-r],
.webform-multiple-add [type=submit]:hover:not([disabled]) span[class*=icon-arrow-r],
.webform-multiple-table .tabledrag-toggle-weight-wrapper button:hover:not([disabled]) span[class*=icon-arrow-r],
.tabs-primary.tabs.primary a:hover:not([disabled]) span[class*=icon-arrow-r],
.form-actions [type=submit]:focus:not([disabled]) span[class*=icon-arrow-r],
.webform-multiple-add [type=submit]:focus:not([disabled]) span[class*=icon-arrow-r],
.webform-multiple-table .tabledrag-toggle-weight-wrapper button:focus:not([disabled]) span[class*=icon-arrow-r],
.tabs-primary.tabs.primary a:focus:not([disabled]) span[class*=icon-arrow-r] {
  color: #fff;
  transform: translateX(8px);
  transition-duration: 0.5s;
}
.btn--primary {
  color: #fff;
  background-color: #000FFF;
}
.btn--secondary {
  color: #000FFF;
  background-color: #fff;
  border: 1px solid #3351E0;
}
@media (min-width: 768px) {
  .btn--secondary-header {
    border: none;
    background-color: transparent;
    color: #000FFF;
  }
}
.btn--secondary:hover:not([disabled]), .btn--secondary:focus:not([disabled]) {
  border-color: #3351E0;
}
.btn--ghost {
  border: none;
  background-color: transparent;
  color: #000FFF;
}
.btn--useful {
  border: 1px solid #000FFF;
  background-color: transparent;
  color: #000FFF;
  border-radius: 12px;
  padding: 1.6rem 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.8rem;
  margin: 0.8rem 0;
}
.btn--useful span[class*=icon-arrow-r] {
  font-size: 3.2rem;
  color: #000FFF;
  transition-duration: 0.5s;
}
.btn--useful.colored {
  color: #000FFF;
}
.btn--useful.colored span[class*=icon-arrow-r] {
  color: #000FFF;
}
.btn--useful.colored .bk-link__title {
  font-size: 2rem;
}
.btn--useful:hover:not([disabled]), .btn--useful:focus:not([disabled]) {
  background-color: transparent;
  color: #535353;
}
.btn--useful:hover:not([disabled]) span[class*=icon-arrow-r], .btn--useful:focus:not([disabled]) span[class*=icon-arrow-r] {
  color: #000FFF;
}
@media (min-width: 768px) {
  .btn--useful {
    margin: 1.6rem 0;
    padding: 2.75rem 3.2rem;
  }
}
.btn--disabled, .btn[disabled],
.form-actions [disabled][type=submit],
.webform-multiple-add [disabled][type=submit],
.webform-multiple-table .tabledrag-toggle-weight-wrapper button[disabled],
.tabs-primary.tabs.primary a[disabled] {
  cursor: not-allowed;
  opacity: 0.8;
  background-color: #F1F1F1;
  color: #706F6F;
  border: none;
}
.btn--small {
  font-size: 1.4rem;
  padding: 0.8rem 3.5rem;
}
.btn--ir, .btn--il {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--ir [class^=icon-] {
  margin-left: 0.8rem;
}
.btn--il [class^=icon-] {
  margin-right: 0.8rem;
}

.btn-wrapper .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .btn-wrapper .btn {
    width: auto;
  }
}
.btn-wrapper--l-r {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.btn-wrapper--l-r .btn {
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .btn-wrapper--l-r {
    justify-content: center;
  }
}
@media (max-width: 1023px) {
  .btn-wrapper--l-r.-many-btns {
    justify-content: center;
  }
}
@media (max-width: 1023px) {
  .btn-wrapper--l-r.-many-btns .right-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    align-content: normal;
    width: 100%;
  }
}
@media (max-width: 1365px) {
  .btn-wrapper--l-r.-many-btns .right-btns .btn {
    padding: 1.5rem 4rem;
  }
}
.btn-wrapper--l-r.-many-btns .right-btns .btn + .btn {
  margin-left: 1rem;
}
.btn-wrapper--r {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.btn-wrapper--r .btn {
  margin-bottom: 1rem;
}
.btn-wrapper--center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.btn-wrapper--center .btn {
  margin: 1rem;
}

.player__btn {
  position: relative;
}
.player__btn__link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  z-index: 9;
}
.player__btn__link:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e944";
  background: radial-gradient(circle, rgb(255, 255, 255) 50%, rgb(255, 86, 87) 100%);
  color: #FF5657;
  border-radius: 5rem;
  font-size: 7.5rem;
}
.player__btn__link--small:after {
  font-size: 5rem;
}

.info__btn {
  text-decoration: none;
  color: #535353;
  position: relative;
  margin-left: 1.2rem;
  width: 2rem;
  height: 2rem;
}
.info__btn span {
  font-size: 1.3rem;
}
@media (min-width: 768px) {
  .info__btn span {
    font-size: 2rem;
  }
}

/*
 * Form elements
 */
[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin: 9px 0;
  width: 100%;
}
[type=range]::-moz-focus-outer {
  border: 0;
}
[type=range]:focus {
  outline: 0;
}
[type=range]:focus::-webkit-slider-runnable-track {
  background: lightgray;
}
[type=range]:focus::-ms-fill-lower {
  background: #E0E0E0;
}
[type=range]:focus::-ms-fill-upper {
  background: lightgray;
}
[type=range]::-webkit-slider-runnable-track {
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  background: #E0E0E0;
  border: 0px solid #E0E0E0;
  border-radius: 5px;
}
[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  background: #000FFF;
  border: 0 solid #E0E0E0;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: default;
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  margin-top: -5px;
}
[type=range]::-moz-range-track {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  background: #E0E0E0;
  border: 0px solid #E0E0E0;
  border-radius: 5px;
  height: 4px;
}
[type=range]::-moz-range-thumb {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  background: #000FFF;
  border: 0 solid #E0E0E0;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: default;
  height: 18px;
  width: 18px;
}
[type=range]::-ms-track {
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: 9px 0;
  color: transparent;
}
[type=range]::-ms-fill-lower {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  background: lightgray;
  border: 0px solid #E0E0E0;
  border-radius: 10px;
}
[type=range]::-ms-fill-upper {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  background: #E0E0E0;
  border: 0px solid #E0E0E0;
  border-radius: 10px;
}
[type=range]::-ms-thumb {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  background: #000FFF;
  border: 0 solid #E0E0E0;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: default;
  height: 18px;
  width: 18px;
  margin-top: 2px;
}
[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-ms-thumb, [type=range]:disabled::-webkit-slider-runnable-track, [type=range]:disabled::-ms-fill-lower, [type=range]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}

[data-whatinput=keyboard] .inputfile__field:focus + label {
  outline: 2px solid #000FFF;
  outline-offset: 2px;
}
.inputfile__field:focus + label .inputfile__wrapper-result {
  border-color: #000;
}
.inputfile__field + label {
  display: flex;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: normal;
  border-radius: 8px;
}
.inputfile__field + label .inputfile__wrapper-result {
  position: relative;
  width: 100%;
  padding: 0.8rem 1.6rem;
  border: 1px solid #CCCCFF;
  background-color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-transform: none;
}
.inputfile__field + label .inputfile__wrapper-result .inputfile__result:after {
  content: "|";
  opacity: 0;
}
.inputfile__field + label .inputfile__text {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.2rem;
  text-align: center;
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.inputfile__field + label .inputfile__delete {
  position: absolute;
  display: none;
  background: none;
  border: 0;
  top: 50%;
  transform: translate(0, -50%);
  right: 20px;
}
.inputfile__field + label .inputfile__delete::before {
  content: "X";
  display: block;
  color: #000FFF;
  text-indent: 0;
}

.input-range-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-range-wrapper [type=range] {
  width: 100%;
}
.input-range-wrapper > span {
  min-width: 10rem;
  color: #000;
}

.form-item,
.line {
  margin-top: 2.4rem;
}
.form-item > *:last-child,
.line > *:last-child {
  margin-bottom: 0;
}

.form-item--short {
  width: 33%;
}
.form-item--bl .input-line--l {
  padding: 0.9rem 1.6rem;
  border-left: 1px solid #E0E0E0;
}
@media (max-width: 767px) {
  .form-item--bl--mobile .input-line--l {
    padding: 0.9rem 1.6rem;
    border-left: 1px solid #E0E0E0;
  }
}

label,
.label-like {
  display: block;
  margin-bottom: 2.4rem;
  color: #000FFF;
  font-weight: 600;
  font-family: "Montserrat", Arial, sans-serif;
}

input,
select {
  vertical-align: middle;
}

[type=date]::-webkit-inner-spin-button,
[type=datetime-local]::-webkit-inner-spin-button,
[type=time]::-webkit-inner-spin-button,
[type=month]::-webkit-inner-spin-button,
[type=week]::-webkit-inner-spin-button {
  display: none;
}

[type=text],
[type=email],
[type=date],
[type=datetime-local],
[type=time],
[type=month],
[type=week],
[type=password],
[type=tel],
[type=url],
[type=number],
[type=search],
textarea {
  appearance: none;
}

[type=text],
[type=email],
[type=date],
[type=datetime-local],
[type=time],
[type=month],
[type=week],
[type=password],
[type=tel],
[type=url],
[type=number],
[type=search],
textarea,
select {
  font-family: "Barlow", Arial, sans-serif;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 2rem 2.4rem;
  border: 1px solid #CCCCFF;
  border-radius: 8px;
  background-color: #fff;
  font-size: 1.4rem;
  line-height: 1.5;
}
[type=text][disabled],
[type=email][disabled],
[type=date][disabled],
[type=datetime-local][disabled],
[type=time][disabled],
[type=month][disabled],
[type=week][disabled],
[type=password][disabled],
[type=tel][disabled],
[type=url][disabled],
[type=number][disabled],
[type=search][disabled],
textarea[disabled],
select[disabled] {
  border-color: #fff !important;
  background-color: #fff !important;
}
[type=text]:focus,
[type=email]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=time]:focus,
[type=month]:focus,
[type=week]:focus,
[type=password]:focus,
[type=tel]:focus,
[type=url]:focus,
[type=number]:focus,
[type=search]:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
  border-color: #535353;
}
[type=text].input-line,
[type=email].input-line,
[type=date].input-line,
[type=datetime-local].input-line,
[type=time].input-line,
[type=month].input-line,
[type=week].input-line,
[type=password].input-line,
[type=tel].input-line,
[type=url].input-line,
[type=number].input-line,
[type=search].input-line,
textarea.input-line,
select.input-line {
  color: inherit;
  border-radius: 0;
  border-width: 0 0 1px;
  background: transparent;
}
[type=text].input-line--l,
[type=email].input-line--l,
[type=date].input-line--l,
[type=datetime-local].input-line--l,
[type=time].input-line--l,
[type=month].input-line--l,
[type=week].input-line--l,
[type=password].input-line--l,
[type=tel].input-line--l,
[type=url].input-line--l,
[type=number].input-line--l,
[type=search].input-line--l,
textarea.input-line--l,
select.input-line--l {
  color: inherit;
  border-radius: 0;
  border-width: 0 0 0 1px;
  background: transparent;
}
[type=text].no-border,
[type=email].no-border,
[type=date].no-border,
[type=datetime-local].no-border,
[type=time].no-border,
[type=month].no-border,
[type=week].no-border,
[type=password].no-border,
[type=tel].no-border,
[type=url].no-border,
[type=number].no-border,
[type=search].no-border,
textarea.no-border,
select.no-border {
  color: inherit;
  border-radius: 0;
  border-width: 0;
  background: transparent;
}

.ui-datepicker-title select {
  padding: 0;
  border: 0;
  min-height: 2rem;
}

.styled-select {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  padding: 0;
  position: relative;
}
.styled-select::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e909";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.8rem;
  height: 100%;
  color: #000;
  font-size: 3rem;
  pointer-events: none;
  z-index: 1;
}
.ie9 .styled-select::before {
  display: none;
}
.styled-select select {
  padding-right: 3rem;
  position: relative;
  width: 100%;
  max-width: none;
  appearance: none;
  border: 1px solid #706F6F;
  background-color: rgba(112, 111, 111, 0.1);
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
}
.styled-select select::-ms-expand {
  display: none;
}
.ie9 .styled-select select {
  padding-right: 1rem;
}
.styled-select--secondary:before {
  color: #FFCB05;
}
.styled-select.--blue select {
  background: #F2F2FF;
  background-color: #F2F2FF;
  color: #000FFF;
}

textarea {
  display: block;
  min-height: 25rem;
}

/* input[type='submit'],
input[type='reset'],
button {
    @extend %btn;
} */
button {
  cursor: pointer;
}

.input-with-button {
  display: flex;
  position: relative;
}
.input-with-button [type=text],
.input-with-button [type=email] {
  width: 100%;
}
.input-with-button [type=submit],
.input-with-button [type=button],
.input-with-button .btn {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  text-align: center;
}

.radio-check {
  display: block;
  margin-bottom: 1rem;
}
.radio-check--inline {
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
}
.bk-accordion--quiz .radio-check--inline {
  margin: 0;
}
.radio-check span {
  flex-direction: row;
  align-items: baseline;
  display: flex;
}
.radio-check span label {
  font-weight: normal;
  text-transform: none;
  color: #535353;
  font-family: "Barlow", Arial, sans-serif;
}

.inline-inputs label {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  padding-left: 5px;
  padding-right: 0;
  text-transform: none;
}

.simple-inline-input {
  position: relative;
  padding-left: 15px;
}
.simple-inline-input input {
  position: absolute;
  left: 0;
  top: 3px;
}
.simple-inline-input label {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  padding-left: 5px;
  padding-right: 0;
  text-transform: none;
}

.legal {
  font-size: 1.1rem;
  line-height: normal;
}

[type=checkbox],
[type=radio] {
  vertical-align: baseline;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.5rem;
  accent-color: #000FFF;
}
[type=checkbox] + label,
[type=radio] + label {
  display: inline-block;
}

.custom-fields [type=checkbox],
.custom-fields [type=radio] {
  position: absolute;
  opacity: 0;
}
.custom-fields [type=checkbox] + label,
.custom-fields [type=radio] + label {
  position: relative;
  padding-left: 3rem;
  margin: 0 2rem 0 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}
.bk-professions-list .custom-fields [type=checkbox] + label,
.bk-professions-list .custom-fields [type=radio] + label {
  padding-left: 2.4rem;
  margin-right: 0.8rem;
}
.custom-fields [type=checkbox] + label::before,
.custom-fields [type=radio] + label::before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #000FFF;
  background-color: #fff;
  position: absolute;
  border-radius: 6px;
  top: -2px;
  left: 0;
  transition: ease 0.2s;
}
.bk-professions-list .custom-fields [type=checkbox] + label::before,
.bk-professions-list .custom-fields [type=radio] + label::before {
  top: 0;
}
.custom-fields [type=checkbox] + label::after,
.custom-fields [type=radio] + label::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 2rem;
  position: absolute;
  left: 0.6rem;
  top: 0.4rem;
  opacity: 0;
  transition: 0.2s;
  color: #000FFF;
  background-color: currentColor;
}
.bk-professions-list .custom-fields [type=checkbox] + label::after,
.bk-professions-list .custom-fields [type=radio] + label::after {
  top: 2px;
}
.custom-fields [type=checkbox] + label a,
.custom-fields [type=radio] + label a {
  color: #000FFF;
  display: inline;
}
.custom-fields [type=checkbox]:checked + label::after,
.custom-fields [type=radio]:checked + label::after {
  opacity: 1;
  transform: scale(1);
}
.custom-fields [type=checkbox]:not(:checked):not(:disabled):hover + label::after,
.custom-fields [type=radio]:not(:checked):not(:disabled):hover + label::after {
  opacity: 0.3;
  transform: scale(1);
}
.bk-accordion--quiz .custom-fields [type=checkbox]:not(:checked):not(:disabled):hover + label::after,
.bk-accordion--quiz .custom-fields [type=radio]:not(:checked):not(:disabled):hover + label::after {
  top: 50%;
  transform: translate(0, -50%);
}
[data-whatinput=keyboard] .custom-fields [type=checkbox]:focus + label::before,
[data-whatinput=keyboard] .custom-fields [type=radio]:focus + label::before {
  outline: 2px solid #000FFF;
  outline-offset: 2px;
}
.custom-fields [type=checkbox].error + label,
.custom-fields [type=radio].error + label {
  color: #c00;
}
.custom-fields [type=checkbox].valid + label,
.custom-fields [type=radio].valid + label {
  color: #007f00;
}
.custom-fields [type=checkbox]:disabled + label,
.custom-fields [type=radio]:disabled + label {
  color: #535353;
  cursor: default;
}
.custom-fields [type=checkbox]:disabled + label::before, .custom-fields [type=checkbox]:disabled + label::after,
.custom-fields [type=radio]:disabled + label::before,
.custom-fields [type=radio]:disabled + label::after {
  border-color: #CCCCFF !important;
}
.custom-fields [type=checkbox]:disabled + label:before,
.custom-fields [type=radio]:disabled + label:before {
  background-color: #F1F1F1 !important;
}
.custom-fields [type=checkbox]:disabled + label:after,
.custom-fields [type=radio]:disabled + label:after {
  color: #535353;
}
.custom-fields [type=checkbox]:disabled[type=checkbox] + label::after,
.custom-fields [type=radio]:disabled[type=checkbox] + label::after {
  color: #E0E0E0 !important;
}
.custom-fields [type=checkbox]:disabled[type=radio] + label::after,
.custom-fields [type=radio]:disabled[type=radio] + label::after {
  background-color: #E0E0E0 !important;
}
.custom-fields [type=checkbox] + label::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e938";
  background-color: transparent;
  font-size: 1.2rem;
  left: 2px;
  top: 8px;
}
.custom-fields [type=checkbox]:checked + label:before {
  background-color: #000FFF;
  border-color: #000FFF;
}
.custom-fields [type=checkbox]:checked + label:after {
  color: #fff;
}
.custom-fields [type=radio] + label::before {
  border-radius: 50%;
}
.custom-fields [type=radio] + label::after {
  border-radius: 50%;
}
.custom-fields .-big-with-icon [type=checkbox] + label,
.custom-fields .-big-with-icon [type=radio] + label {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  width: 100%;
  min-height: 22rem;
  padding: 2rem;
  background: #000FFF;
  border: 1px solid #000FFF;
  color: #fff;
  transition: 0.3s ease;
  text-align: center;
}
.custom-fields .-big-with-icon [type=checkbox] + label .label-number,
.custom-fields .-big-with-icon [type=radio] + label .label-number {
  display: none;
}
@media (max-width: 1365px) {
  .custom-fields .-big-with-icon [type=checkbox] + label,
.custom-fields .-big-with-icon [type=radio] + label {
    min-height: 18rem;
  }
}
@media (max-width: 1023px) {
  .custom-fields .-big-with-icon [type=checkbox] + label,
.custom-fields .-big-with-icon [type=radio] + label {
    flex-flow: row nowrap;
    min-height: auto;
    padding: 1rem;
  }
}
.custom-fields .-big-with-icon [type=checkbox] + label::before,
.custom-fields .-big-with-icon [type=radio] + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  border: 1px solid #000FFF;
  border-radius: 0;
  transition: 0.2s ease;
  background: transparent;
}
.custom-fields .-big-with-icon [type=checkbox] + label::after,
.custom-fields .-big-with-icon [type=radio] + label::after {
  content: none;
}
.custom-fields .-big-with-icon [type=checkbox] + label:hover::before, .custom-fields .-big-with-icon [type=checkbox] + label:focus::before,
.custom-fields .-big-with-icon [type=radio] + label:hover::before,
.custom-fields .-big-with-icon [type=radio] + label:focus::before {
  left: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  border: 1px solid #fff;
}
.custom-fields .-big-with-icon [type=checkbox] + label [class*=icon-],
.custom-fields .-big-with-icon [type=radio] + label [class*=icon-] {
  display: block;
  text-align: center;
  font-size: 6rem;
}
@media (max-width: 1023px) {
  .custom-fields .-big-with-icon [type=checkbox] + label [class*=icon-],
.custom-fields .-big-with-icon [type=radio] + label [class*=icon-] {
    font-size: 4rem;
    margin-right: 2rem;
  }
}
.custom-fields .-big-with-icon [type=checkbox]:checked + label,
.custom-fields .-big-with-icon [type=radio]:checked + label {
  position: relative;
  background: #fff;
  color: #000FFF;
}
.custom-fields .-big-with-icon [type=checkbox]:checked + label .label-number,
.custom-fields .-big-with-icon [type=radio]:checked + label .label-number {
  display: block;
  padding: 0.3rem;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: #000FFF;
  color: #fff;
}
.custom-fields .-big-with-icon [type=checkbox]:checked + label::before,
.custom-fields .-big-with-icon [type=radio]:checked + label::before {
  border: 1px solid #fff;
}
.custom-fields .-big-with-icon [type=checkbox]:checked + label:hover::before, .custom-fields .-big-with-icon [type=checkbox]:checked + label:focus::before,
.custom-fields .-big-with-icon [type=radio]:checked + label:hover::before,
.custom-fields .-big-with-icon [type=radio]:checked + label:focus::before {
  border: 1px solid #000FFF;
}
.custom-fields .-big-with-icon [type=checkbox]:focus + label::before,
.custom-fields .-big-with-icon [type=radio]:focus + label::before {
  border: 1px solid #000FFF;
}

.switch-checkbox {
  position: absolute;
  opacity: 0;
}
.switch-checkbox + label {
  position: relative;
  padding-left: 5.5rem;
  margin: 0 2rem 0 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
}
.switch-checkbox + label::before, .switch-checkbox + label::after {
  content: "";
  display: block;
  position: absolute;
  transition: ease 0.2s;
}
.switch-checkbox + label::before {
  width: 4.8rem;
  height: 2.4rem;
  color: #E0E0E0;
  border: 1px solid currentColor;
  background-color: currentColor;
  position: absolute;
  border-radius: 1.2rem;
  top: -2px;
  left: 0;
}
.switch-checkbox + label::after {
  width: 1.5rem;
  height: 1.5rem;
  left: 0.6rem;
  top: 0.2rem;
  border-radius: 50%;
  color: #fff;
  background-color: currentColor;
}
.switch-checkbox + label a {
  color: #000FFF;
  display: inline;
}
.switch-checkbox:checked + label::before {
  color: #000FFF;
}
.switch-checkbox:checked + label::after {
  left: 27px;
}
[data-whatinput=keyboard] .switch-checkbox:focus + label::before {
  outline: 2px solid #000FFF;
  outline-offset: 2px;
}
.switch-checkbox.error + label {
  color: #c00;
}
.switch-checkbox.valid + label {
  color: #007f00;
}
.switch-checkbox:disabled + label {
  color: #535353;
  cursor: default;
}
.switch-checkbox:disabled + label::before, .switch-checkbox:disabled + label::after {
  border-color: #CCCCFF !important;
}
.switch-checkbox:disabled + label:before {
  background-color: #F1F1F1 !important;
}
.switch-checkbox:disabled + label:after {
  color: #535353;
}
.switch-checkbox:disabled[type=checkbox] + label::after {
  color: #E0E0E0 !important;
}

.js-added-line .js-remove-line {
  display: none;
}
.js-added-line:last-child .js-remove-line {
  display: inline-block;
}

.form-field-inline {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 0 0 30px;
}
.form-field-inline label,
.form-field-inline .label-like {
  margin: 0;
}
.form-field-inline input {
  width: auto;
  margin: 0 15px;
}

.checkbox-list {
  display: inline-block;
  position: relative;
}
.checkbox-list__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 5rem;
  min-width: 19rem;
  padding: 0 1.5rem;
  border: 2px solid #CCCCFF;
  border-left: 0;
  border-right: 0;
  background: transparent;
  font-size: 2rem;
  transition: 0.2s;
}
@media (max-width: 1023px) {
  .checkbox-list__label {
    width: 100%;
  }
}
.checkbox-list__label::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  content: "\e903";
  margin-left: 2rem;
  color: #000FFF;
  transform: rotate(90deg);
  transition: 0.2s;
}
.checkbox-list__label[aria-expanded=true] {
  border-bottom-color: transparent;
}
.checkbox-list__label[aria-expanded=true]::after {
  transform: rotate(-90deg);
}
.checkbox-list__list {
  margin: 0;
  padding: 1.5rem;
  border: 0;
  background-color: #fff;
}
@media (max-width: 767px) {
  .checkbox-list__list {
    width: 100%;
    height: 100%;
    padding: 2rem 5rem 2rem 2rem;
    overflow-y: auto;
  }
}
@media (max-width: 767px) {
  .checkbox-list__list::before {
    content: attr(aria-label);
    display: block;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
}
.checkbox-list__list-wrapper {
  position: absolute;
  top: 110%;
  margin: 0;
  display: none;
  min-width: 100%;
  z-index: 10;
  overflow: auto;
  max-height: 30rem;
  background-color: #fff;
  border: 0;
  border-bottom: 2px solid #CCCCFF;
  box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.05);
}
@media (max-width: 767px) {
  .checkbox-list__list-wrapper {
    position: fixed;
    top: 1rem;
    left: 1rem;
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    min-width: 0;
    padding: 0;
  }
  .checkbox-list__list-wrapper::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #535353;
    opacity: 0.5;
    z-index: -1;
  }
}
.checkbox-list__list-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: 0;
  color: #000FFF;
  font-size: 2rem;
  transition: 0.2s;
}
.checkbox-list__list-close:hover {
  color: #FFCB05;
}
.checkbox-list__list input[type=checkbox] + label, .checkbox-list__list input[type=radio] + label {
  margin-right: 0;
}
@media (max-width: 767px) {
  .checkbox-list__list input[type=checkbox] + label, .checkbox-list__list input[type=radio] + label {
    font-size: 2rem;
  }
}

.-with-icon {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 80rem;
  margin-bottom: 2rem;
}
.-with-icon input {
  padding-right: 4.8rem;
}
.-with-icon input:active + button, .-with-icon input:focus + button {
  color: #000;
}
.-with-icon > [class*=icon-] {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  border: 0;
  background: transparent;
  font-size: 1.8rem;
  transition: 0.2s;
}
.-with-icon > button {
  color: #000FFF;
}
.-with-icon > button:hover {
  color: rgba(0, 15, 255, 0.8);
}

.-with-unit {
  position: relative;
  display: inline-block;
  width: 100%;
}
.-with-unit > input {
  padding-right: 5rem;
}
.-with-unit > span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
}
.-with-unit > span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  bottom: 8px;
  border-left: 1px solid #E0E0E0;
}

.-with-button {
  display: flex;
}
.-with-button > span, .-with-button > input {
  flex: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.-with-button > .btn {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.-with-round-button {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 80rem;
  margin: 0 auto;
}
.-with-round-button input {
  padding-right: 4.8rem;
  height: 5rem;
}
.-with-round-button > button {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background-color: #FFCB05;
  color: #fff;
  height: 4.2rem;
  width: 4.2rem;
  padding: 0;
  font-size: 2.2rem;
  border-radius: 50%;
  border: 0;
}

.select2-container--default .select2-search--inline .select2-search__field {
  min-height: 0;
  padding: 0;
  line-height: 1;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-bottom: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice:before {
  content: none;
}
.select2-container--default .select2-selection--single {
  border-color: #CCCCFF;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0.9rem 1.6rem;
  line-height: 1.5;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e909";
  font-size: 1.8rem;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #CCCCFF;
}
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border-color: #535353;
}

.select2-dropdown {
  border-color: #CCCCFF;
}

.select2-selection:focus {
  box-shadow: none !important;
  border-color: #535353;
}

.select2-results__option {
  margin-bottom: 0;
}
.select2-results__option:before {
  display: none;
}

.select2-container .select2-selection--single {
  height: auto;
}

.select-2-dropdown-checkboxes .select2-results__option:not(.select2-results__message) {
  position: relative;
  padding-left: 3rem;
}
.select-2-dropdown-checkboxes .select2-results__option:not(.select2-results__message):before, .select-2-dropdown-checkboxes .select2-results__option:not(.select2-results__message):after {
  content: "";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0.8rem;
}
.select-2-dropdown-checkboxes .select2-results__option:not(.select2-results__message):before {
  background-color: #fff;
  border: 1px solid #CCCCFF;
  border-radius: 6px;
}
.select-2-dropdown-checkboxes .select2-results__option:not(.select2-results__message):after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e938";
  color: #fff;
  font-size: 12px;
  transform: scale(0) translateY(-50%);
  transition: transform 0.2s;
}
.select-2-dropdown-checkboxes .select2-results__option:not(.select2-results__message).select2-results__option--selected:before {
  background-color: #000FFF;
  border-color: #000FFF;
}
.select-2-dropdown-checkboxes .select2-results__option:not(.select2-results__message).select2-results__option--selected:after {
  transform: scale(1) translateY(-50%);
}

.webform-multiple-table {
  margin-bottom: 3.2rem;
}
.webform-multiple-table > *:last-child {
  margin-bottom: 0;
}
.webform-multiple-table table {
  width: 100%;
}
.webform-multiple-table table .form-item {
  margin-bottom: 0;
}
.webform-multiple-table table .js-form-type-date {
  position: relative;
}
.webform-multiple-table table .js-form-type-date:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
  pointer-events: none;
}
.webform-multiple-table table .js-form-type-date input {
  padding-right: 5rem;
  box-sizing: border-box;
}
.webform-multiple-table table .js-form-type-date img {
  display: none;
}
.webform-multiple-table .tabledrag-toggle-weight-wrapper {
  margin-bottom: 1.6rem;
}
.webform-multiple-add {
  margin-bottom: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.webform-multiple-add > *:last-child {
  margin-bottom: 0;
}
.webform-multiple-add input {
  box-sizing: border-box;
}
.webform-multiple-add [type=submit] {
  margin-right: 1.6rem;
}
.webform-multiple-add .form-item {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

form .container-inline > * {
  display: inline-block;
}

.webform-element--title-inline > label {
  display: block;
}
.webform-element--title-inline > div .container-inline {
  display: flex;
}

.webform-element-help {
  background-color: #000FFF !important;
  border-color: #000FFF !important;
}

.parsley-errors-list {
  margin: 0.5rem 0 0;
  color: #c00;
  font-size: 1.1rem;
}
.parsley-errors-list li {
  padding-left: 0 !important;
}
.parsley-errors-list li::before {
  display: none;
}
.no-error-msg .parsley-errors-list {
  display: none;
}

.radio-check + .parsley-errors-list {
  margin-top: 0;
}

.parsley-error [type=text],
.parsley-error [type=email],
.parsley-error [type=date],
.parsley-error [type=datetime-local],
.parsley-error [type=time],
.parsley-error [type=month],
.parsley-error [type=week],
.parsley-error [type=password],
.parsley-error [type=tel],
.parsley-error [type=url],
.parsley-error [type=number],
.parsley-error [type=search],
.parsley-error textarea,
.parsley-error select {
  border: 1px solid #c00;
  color: #c00;
}

.parsley-error .inline-inputs {
  color: #c00;
}

.parsley-error [type=checkbox] + label::before,
.parsley-error [type=radio] + label::before {
  border-color: #c00;
}
.parsley-error + .radio-check [type=checkbox] + label::before,
.parsley-error + .radio-check [type=radio] + label::before {
  border-color: #c00;
}

.parsley-error .js-inputfile[type=file] + label .wrapper-result {
  border-color: #c00;
}

/*
 * Tables elements (from bootstap) : http://bootstrapdocs.com/v3.0.3/docs/css/#tables
 */
.bk-table {
  margin-bottom: 3.2rem;
  background-color: #F1F1F1;
  padding: 1.2rem 4.8rem;
  font-size: 1.4rem;
}
.bk-table > *:last-child {
  margin-bottom: 0;
}
.bk-professions-list .bk-table {
  padding: 0 3.2rem;
  margin-top: 3.2rem;
}
.bk-table table {
  margin-bottom: 0;
}

table {
  margin-bottom: 3.2rem;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}
table > *:last-child {
  margin-bottom: 0;
}

caption {
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: left;
  font-size: 18px;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 12px;
  vertical-align: top;
  border-top: 1px solid #F0EAEA;
}
.bk-professions-list .table > thead > tr > th,
.bk-professions-list .table > thead > tr > td,
.bk-professions-list .table > tbody > tr > th,
.bk-professions-list .table > tbody > tr > td,
.bk-professions-list .table > tfoot > tr > th,
.bk-professions-list .table > tfoot > tr > td {
  vertical-align: middle;
  padding: 1.6rem 2.4rem;
  padding-left: 0;
}
.table > thead > tr:first-child th,
.table > thead > tr:first-child td,
.table > tbody > tr:first-child th,
.table > tbody > tr:first-child td,
.table > tfoot > tr:first-child th,
.table > tfoot > tr:first-child td {
  border-top: 0;
}
.bk-professions-list .table > thead > tr:first-child th,
.bk-professions-list .table > thead > tr:first-child td,
.bk-professions-list .table > tbody > tr:first-child th,
.bk-professions-list .table > tbody > tr:first-child td,
.bk-professions-list .table > tfoot > tr:first-child th,
.bk-professions-list .table > tfoot > tr:first-child td {
  border-top: 0;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #F0EAEA;
  color: #000FFF;
}
.bk-professions-list .table > thead > tr > th {
  vertical-align: bottom;
}
.table > caption + thead:first-child > tr :first-child > th,
.table > caption + thead:first-child > tr :first-child > td,
.table > colgroup + thead:first-child > tr :first-child > th,
.table > colgroup + thead:first-child > tr :first-child > td,
.table > thead:first-child > tr :first-child > th,
.table > thead:first-child > tr :first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #535353;
}
.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 6px;
}

.table-bordered {
  border: 1px solid #535353;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #535353;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #ddd;
}

.table-hover > tbody > tr:hover, .table-hover > tbody > tr:focus {
  background-color: #ddd;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media (max-width: 767px) {
  .table-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #535353;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tfoot > tr > th,
.table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
.table-responsive > .table-bordered > thead > tr > td:first-child,
.table-responsive > .table-bordered > tbody > tr > th:first-child,
.table-responsive > .table-bordered > tbody > tr > td:first-child,
.table-responsive > .table-bordered > tfoot > tr > th:first-child,
.table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
.table-responsive > .table-bordered > thead > tr > td:last-child,
.table-responsive > .table-bordered > tbody > tr > th:last-child,
.table-responsive > .table-bordered > tbody > tr > td:last-child,
.table-responsive > .table-bordered > tfoot > tr > th:last-child,
.table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
.table-responsive > .table-bordered > tbody > tr:last-child > td,
.table-responsive > .table-bordered > tfoot > tr:last-child > th,
.table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.guarantees-table .table {
  background: transparent;
}
.guarantees-table .table > thead > tr > th,
.guarantees-table .table > thead > tr > td,
.guarantees-table .table > tbody > tr > th,
.guarantees-table .table > tbody > tr > td,
.guarantees-table .table > tfoot > tr > th,
.guarantees-table .table > tfoot > tr > td {
  position: relative;
  vertical-align: middle !important;
  border: 10px solid #fff;
  text-align: center;
}
.guarantees-table .table > thead > tr > th:first-child,
.guarantees-table .table > thead > tr > td:first-child,
.guarantees-table .table > tbody > tr > th:first-child,
.guarantees-table .table > tbody > tr > td:first-child,
.guarantees-table .table > tfoot > tr > th:first-child,
.guarantees-table .table > tfoot > tr > td:first-child {
  text-align: left;
}

.offers-table {
  margin-bottom: 4rem;
}
.offers-table .table {
  background: transparent;
}
@media (max-width: 1365px) {
  .offers-table .table {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-collapse: inherit;
  }
}
.offers-table .table > thead > tr > th,
.offers-table .table > thead > tr > td,
.offers-table .table > tbody > tr > th,
.offers-table .table > tbody > tr > td,
.offers-table .table > tfoot > tr > th,
.offers-table .table > tfoot > tr > td {
  position: relative;
  vertical-align: middle !important;
  border: 10px solid #fff;
  text-align: center;
}
@media (max-width: 1365px) {
  .offers-table .table > thead > tr > th,
.offers-table .table > thead > tr > td,
.offers-table .table > tbody > tr > th,
.offers-table .table > tbody > tr > td,
.offers-table .table > tfoot > tr > th,
.offers-table .table > tfoot > tr > td {
    border: 0;
    padding: 1rem;
  }
}
.offers-table .table > thead > tr > th:first-child,
.offers-table .table > thead > tr > td:first-child,
.offers-table .table > tbody > tr > th:first-child,
.offers-table .table > tbody > tr > td:first-child,
.offers-table .table > tfoot > tr > th:first-child,
.offers-table .table > tfoot > tr > td:first-child {
  text-align: left;
}
@media (max-width: 1365px) {
  .offers-table .table > thead > tr > th:first-child,
.offers-table .table > thead > tr > td:first-child,
.offers-table .table > tbody > tr > th:first-child,
.offers-table .table > tbody > tr > td:first-child,
.offers-table .table > tfoot > tr > th:first-child,
.offers-table .table > tfoot > tr > td:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
}
.offers-table .table > thead > tr > th > .mob-visible,
.offers-table .table > thead > tr > td > .mob-visible,
.offers-table .table > tbody > tr > th > .mob-visible,
.offers-table .table > tbody > tr > td > .mob-visible,
.offers-table .table > tfoot > tr > th > .mob-visible,
.offers-table .table > tfoot > tr > td > .mob-visible {
  padding-top: 1.5rem;
}
.offers-table .table > thead > tr > th > .mob-visible > *:last-child,
.offers-table .table > thead > tr > td > .mob-visible > *:last-child,
.offers-table .table > tbody > tr > th > .mob-visible > *:last-child,
.offers-table .table > tbody > tr > td > .mob-visible > *:last-child,
.offers-table .table > tfoot > tr > th > .mob-visible > *:last-child,
.offers-table .table > tfoot > tr > td > .mob-visible > *:last-child {
  margin-bottom: 0;
}
.offers-table .table > thead > tr > th > .mob-visible p,
.offers-table .table > thead > tr > td > .mob-visible p,
.offers-table .table > tbody > tr > th > .mob-visible p,
.offers-table .table > tbody > tr > td > .mob-visible p,
.offers-table .table > tfoot > tr > th > .mob-visible p,
.offers-table .table > tfoot > tr > td > .mob-visible p {
  margin-bottom: 0.5rem;
}
.offers-table .table [type=checkbox] + label,
.offers-table .table [type=radio] + label {
  padding: 0;
}
@media (max-width: 1365px) {
  .offers-table .table [type=checkbox] + label,
.offers-table .table [type=radio] + label {
    padding-left: 3rem;
    margin-right: 2rem;
  }
}

.options-table {
  margin-bottom: 4rem;
}
.options-table .table {
  background: transparent;
}
@media (max-width: 1365px) {
  .options-table .table {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-collapse: inherit;
  }
}
.options-table .table > thead > tr > th,
.options-table .table > thead > tr > td,
.options-table .table > tbody > tr > th,
.options-table .table > tbody > tr > td,
.options-table .table > tfoot > tr > th,
.options-table .table > tfoot > tr > td {
  position: relative;
  vertical-align: middle !important;
  border: 10px solid #fff;
  text-align: center;
}
@media (max-width: 1365px) {
  .options-table .table > thead > tr > th,
.options-table .table > thead > tr > td,
.options-table .table > tbody > tr > th,
.options-table .table > tbody > tr > td,
.options-table .table > tfoot > tr > th,
.options-table .table > tfoot > tr > td {
    border: 0;
    padding: 1rem;
  }
}
.options-table .table > thead > tr > th:first-child,
.options-table .table > thead > tr > td:first-child,
.options-table .table > tbody > tr > th:first-child,
.options-table .table > tbody > tr > td:first-child,
.options-table .table > tfoot > tr > th:first-child,
.options-table .table > tfoot > tr > td:first-child {
  text-align: left;
}
@media (max-width: 1365px) {
  .options-table .table > thead > tr > th:first-child,
.options-table .table > thead > tr > td:first-child,
.options-table .table > tbody > tr > th:first-child,
.options-table .table > tbody > tr > td:first-child,
.options-table .table > tfoot > tr > th:first-child,
.options-table .table > tfoot > tr > td:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
}
.options-table .table > thead > tr > th:nth-child(2), .options-table .table > thead > tr > th:last-child,
.options-table .table > thead > tr > td:nth-child(2),
.options-table .table > thead > tr > td:last-child,
.options-table .table > tbody > tr > th:nth-child(2),
.options-table .table > tbody > tr > th:last-child,
.options-table .table > tbody > tr > td:nth-child(2),
.options-table .table > tbody > tr > td:last-child,
.options-table .table > tfoot > tr > th:nth-child(2),
.options-table .table > tfoot > tr > th:last-child,
.options-table .table > tfoot > tr > td:nth-child(2),
.options-table .table > tfoot > tr > td:last-child {
  white-space: nowrap;
}
.options-table .table > thead > tr > th > .mob-visible,
.options-table .table > thead > tr > td > .mob-visible,
.options-table .table > tbody > tr > th > .mob-visible,
.options-table .table > tbody > tr > td > .mob-visible,
.options-table .table > tfoot > tr > th > .mob-visible,
.options-table .table > tfoot > tr > td > .mob-visible {
  padding-top: 1.5rem;
}
.options-table .table > thead > tr > th > .mob-visible > *:last-child,
.options-table .table > thead > tr > td > .mob-visible > *:last-child,
.options-table .table > tbody > tr > th > .mob-visible > *:last-child,
.options-table .table > tbody > tr > td > .mob-visible > *:last-child,
.options-table .table > tfoot > tr > th > .mob-visible > *:last-child,
.options-table .table > tfoot > tr > td > .mob-visible > *:last-child {
  margin-bottom: 0;
}
.options-table .table > thead > tr > th > .mob-visible p,
.options-table .table > thead > tr > td > .mob-visible p,
.options-table .table > tbody > tr > th > .mob-visible p,
.options-table .table > tbody > tr > td > .mob-visible p,
.options-table .table > tfoot > tr > th > .mob-visible p,
.options-table .table > tfoot > tr > td > .mob-visible p {
  margin-bottom: 0.5rem;
}
@media (max-width: 1365px) {
  .options-table .table > thead > tr:not(:last-child) td,
.options-table .table > tbody > tr:not(:last-child) td,
.options-table .table > tfoot > tr:not(:last-child) td {
    display: block;
  }
}
.options-table .table > thead > tr:not(:last-child) td:first-child,
.options-table .table > tbody > tr:not(:last-child) td:first-child,
.options-table .table > tfoot > tr:not(:last-child) td:first-child {
  border-right: 0;
}
.options-table .table > thead > tr:not(:last-child) td:nth-child(2),
.options-table .table > tbody > tr:not(:last-child) td:nth-child(2),
.options-table .table > tfoot > tr:not(:last-child) td:nth-child(2) {
  border-left: 0;
}
@media (max-width: 1365px) {
  .options-table .table > thead > tr:last-child,
.options-table .table > tbody > tr:last-child,
.options-table .table > tfoot > tr:last-child {
    display: flex;
    width: calc(100% + 1px);
  }
  .options-table .table > thead > tr:last-child td,
.options-table .table > tbody > tr:last-child td,
.options-table .table > tfoot > tr:last-child td {
    padding: 2rem;
  }
  .options-table .table > thead > tr:last-child td:first-child,
.options-table .table > tbody > tr:last-child td:first-child,
.options-table .table > tfoot > tr:last-child td:first-child {
    width: 100%;
  }
  .options-table .table > thead > tr:last-child td:last-child,
.options-table .table > tbody > tr:last-child td:last-child,
.options-table .table > tfoot > tr:last-child td:last-child {
    flex-shrink: 0;
  }
}
.options-table .table .uppercase {
  font-size: 1.8rem;
}
.options-table .table .text-big {
  font-size: 3rem;
  line-height: 1;
}
@media (max-width: 1365px) {
  .options-table .table .text-big {
    font-size: 2rem;
  }
}
.options-table .table .lk {
  color: #000;
  margin-top: 2rem;
}
.options-table .table [type=checkbox] + label,
.options-table .table [type=radio] + label {
  margin-bottom: 0;
  margin-top: -1.4rem;
  padding: 0;
  color: #fff;
}
@media (max-width: 1365px) {
  .options-table .table [type=checkbox] + label,
.options-table .table [type=radio] + label {
    padding-left: 3rem;
    margin-right: 2rem;
    margin-top: 0;
  }
}

/*
 * pop-in
 */
.popin {
  position: relative;
  max-width: 120rem;
  margin: 2rem 1.2rem;
  padding: 2.4rem;
  background-color: #fff;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.8rem;
}
@media (min-width: 480px) {
  .popin {
    margin: 2rem auto;
  }
}
@media (min-width: 1024px) {
  .popin {
    padding: 4.8rem 3.2rem;
  }
}
.popin > .mfp-close {
  width: 4rem;
  height: 4rem;
  top: -4.4rem;
  right: 0;
  border-radius: 0.8rem;
  background: #000FFF;
  color: #fff;
}
.popin > .mfp-close:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e935";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-indent: 0;
  font-size: 1.6rem;
}
@media (min-width: 1024px) {
  .popin > .mfp-close {
    color: #535353;
    background: #fff;
    right: -6.4rem;
    top: 0;
  }
}
.popin--s {
  max-width: 60rem;
}
.popin__head {
  padding: 0 2.4rem 2.4rem;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.popin__head h1 {
  font-size: 2rem;
}
.popin__body > *:last-child {
  margin-bottom: 0;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* ------------------------------------------------
- Ici, tous les styles vraiment spécifiques.
- A utiliser le moins possible
------------------------------------------------ */
/* ============================== */
/* PAGE CHART */
/* ============================== */
.modal-hidden {
  display: none !important;
}

dialog {
  display: block;
  border: 0;
}

/* enlève le scroll de la page quand la modale est ouverte */
.no-scroll {
  overflow: hidden;
}

/* l’overlay couvre tout */
.simple-modal-overlay,
.simple-animated-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(83, 83, 83, 0.8);
  opacity: 0.8;
  z-index: 666;
  cursor: pointer;
  text-indent: -9000px;
}
.simple-modal-overlay[data-background-click=disabled],
.simple-animated-modal-overlay[data-background-click=disabled] {
  cursor: auto;
}

.simple-animated-modal-overlay {
  animation: fadewhite ease 0.5s 1 normal;
}

@keyframes fadewhite {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
/* modales */
.simple-modal,
.simple-animated-modal {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 5vh;
  max-width: 130.5rem;
  width: 90vw;
  max-height: 90vh;
  border: 1px solid #FFCB05;
  background: #fff;
  z-index: 667;
  padding: 2rem;
  right: auto;
  overflow: auto;
}

.simple-animated-modal-title {
  margin-bottom: 2.5rem;
  font-size: 3rem;
  line-height: normal;
}

.simple-modal-close,
.simple-animated-modal-close {
  float: right;
  color: #FFCB05;
  background: transparent;
  border: 0;
  font: inherit;
  padding: 1rem;
  font-size: 2.5rem;
  cursor: pointer;
  overflow: hidden;
}
.simple-modal-close::before,
.simple-animated-modal-close::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  position: relative;
  content: "\e935";
  transition: 0.3s ease-out;
}
.simple-modal-close .simple-animated-modal-close__text,
.simple-animated-modal-close .simple-animated-modal-close__text {
  position: absolute;
  left: -5000px;
}
.simple-modal-close:hover::before, .simple-modal-close:focus::before,
.simple-animated-modal-close:hover::before,
.simple-animated-modal-close:focus::before {
  transform: rotate(90deg);
}

.simple-modal-close:focus, .simple-modal-close:hover, .simple-modal-close:active {
  outline: 1px dotted #fff;
}
.simple-modal-close:hover, .simple-modal-close:active {
  background: #000FFF;
}

.simple-animated-modal {
  animation: apparition ease 0.5s 1 normal;
}

@keyframes apparition {
  0% {
    margin-top: 10vh;
  }
  100% {
    margin-top: 0;
  }
}
.tooltip-hide {
  display: none;
}

.custom-tooltip {
  padding: 0;
  background: none;
  border: 0;
  color: #000FFF;
  display: inline-block;
  margin: 0 0.6rem;
  position: relative;
  border-radius: 50%;
}

.tippy-box {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
}
.tippy-box[data-theme~=lightgrey] {
  background-color: #F1F1F1;
  color: #535353;
}
.tippy-box[data-theme~=lightgrey][data-placement^=top] > .tippy-arrow::before {
  border-top-color: #F1F1F1;
}
.tippy-box[data-theme~=lightgrey][data-placement^=bottom] > .tippy-arrow::before {
  border-bottom-color: #F1F1F1;
}
.tippy-box[data-theme~=lightgrey][data-placement^=left] > .tippy-arrow::before {
  border-left-color: #F1F1F1;
}
.tippy-box[data-theme~=lightgrey][data-placement^=right] > .tippy-arrow::before {
  border-right-color: #F1F1F1;
}
.tippy-box[data-theme~=white] {
  background-color: #fff;
  color: #535353;
}
.tippy-box[data-theme~=white][data-placement^=top] > .tippy-arrow::before {
  border-top-color: #fff;
}
.tippy-box[data-theme~=white][data-placement^=bottom] > .tippy-arrow::before {
  border-bottom-color: #fff;
}
.tippy-box[data-theme~=white][data-placement^=left] > .tippy-arrow::before {
  border-left-color: #fff;
}
.tippy-box[data-theme~=white][data-placement^=right] > .tippy-arrow::before {
  border-right-color: #fff;
}
.tippy-box[data-theme~=primary] {
  background-color: #000FFF;
  color: #fff;
}
.tippy-box[data-theme~=primary][data-placement^=top] > .tippy-arrow::before {
  border-top-color: #000FFF;
}
.tippy-box[data-theme~=primary][data-placement^=bottom] > .tippy-arrow::before {
  border-bottom-color: #000FFF;
}
.tippy-box[data-theme~=primary][data-placement^=left] > .tippy-arrow::before {
  border-left-color: #000FFF;
}
.tippy-box[data-theme~=primary][data-placement^=right] > .tippy-arrow::before {
  border-right-color: #000FFF;
}
.tippy-box .tippy-content {
  padding: 2.4rem;
}

.btn-back-to-top {
  position: fixed;
  right: 0;
  bottom: 10vh;
  padding: 12px;
  background: #000FFF;
  color: #fff;
  text-decoration: none;
  transform: translateX(100%);
  transition: transform 0.2s;
}
.btn-back-to-top [class^=icon-] {
  display: inline-block;
  transition: transform 0.2s;
}
.btn-back-to-top:hover {
  color: #fff;
}
.btn-back-to-top:hover [class^=icon-] {
  transform: scale(1.2) translateY(-5px);
}
.btn-back-to-top.is-visible {
  transform: translateX(0);
}

/*
 * Bk infos
 */
.bk-info {
  padding: 20px;
  background-color: #fae692;
  border: 1px solid #e38e0e;
  border-radius: 8px;
  color: #000;
}
.bk-info--warning {
  background-color: rgba(227, 142, 14, 0.3);
  border: 1px solid #e38e0e;
}
.bk-info--error {
  background-color: rgba(204, 0, 0, 0.3);
  border: 1px solid #c00;
}
.bk-info--success {
  background-color: rgba(0, 127, 0, 0.3);
  border: 1px solid #007f00;
}

/*
 * bk-video
 */
.bk-video-et-transcription__title {
  margin-bottom: 2rem;
}

/*
 * bk-video-wrapper
 */
.bk-video-wrapper {
  margin-bottom: 3.2rem;
  position: relative;
  padding-top: 56.25%;
  /* 16:9 */
}
.bk-video-wrapper > *:last-child {
  margin-bottom: 0;
}
.bk-video-wrapper--ratio-4-3 {
  padding-top: 75%;
}
.bk-video-wrapper embed,
.bk-video-wrapper iframe,
.bk-video-wrapper video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.bk-accordion__title {
  font-size: 2.8rem;
}
.bk-accordion__toggle-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all {
  padding: 0px;
  background: none;
  border: 0;
  transition: 0.8s;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all:focus {
  color: #535353;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all:focus:after {
  opacity: 0;
}
@media (hover: none) {
  .bk-accordion__toggle-container .bk-accordion__toggle-all:after {
    display: none;
  }
}
.bk-accordion__toggle-container .bk-accordion__toggle-all:hover:after {
  opacity: 1;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all .is-open-text {
  display: none;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all[aria-pressed=true] {
  color: #000FFF;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all[aria-pressed=true] .default-text {
  display: none;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all[aria-pressed=true] .is-open-text {
  display: inline;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all[aria-pressed=true]:after {
  opacity: 0;
}
.bk-accordion__toggle-container .bk-accordion__toggle-all[aria-pressed=true]:hover:after {
  opacity: 1;
}
.bk-accordion__list-item h2 {
  margin-bottom: 0;
}
.bk-accordion__list-item-label {
  border: 0;
  border-bottom: 1px solid #E0E0E0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.8rem 0;
  text-align: left;
  background: none;
  color: #535353;
  font-family: "Montserrat", Arial, sans-serif;
}
.bk-accordion__list-item-label .icon-accordion {
  color: #000FFF;
  font-size: 3rem;
  transition: 0.8s;
  margin-left: 2.5rem;
}
.bk-accordion__list-item-label .icon-accordion:before {
  content: "\e909";
}
.bk-accordion__list-item-label .icon-accordion.cross {
  color: #000FFF;
  transform: rotate(45deg);
  transition: 0.8s;
  margin-left: 2.5rem;
  font-size: 2.2rem;
}
.bk-accordion__list-item-label .icon-accordion.cross:before {
  content: "\e935";
  border: 1px solid #E0E0E0;
  border-radius: 5rem;
  padding: 0.8rem;
}
.bk-accordion__list-item-label:hover:not([disabled]), .bk-accordion__list-item-label:focus:not([disabled]) {
  color: #000FFF;
}
.bk-accordion__list-item-label:hover .cross:before, .bk-accordion__list-item-label:focus .cross:before {
  border-color: #000FFF;
}
.bk-accordion__list-item-content {
  display: none;
  padding: 1.6rem 0;
  overflow: hidden;
  border-bottom: 1px solid #E0E0E0;
}
.bk-accordion__list-item-content > div[class^=bk-] {
  margin-bottom: 4.8rem;
}
.bk-accordion__list-item-content .paragraph-full-width {
  width: 100%;
  background-color: transparent;
}
.bk-accordion__list-item-content ul {
  text-align: left;
}
.bk-accordion__list-item-content ul li {
  margin-bottom: 0.8rem;
  padding-left: 1.6rem;
  position: relative;
}
.bk-accordion__list-item-content ul li:before {
  display: block !important;
  content: "";
  position: absolute;
  left: 0;
  top: 1.2rem;
  transform: translate(0, -50%);
  width: 0.6rem;
  height: 0.6rem;
  color: #000FFF;
  background-color: currentColor;
  border-radius: 50%;
}
.bk-accordion__list-item-content *:last-child:not(.btn) {
  margin-bottom: 0;
}
.bk-accordion__list-item-content figure {
  float: none;
  display: flex;
  flex-direction: column;
}
.bk-accordion__list-item-content figure.align-center {
  align-items: center;
}
.bk-accordion__list-item-content figure.align-right {
  align-items: end;
}
.bk-accordion__list-item-content--list ul li {
  margin-bottom: 2.4rem;
}
.bk-accordion__list-item-content--list ul li a {
  text-decoration: underline;
  font-family: "Montserrat", Arial, sans-serif;
}
.bk-accordion__list-item-content--list ul li .subtitle {
  font-family: "Barlow", Arial, sans-serif;
}
.bk-accordion__list-item.is-open .bk-accordion__list-item-label {
  border-bottom: 0;
}
.bk-accordion__list-item.is-open [class*=icon-accordion],
.bk-accordion__list-item.is-open [class*=icon-arrow] {
  transform: rotate(180deg);
}
.bk-accordion__list-item.is-open [class*=cross] {
  transform: rotate(90deg);
}
@media (min-width: 768px) {
  .bk-accordion__list-item-label {
    padding: 2.4rem 0;
    min-height: 9.5rem;
  }
  .bk-accordion__list-item-content {
    padding: 6.4rem 0 1.6rem 0;
  }
  .bk-accordion__list-item-content:first-child {
    margin-top: 0 !important;
  }
  .bk-accordion__list-item-content:last-child {
    margin-bottom: 0 !important;
  }
}
.bk-accordion.bordered .bk-accordion__list-item {
  border: 1px solid #CCCCFF;
  padding: 0 3.2rem;
  border-radius: 8px;
  margin-bottom: 2.4rem;
}
.bk-accordion.bordered .bk-accordion__list-item-label {
  font-size: 2.2rem;
  color: #000FFF;
  border-bottom: 0;
}
.bk-accordion.bordered .bk-accordion__list-item-content {
  padding-left: 0;
}

.bk-tabs {
  display: flex;
  flex-direction: column;
}
.bk-tabs__head {
  display: flex;
  align-items: flex-end;
}
.bk-tabs__item {
  padding: 16px;
  border-bottom: 0;
  cursor: pointer;
}
.bk-tabs__item[tabindex="0"] {
  position: relative;
  border-color: #000FFF;
  border-top-width: 3px;
  color: #000FFF;
  cursor: default;
}
.bk-tabs__item[tabindex="0"]:after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  height: 2px;
}
.bk-tabs__content {
  padding: 24px;
  border: 1px solid #F0EAEA;
  border-top: 0;
}
.bk-tabs__content > *:last-child {
  margin-bottom: 0;
}

.bk-document {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}
@media (min-width: 481px) {
  .bk-document {
    margin-left: 0;
  }
}
.bk-document a {
  text-decoration: none;
}
.bk-document a:hover {
  cursor: pointer;
}
.bk-document__top {
  margin-bottom: 15px;
  position: relative;
}
.bk-document__top span {
  position: absolute;
  right: 10%;
  bottom: 10%;
  color: #fff;
}
.bk-document__top span::before {
  font-size: 32px;
}
.bk-document__bottom {
  background-color: #E0E0E0;
  text-align: center;
  padding: 12px 32px;
}
@media (min-width: 768px) {
  .bk-document__bottom {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .bk-documents .bk-slider__controls {
    display: none;
  }
}
.bk-documents-list__elem {
  margin-bottom: 24px !important;
}
.bk-documents-list__elem a {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-decoration: none;
}
.bk-documents-list__elem a [class^=icon-] {
  margin-left: 12px;
}
.bk-documents-list__elem span::before {
  font-size: 28px;
}

.bk-contact-card__left {
  text-align: right !important;
}
.bk-contact-card__right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.bk-contact-card__right span {
  margin-top: 16px;
}
.bk-contact-txt__details {
  background-color: #F1F1F1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
@media (min-width: 481px) {
  .bk-contact-txt__details {
    padding: 8px;
  }
}
.bk-contact-txt__details span {
  margin-top: 16px;
}
.bk-contact-txt__para {
  margin-top: 16px;
}
@media (min-width: 481px) {
  .bk-contact-txt__para {
    margin-top: 0;
  }
}

.bk-card {
  margin-bottom: 3.2rem;
  border: 1px solid #F0EAEA;
  border-radius: 8px;
  overflow: hidden;
}
.bk-card > *:last-child {
  margin-bottom: 0;
}
.bk-card__img img {
  display: block;
}
.bk-card__content {
  background-color: #fff;
  border-top: 0;
  padding: 12px;
}
.bk-card__content div {
  margin-bottom: 8px;
}
.bk-card__typo {
  border-left: 4px solid #000;
  padding-left: 8px;
}
.bk-card__title {
  font-size: 1.8rem;
  margin: 1.6rem 0;
}
.bk-card__taxo {
  color: #000FFF;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .bk-card--xl .bk-card__img img {
    -o-object-fit: "cover";
    object-fit: "cover";
    font-family: "object-fit: cover";
    height: 100%;
  }
  .bk-card--xl .bk-card__title {
    font-size: 2rem;
  }
}
.bk-card-pro {
  border: 1px solid #CCCCFF;
  border-radius: 1.2rem;
  margin-bottom: 1.6rem;
  align-self: stretch;
}
.grid__cell .bk-card-pro {
  height: 100%;
}
.bk-card-pro a {
  padding: 1.6rem 2.4rem;
  width: 100%;
  text-decoration: none;
}
.bk-card-pro a ul {
  margin: 0;
  margin-top: 1.6rem;
}
.bk-card-pro:hover:not([disabled]) span[class*=icon-arrow-], .bk-card-pro:focus:not([disabled]) span[class*=icon-arrow-] {
  transform: translateX(8px);
  transition-duration: 0.5s;
}
.bk-card-pro .bk-card__img {
  margin-bottom: 1.2rem;
}
.bk-card-pro__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bk-card-pro__title h4 {
  margin-bottom: 0;
}
.bk-card-pro__title span[class*=icon-arrow-] {
  font-size: 2.8rem;
  color: #000FFF;
  transition-duration: 0.5s;
}
.bk-card-avantages {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1.6rem 0;
}
@media (min-width: 768px) {
  .bk-card-avantages {
    margin: 0 3.2rem;
    flex-basis: 33%;
  }
}
.bk-card-avantages:first-of-type {
  margin-left: 0;
}
.bk-card-avantages:last-of-type {
  margin-right: 0;
}
.bk-card-avantages__img {
  position: relative;
  width: 4.8rem;
  height: 4.8rem;
}
@media (min-width: 768px) {
  .bk-card-avantages__img {
    width: 7.2rem;
    height: 7.2rem;
  }
}
.bk-card-avantages__img.bordered {
  border: 1px solid #FFCB05;
  border-radius: 50px;
}
.bk-card-avantages__img.checked {
  width: 2.8rem;
  height: 2.8rem;
  background-color: #FFCB05;
}
.bk-card-avantages__img.checked:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e938";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
}
.bk-card-avantages__img.checked img {
  display: none;
}
.bk-card-avantages__img img {
  margin-bottom: 1.2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bk-card-avantages h4,
.bk-card-avantages .like-h4 {
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "Montserrat", Arial, sans-serif;
  color: #000FFF;
}
@media (min-width: 768px) {
  .bk-card-avantages h4,
.bk-card-avantages .like-h4 {
    font-size: 2.2rem;
  }
}
.bk-card-avantages p,
.bk-card-avantages a {
  margin-top: 0;
  margin-bottom: 0.8rem;
}
.bk-card-avantages .savoir-plus {
  font-weight: 600;
  color: #000FFF !important;
  text-decoration: underline;
}
.bk-card-avantages .savoir-plus span {
  margin-left: 0.8rem;
  font-size: 1.6rem;
}
.bk-card-avantages--logo {
  padding: 4rem;
  border: 1px solid #CCCCFF;
  border-radius: 1.2rem;
}
@media (min-width: 768px) {
  .bk-card-avantages--logo {
    margin: 0 1.6rem;
  }
}
.bk-card-avantages--logo p {
  line-height: 2.5rem;
}
.bk-card-avantages--logo p .int {
  font-weight: 500;
  font-size: 2rem;
}
.bk-card-avantages--logo p:last-of-type {
  margin-bottom: 3.2rem;
}
.bk-card-avantages--logo a {
  font-size: 1.6rem;
  line-height: 2rem;
}
.bk-card-avantages--logo .bk-card-avantages__img {
  border: 0;
  width: auto;
  height: auto;
}
.bk-card-avantages--logo .bk-card-avantages__img img {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
}
.bk-card--large {
  border: 1px solid #8080FF;
  border-radius: 1.2rem;
  background-color: #fff;
  padding: 2.4rem;
}
@media (min-width: 1024px) {
  .bk-card--large {
    padding: 3.2rem 4.8rem;
  }
}
.bk-card--large a {
  width: 100%;
  text-decoration: none;
}
.bk-card--large:hover {
  border: 1px solid #000FFF;
  cursor: pointer;
}
.bk-card--large:hover .title span,
.bk-card--large:hover .title img {
  transition-duration: 500ms;
  transform: translateX(-10px);
}
.bk-card--large .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bk-card--large .title h4 {
  margin-bottom: 0;
  text-transform: capitalize;
}
.bk-card--large .title p {
  font-family: "Barlow", Arial, sans-serif;
}
.bk-card--large .title img,
.bk-card--large .title span {
  transition-duration: 500ms;
}
.bk-card--large .title .icon-arrow-line-r {
  font-size: 32px;
}
.bk-card--icon {
  text-align: center;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #CCCCFF;
  border-radius: 1.2rem;
  padding: 2.4rem;
}
@media (min-width: 768px) {
  .bk-card--icon {
    padding: 3.2rem 6.4rem;
  }
}
.bk-card--icon h4 {
  margin-bottom: 0.8rem;
}
.bk-card--icon p {
  font-family: "Barlow", Arial, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #535353;
  margin: 0;
}
@media (min-width: 768px) {
  .bk-card--icon p {
    margin-top: 0;
    margin-bottom: auto;
  }
}
.bk-card--icon__img {
  position: relative;
  border: 1px solid #FFCB05;
  border-radius: 50px;
  width: 7.2rem;
  height: 7.2rem;
  margin: 0 auto;
}
.bk-card--icon__img img {
  margin-bottom: 1.2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bk-card-select {
  padding: 4.8rem 2.4rem;
  background: linear-gradient(135deg, #FAFAFF 85%, #000FFF 85%);
  border-radius: 0;
  position: relative;
}
.bk-card-select a {
  text-decoration: none;
}
.bk-card-select .bk-card--large {
  z-index: 2;
  margin-top: 1.6rem;
}
@media (min-width: 1366px) {
  .bk-card-select .bk-card--large {
    margin-top: auto;
  }
}
@media (min-width: 768px) {
  .bk-card-select {
    border-radius: 2rem;
    padding: 6.4rem 6.4rem 4.8rem 8rem;
  }
}
.bk-card-select__left {
  margin-bottom: 0;
}
.bk-card-select__left .js-tabs {
  padding-left: 0 !important;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
}
@media (min-width: 768px) {
  .bk-card-select__left .js-tabs {
    margin-left: auto;
    margin-right: auto;
  }
}
.bk-card-select__left .js-tabs li {
  font-family: "Barlow", Arial, sans-serif;
}
.bk-card-select__left .js-tabs li:hover {
  background-color: #3351E0;
}
.bk-card-select__left .js-tabs li[aria-selected=true] {
  color: #fff;
  background-color: #000FFF !important;
}
.bk-card-select__left .js-tabs .slick-track {
  display: flex;
}
.bk-card-select__left .js-tabs .slick-track .slick-slide {
  margin-right: 1.2rem;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .bk-card-select__left .js-tabs {
    padding-left: 2.4rem;
  }
  .bk-card-select__left .js-tabs .slick-slide {
    margin-right: 0;
  }
}
.bk-card-select__left p,
.bk-card-select__left h3 {
  max-width: none;
}
@media (min-width: 1366px) {
  .bk-card-select__left p,
.bk-card-select__left h3 {
    max-width: 52rem;
  }
}
.bk-card-select__left h3 {
  font-size: 2.2rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 1024px) {
  .bk-card-select__left h3 {
    font-size: 2.8rem;
  }
}
.bk-card-select__left p {
  margin-bottom: 3.2rem;
}
@media (min-width: 1366px) {
  .bk-card-select__left p {
    margin-bottom: 4rem;
  }
}
.bk-card-select__right {
  position: relative;
  margin-bottom: 0;
}
.bk-card-select__right .compare-btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff !important;
  margin: 2.4rem 0 0 0 !important;
  font-family: "Barlow", Arial, sans-serif;
}
.bk-card-select__right .compare-btn:hover {
  background-color: #3351E0 !important;
}
.bk-card-select__right .compare-btn:focus {
  color: #000FFF;
}
.bk-card-select .btn {
  min-width: 0;
  margin-bottom: 1.6rem;
  margin-right: 1.6rem;
  padding: 1.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: transparent;
}
@media (min-width: 768px) {
  .bk-card-select .btn {
    padding: 1.6rem 2.4rem;
  }
}
.bk-card-select .btn:hover {
  cursor: pointer;
}
.bk-card-security {
  padding: 4rem 2.4rem;
  background: linear-gradient(135deg, #000FFF 88%, #FFCB05 88%);
  border-radius: 0;
  position: relative;
  color: #fff;
}
.bk-card-security .grid__cell {
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  .bk-card-security {
    border-radius: 2rem;
    padding: 6.4rem 8rem;
  }
}
.bk-card-security--logo {
  background: linear-gradient(135deg, #000FFF 80%, #FFCB05 80%);
}
.bk-card-security p,
.bk-card-security h2,
.bk-card-security li {
  color: #fff !important;
}
.bk-card-security h2 {
  margin-bottom: 2.4rem;
}
.bk-card-security li {
  position: relative;
  padding-left: 3.2rem;
}
.bk-card-security li.not-checked {
  padding-left: 0;
}
.bk-card-security li.not-checked:before {
  display: none;
}
.bk-card-security li:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e938";
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 20px;
  color: #fff;
}
.bk-card-security picture.corner {
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
}
@media (min-width: 768px) {
  .bk-card-security picture.corner {
    bottom: 2.4rem;
    right: 2.4rem;
  }
}
.bk-card-security .btn {
  margin-bottom: 3.2rem;
  width: 100%;
}
@media (min-width: 768px) {
  .bk-card-security .btn {
    width: auto;
  }
}
@media (min-width: 1366px) {
  .bk-card-security .btn {
    margin-bottom: auto;
  }
}
.bk-card-actu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.8rem;
  flex: 1;
}
@media (min-width: 1366px) {
  .bk-card-actu {
    margin-bottom: auto;
  }
}
.bk-card-actu__top {
  position: relative;
  margin-bottom: 2rem;
}
.bk-card-actu__top img {
  display: block;
  margin: 0 auto;
  border-radius: 0.8rem;
  width: 100%;
}
@media (min-width: 768px) {
  .bk-card-actu__top img {
    width: auto;
  }
}
.bk-card-actu__top a {
  text-decoration: none;
  border-bottom: 0;
}
.bk-card-actu__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
}
.bk-card-actu__body__date {
  font-size: 1.6rem;
  color: #706F6F;
  margin-bottom: 0.8rem;
}
.bk-card-actu__body__title {
  margin-bottom: 0;
  padding-bottom: 1.2rem;
  text-decoration: none;
  font-size: 2.2rem;
  font-family: "Montserrat", Arial, sans-serif;
  color: #000FFF;
  font-weight: 600;
}
.bk-card-actu__body__title:hover {
  color: #000FFF;
}
.bk-card-actu__body__teaser {
  color: #706F6F;
}
.bk-card-contact {
  padding: 4rem 2.4rem;
  background: linear-gradient(45deg, #FFCB05 12%, #000FFF 12%, #000FFF 88%, #FFCB05 88%);
  border-radius: 0;
  position: relative;
  color: #fff;
  text-align: center;
}
@media (min-width: 768px) {
  .bk-card-contact {
    border-radius: 2rem;
    padding: 5.6rem;
  }
}
.bk-card-contact h2,
.bk-card-contact p {
  max-width: 75rem;
  color: #fff;
  margin: 4rem auto;
}
.bk-card-contact p {
  margin-bottom: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
}
@media (min-width: 768px) {
  .bk-card-contact p {
    font-size: 2.4rem;
  }
}
.bk-card-contact .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .bk-card-contact .btn {
    width: auto;
  }
}
.bk-card-information {
  background-color: #FAFAFF;
  padding: 3.2rem;
  text-align: center;
  border-radius: 2rem;
}
.bk-card-information .title {
  margin-bottom: 2.4rem;
}
.bk-card-information .btn {
  border: 1px solid #000FFF;
}
@media (min-width: 768px) {
  .bk-card-information {
    padding: 4.8rem;
  }
}
.paragraph-full-width .bk-card-information {
  border-radius: 0;
}
.bk-card-avis {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem;
  position: relative;
}
@media (min-width: 768px) {
  .bk-card-avis {
    padding: 6.4rem 18rem;
  }
}
.bk-card-avis__id {
  position: relative;
  padding-left: 7.5rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .bk-card-avis__id:before {
    content: "";
    position: absolute;
    left: 0;
    background-color: #FFCB05;
    border-radius: 2rem;
    width: 6rem;
    height: 6rem;
  }
}
@media (min-width: 768px) {
  .bk-card-avis__id:after {
    content: "“";
    position: absolute;
    display: block;
    top: 14px;
    left: 14px;
    color: #fff;
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 6rem;
    font-weight: bold;
    line-height: 1em;
  }
}
@media (min-width: 768px) {
  .bk-card-avis__id {
    margin-bottom: 3.2rem;
  }
}
.bk-card-avis__id .name {
  color: #535353;
  margin-bottom: 0.4rem;
}
.bk-card-avis__id .function {
  margin: 0;
  color: #706F6F;
}
.bk-card-avis .content {
  padding-right: 1.2rem;
}
.bk-card-avis__img {
  position: absolute;
  left: 0;
  top: 1.5rem;
}
@media (min-width: 768px) {
  .bk-card-avis__img {
    position: relative;
    padding: 5rem;
    left: auto;
    top: auto;
    flex-shrink: 0;
  }
}
.bk-card-avis__img img {
  transform: scale(0.4);
}
@media (min-width: 768px) {
  .bk-card-avis__img img {
    position: relative;
    top: 50%;
    transform: translateY(-50%) scale(1);
  }
}
.bk-card-avis__img:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 1px solid #E0E0E0;
  border-radius: 2rem;
  box-shadow: 2px 2px 0 #FFCB05;
  width: 6rem;
  height: 6rem;
}
@media (min-width: 768px) {
  .bk-card-avis__img:before {
    width: 16rem;
    height: 16rem;
    box-shadow: 10px 10px 0 #FFCB05;
  }
}
.bk-card-tarif {
  padding: 0 2.4rem;
  border: 1px solid #FFCB05;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.bk-card-tarif.blue {
  border: 1px solid #CCCCFF;
}
.bk-card-tarif__descr, .bk-card-tarif__list {
  padding: 2.4rem 0;
  display: flex;
  flex-direction: column;
}
.bk-card-tarif__descr {
  border-bottom: 1px solid #F1F1F1;
  justify-content: space-between;
}
.bk-card-tarif__list {
  justify-content: space-between;
  flex: 1 0 auto;
}
.bk-card-tarif p {
  margin: 0;
}
.bk-card-tarif .btn {
  min-width: 0;
}
@media (min-width: 1366px) {
  .bk-card-tarif .btn {
    width: 100%;
  }
}
.bk-card-tarif .like-h5 {
  margin-bottom: 0.8rem;
}
.bk-card-tarif .subtitle {
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.bk-card-tarif .tarif {
  margin: 0.8rem 0 0;
  font-size: 2.5rem;
  font-weight: 600;
  color: #000FFF;
  font-family: "Montserrat", Arial, sans-serif;
}
.bk-card-tarif .tarif span {
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 0.8rem;
}
.bk-card-tarif .ttc {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: left;
  margin-top: 0;
  margin-bottom: 1.6rem;
  font-family: "Montserrat", Arial, sans-serif;
  color: #706F6F;
}
.bk-card-tarif .ttc:after {
  content: " TTC";
  font-size: 1.2rem;
}
.bk-card-tarif .list-title {
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-weight: 500;
}
.bk-card-tarif ul {
  margin-top: 1.6rem;
}
.bk-card-tarif ul li {
  font-size: 1.4rem;
  color: #706F6F;
  padding-left: 3.2rem;
  margin-bottom: 1.6rem;
}
.bk-card-tarif ul li a {
  font-size: 1.4rem;
  color: #706F6F;
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
}
.bk-card-tarif ul li:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e938";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
}
.bk-card-tarif .savoir-plus {
  text-align: center;
  font-size: 1.4rem;
}
@media (min-width: 768px) {
  .bk-card-tarif.solo {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .bk-card-tarif.solo .bk-card-tarif__descr {
    border-bottom: 0;
    margin-right: 2.4rem;
    justify-content: flex-start;
  }
  .bk-card-tarif.solo .bk-card-tarif__descr .subtitle {
    margin-bottom: 2.4rem;
  }
  .bk-card-tarif.solo .bk-card-tarif__descr .btn {
    max-width: 24.5rem;
  }
  .bk-card-tarif.solo .bk-card-tarif__list ul {
    max-height: 9.5rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
  }
  .bk-card-tarif.solo .bk-card-tarif__list ul li {
    margin-right: 1.6rem;
  }
  .bk-card-tarif.solo .tarif,
.bk-card-tarif.solo .ttc,
.bk-card-tarif.solo .savoir-plus {
    margin: 0;
    position: absolute;
    right: 2.4rem;
    top: 2.4rem;
  }
  .bk-card-tarif.solo .ttc {
    top: 6.5rem;
  }
  .bk-card-tarif.solo .savoir-plus {
    top: auto;
    bottom: 2.4rem;
  }
  .bk-card-tarif.solo--thirds .bk-card-tarif__descr {
    max-width: 26.5rem;
  }
  .bk-card-tarif.solo--bigger {
    min-height: 20rem;
  }
  .bk-card-tarif.solo--bigger .bk-card-tarif__descr .tarif {
    top: calc(50% - 24px);
    transform: translateY(-50%);
    font-size: 4rem;
    max-width: 24.5rem;
    right: auto;
    left: calc(100% - 269px);
  }
  .bk-card-tarif.solo--bigger .bk-card-tarif__descr .tarif:before {
    content: "à partir de:";
    position: absolute;
    top: -2.2rem;
    font-family: "Barlow", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .bk-card-tarif.solo--bigger .bk-card-tarif__descr .btn {
    position: absolute;
    top: auto;
    right: 2.4rem;
    bottom: 2.4rem;
  }
  .bk-card-tarif.solo--bigger .bk-card-tarif__list p,
.bk-card-tarif.solo--bigger .bk-card-tarif__list ul {
    display: none;
  }
  .bk-card-tarif.solo--bigger .bk-card-tarif__list .savoir-plus {
    left: 2.4rem;
    right: auto;
  }
}
.bk-card-offre {
  padding: 2.4rem;
  border: 1px solid #FFCB05;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}
.bk-card-offre[class*=bg] {
  background-color: #FAFAFF;
  border-color: #CCCCFF;
}
.bk-card-offre p {
  margin: 0;
  font-size: 1.4rem;
}
.bk-card-offre .title {
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
}
.bk-card-offre .subtitle {
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 4.8rem;
}
.bk-card-offre .tarif {
  font-size: 2.5rem;
  font-weight: 600;
  color: #000FFF;
  position: relative;
  margin-bottom: 0.4rem;
  font-family: "Montserrat", Arial, sans-serif;
}
.bk-card-offre .tarif:before {
  content: "crédit de:";
  position: absolute;
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
  left: 50%;
  top: -16px;
  transform: translateX(-50%);
}
.bk-card-offre .tarif span {
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 0.8rem;
}
.bk-card-offre .tarif-subtitle {
  margin-bottom: 2.4rem;
  font-weight: 600;
  color: #417CFF;
}
.bk-card-offre .ttc {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.4rem;
  font-family: "Montserrat", Arial, sans-serif;
  color: #706F6F;
}
.bk-card-offre .ttc:after {
  content: " TTC";
  font-size: 1.2rem;
}
.bk-card-offre .ttc-small {
  font-weight: 500;
  text-align: right;
  font-size: 1rem;
}
.bk-card-offre .ttc-small:after {
  font-size: 1rem;
}
.bk-card-offre .offre {
  line-height: 19px;
  margin-bottom: 2.4rem;
  text-align: left;
}
.bk-card-offre .offre-ita {
  font-style: italic;
}
.bk-card-offre .list-title {
  font-weight: 600;
}
.bk-card-offre .list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
}
.bk-card-offre .list-item .list-price {
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
}
.bk-card-offre .list-item .list-price:after {
  content: "HT";
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: 0.4rem;
}
.bk-card-offre .list-item .list-descr {
  max-width: 10.7rem;
}
.bk-card-offre .btn {
  min-width: 0;
  margin-top: 4rem;
}
@media (min-width: 1366px) {
  .bk-card-offre .btn {
    width: 100%;
  }
}
.bk-card-third {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 3.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  position: relative;
}
.bk-card-third p {
  margin: 0;
  margin-bottom: 2.4rem;
}
.bk-card-third .title {
  font-weight: 600;
  color: #000FFF;
  font-size: 2.2rem;
}
.bk-card-third .title--out {
  position: absolute;
  left: 0;
  bottom: 100%;
  margin-bottom: 1.2rem;
}
.bk-card-third .tarif {
  font-size: 2.2rem;
  font-weight: 600;
  color: #000FFF;
  margin-bottom: 0.8rem;
}
.bk-card-third .tarif span {
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 0.8rem;
}
.bk-card-third .legend {
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0;
  color: #706F6F;
}

@media (min-width: 769px) {
  .bk-remontees .bk-slider__controls {
    display: none;
  }
}

.bk-quote {
  margin-left: -2.4rem;
}
.bk-quote__author {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
}
.bk-quote__author-name, .bk-quote__author-job {
  margin-bottom: 0;
}
.bk-quote__author-name {
  font-weight: bold;
}
.bk-quote__author-job {
  margin-top: 0;
  font-style: italic;
}
.bk-quote__txt blockquote {
  border: 0;
  text-align: left;
}
@media (min-width: 769px) {
  .bk-quote__txt blockquote {
    text-align: right;
  }
}
.bk-quote--small {
  margin-left: auto;
}
.bk-quote--small .bk-quote__img {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bk-quote--small .bk-quote__author {
  align-items: center;
}
.bk-quote--small .bk-quote__txt blockquote {
  text-align: center;
}
.bk-quote--vignette, .bk-quote--vignette--nopic {
  margin-left: auto;
}
.bk-quote--vignette .bk-quote__txt blockquote, .bk-quote--vignette--nopic .bk-quote__txt blockquote {
  text-align: left;
}
.bk-quote--vignette .bk-quote__img--vignette, .bk-quote--vignette--nopic .bk-quote__img--vignette {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}
.bk-quote--vignette .bk-quote__img--vignette .bk-quote__author, .bk-quote--vignette--nopic .bk-quote__img--vignette .bk-quote__author {
  margin-left: 1.6rem;
}
.bk-quote--vignette--nopic .bk-quote__author {
  align-items: flex-start;
  padding: 1rem;
}

.bk-columns h3,
.bk-columns .like-h4 {
  margin-bottom: 1.2rem;
  color: #535353;
}
.bk-columns p {
  margin-top: 0;
  font-weight: 400;
}
.bk-columns p.sub {
  font-weight: 500;
  font-size: 2rem;
}

.bk-image figure {
  margin: 0 auto;
}
.bk-image figure img {
  display: block;
  width: 100%;
}
.bk-image figure figcaption {
  font-size: 1.2rem;
  line-height: normal;
  color: #E0E0E0;
  text-align: right;
}

.bk-img-text {
  margin-bottom: m;
}
.bk-img-text > *:last-child {
  margin-bottom: 0;
}
.bk-img-text::before, .bk-img-text::after {
  content: "";
  display: table;
  clear: both;
}
.bk-img-text .img-wrapper {
  max-width: 50%;
  margin-bottom: 2rem;
}
.bk-img-text .img-wrapper .legend {
  font-style: italic;
}
.bk-img-text ul,
.bk-img-text ol {
  overflow: hidden;
}
.bk-img-text:not(.reversed) .img-wrapper {
  margin-right: 3rem;
  float: left;
}
.bk-img-text.reversed .img-wrapper {
  margin-left: 3rem;
  float: right;
}
.bk-img-text.-flex-mode {
  display: flex;
  align-items: center;
}
.bk-img-text.-flex-mode.reversed {
  flex-direction: row-reverse;
}
.bk-img-text.-flex-mode .img-wrapper {
  flex-shrink: 0;
}

/*
 * bk-media
 */
.bk-media__wrapper img,
.bk-media__wrapper .bk-video-wrapper {
  display: block;
  margin-bottom: 1.2rem;
}
.bk-media__infos {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
}
.bk-media__copyright {
  text-align: right;
  font-size: 12px;
}

.bk-slider {
  position: relative;
}
.bk-slider .slick-slider {
  position: relative;
}
.bk-slider img {
  border-radius: 12px;
}
.bk-slider.-s {
  padding: 0;
}
@media (min-width: 768px) {
  .bk-slider.-s {
    padding: 0 6rem;
  }
}
@media (min-width: 1024px) {
  .bk-slider.-s {
    padding: 0 12rem;
  }
}
.bk-slider.videos .bk-slider__slide span {
  position: relative;
}
.bk-slider.videos .bk-slider__slide span:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e944";
  background-color: #fff;
  color: #417CFF;
  border-radius: 5rem;
  position: absolute;
  font-size: 2.4rem;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bk-slider-logo {
  width: 100%;
  margin: 0 auto;
}
.bk-slider-logo__box {
  background-color: #FAFAFF;
  border: 1px solid #CCCCFF;
  border-radius: 0.8rem;
  padding: 2.4rem;
  width: 100%;
}
.bg--blue .bk-slider-logo__box {
  background-color: #fff;
}
.bk-slider-logo__box img {
  margin: 0 auto;
  filter: grayscale(100%);
  transition: 0.5s;
}
.bk-slider-logo__box:hover img {
  filter: none;
  transition: 0.5s;
}
@media (min-width: 768px) {
  .bk-slider--videos .bk-slider__prev, .bk-slider--videos .bk-slider__next {
    top: 8.8rem !important;
  }
}
.bk-slider__slide {
  margin: 0 1.2rem;
}
.bk-slider__slide img {
  margin: 0 auto;
}
.bk-slider__slide .title {
  margin-bottom: 3.2rem;
  text-align: center;
  font-weight: 600;
  font-family: "Montserrat", Arial, sans-serif;
}
.bk-slider__slide--video {
  position: relative;
}
.bk-slider__slide--video .player__btn {
  position: absolute;
  height: 19.1rem;
}
.bk-slider__slide--video video {
  border-radius: 1.2rem;
  height: 100%;
}
.bk-slider__slide--video img {
  position: absolute;
  top: 0;
}
.bk-slider__slide--video .title {
  position: relative;
  top: 19.1rem;
}
.bk-slider__desc {
  margin-bottom: 3.2rem;
  font-size: 2rem;
  font-weight: 500;
  color: #535353;
  text-align: center;
}
.bk-slider__desc--legende {
  text-align: right;
  font-size: 12px;
  color: #706F6F;
  margin-bottom: 1.6rem;
}
.bk-slider__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  color: #535353;
}
.bk-slider__prev, .bk-slider__next {
  display: none;
}
.bk-slider__prev:hover:not([disabled]), .bk-slider__prev:focus:not([disabled]), .bk-slider__next:hover:not([disabled]), .bk-slider__next:focus:not([disabled]) {
  color: #fff;
  background-color: #3351E0;
}
.bk-slider__prev:hover:not([disabled]) span[class*=icon-arrow]:before, .bk-slider__prev:focus:not([disabled]) span[class*=icon-arrow]:before, .bk-slider__next:hover:not([disabled]) span[class*=icon-arrow]:before, .bk-slider__next:focus:not([disabled]) span[class*=icon-arrow]:before {
  color: #fff;
}
@media (min-width: 768px) {
  .bk-slider__prev, .bk-slider__next {
    color: currentColor;
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    left: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    background-color: #fff;
    transition: 0.2s;
    z-index: 3;
    padding: 0;
  }
  .bk-slider__prev span[class*=icon-arrow]:before, .bk-slider__next span[class*=icon-arrow]:before {
    font-size: 35px;
    color: #000FFF;
    line-height: unset;
  }
}
.bk-slider__next {
  left: auto;
  right: 0;
}
.bk-slider__dots .slick-dots {
  position: static;
  display: flex;
  align-items: center;
  z-index: auto;
  margin-bottom: 0.2rem;
}
.bk-slider__dots .slick-dots li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.3rem;
  padding: 0;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
  transition: 0.2s;
}
.bk-slider__dots .slick-dots li:before {
  content: "";
  left: 0.8rem;
  top: 1.3rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #E5E5FF;
  transition: 0.2s;
}
.bk-slider__dots .slick-dots li.slick-active:before {
  background-color: #000FFF;
}
.bk-slider__dots .slick-dots li.slick-active:after {
  transform: scale(1);
}
.bk-slider__dots .slick-dots button {
  width: 2.6rem;
  height: 2.6rem;
  font-size: 0;
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 50%;
}
.bk-slider .slick-slide.slick-current {
  visibility: visible;
}
@media (min-width: 768px) {
  .bk-slider .slick-slide {
    visibility: visible;
  }
}
.bk-slider .slick-slide > div {
  display: flex;
}

.bk-slider--half {
  position: relative;
  padding: 3.2rem 0;
}
@media (min-width: 768px) {
  .bk-slider--half {
    padding: 6.4rem 0;
  }
}
@media (min-width: 1024px) {
  .bk-slider--half {
    padding: 9.6rem 0;
  }
}
.bk-slider--half .slick-slider:before, .bk-slider--half .slick-slider:after {
  display: none;
}
.bk-slider--half .site-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .bk-slider--half .site-content {
    flex-direction: row;
  }
}
.bk-slider--half .site-content .half:first-of-type {
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .bk-slider--half .site-content .half {
    width: 50%;
    margin-bottom: 0;
  }
}
.bk-slider--half .site-content .half h2 {
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .bk-slider--half .site-content .half h2 {
    margin-bottom: 3.2rem;
    text-align: left;
  }
}
.bk-slider--half .site-content .half .legend {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .bk-slider--half .site-content .half .legend {
    text-align: left;
    position: relative;
    left: auto;
    bottom: auto;
    transform: none;
  }
}
.bk-slider--half .site-content .bk-slider {
  margin-left: -2.8rem;
  margin-right: -2.8rem;
  position: relative;
}
@media (min-width: 768px) {
  .bk-slider--half .site-content .bk-slider {
    position: absolute;
    right: 0;
  }
}
.bk-slider--half .site-content .bk-slider__slide {
  width: 38.5rem;
  margin: 0 0.8rem;
}
.bk-slider--half .site-content .bk-slider-logo__box {
  margin-bottom: 0.8rem;
}

.bk-custom-slider__controls {
  visibility: visible;
  width: 100%;
  border: 1px solid #CCCCFF;
  border-radius: 0.4rem;
  margin-top: 0;
}
@media (min-width: 481px) {
  .bk-custom-slider__controls {
    top: -4.8rem;
  }
}
@media (min-width: 768px) {
  .bk-custom-slider__controls {
    visibility: hidden;
  }
}
.bk-custom-slider__dots {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0 0.6rem;
  margin: 0;
}
.bk-custom-slider__dots .custom-dot {
  width: 100%;
  height: auto;
  margin: 0.6rem 0;
}
.bk-custom-slider__dots .custom-dot:before {
  display: none;
}
.bk-custom-slider__dots .custom-dot__item {
  width: 100%;
  border-radius: 0.4rem;
  padding: 0.8rem 1.4rem;
  color: #000FFF;
  text-decoration: none;
  text-align: center;
}
.bk-custom-slider__dots .custom-dot--active .custom-dot__item {
  background-color: #000FFF;
  color: #fff;
}

.slider-custom .bk-slider__controls {
  margin: 1.6rem 0;
}

.slider-filtres.js-slider--mobile .js-tabs {
  margin-left: auto;
}
.slider-filtres.js-slider--mobile .js-tabs .slick-slide div {
  width: 100%;
}
@media (min-width: 768px) {
  .slider-filtres.js-slider--mobile .js-tabs .slick-slide div {
    width: auto;
  }
}

.bk-chiffres-cles {
  background-color: #F1F1F1;
}
.bk-chiffres-cles__part {
  padding: 4.8rem 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.bk-chiffres-cles__part__logo span::before {
  font-size: 48px;
}
.bk-chiffres-cles__part__num, .bk-chiffres-cles__part__title {
  text-align: center;
  font-weight: bolder;
}
.bk-chiffres-cles__part__num {
  font-size: 3rem;
}
.bk-chiffres-cles--nopic {
  background-color: #F1F1F1;
}
.bk-chiffres-cles--nopic .bk-chiffres-cles__logo {
  display: none;
}

.bk-text-framed {
  border: 1px solid #E5E5FF;
  padding: 2.4rem 3.2rem;
  border-radius: 1.2rem;
  margin-bottom: 3.2rem;
}
@media (min-width: 1024px) {
  .bk-text-framed:not(:last-of-type) {
    margin-right: 3.2rem;
  }
}
.bk-text-framed .title {
  font-size: 3.2rem;
  font-weight: 600;
  font-family: "Montserrat", Arial, sans-serif;
  margin-bottom: 2.4rem;
  color: #000FFF;
}
.bk-text-framed__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1024px) {
  .bk-text-framed__wrapper {
    flex-direction: row;
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .bk-text-framed__wrapper--extended {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.bk-text-framed .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .bk-text-framed .btn {
    width: auto;
  }
}
.bk-text-media {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .bk-text-media {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .bk-text-media--reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .bk-text-media--reverse .bk-text-media__textblock {
    margin-left: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .bk-text-media--reverse .bk-text-media__imageblock {
    margin-left: 2.4rem;
    padding-left: 1.6rem;
  }
  .bk-text-media--reverse .bk-text-media__imageblock > *:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .bk-text-media--reverse .bk-text-media__imageblock {
    margin-left: 6.4rem;
  }
}
.bk-text-media__imageblock {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .bk-text-media__imageblock {
    max-width: 44rem;
  }
}
.bk-text-media__imageblock img {
  display: block;
  border-radius: 12px;
  width: 100%;
}
@media (min-width: 768px) {
  .bk-text-media__imageblock img {
    width: auto;
  }
}
.bk-text-media__imageblock--copy {
  margin-top: 1.2rem;
  font-size: 1.2rem;
  text-align: right;
}
.bk-text-media__textblock {
  margin-bottom: 2.4rem;
  margin-left: 0;
}
@media (min-width: 768px) {
  .bk-text-media__textblock {
    margin-bottom: 0;
    margin-left: 2.4rem;
    padding-left: 1.6rem;
  }
  .bk-text-media__textblock > *:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .bk-text-media__textblock {
    margin-left: 6.4rem;
  }
}
.bk-text-media__textblock__title {
  font-size: 2.4rem;
  margin-bottom: 2.4rem;
}
.bk-text-media__textblock__desc {
  margin-bottom: 2.4rem;
}
.bk-timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4.8rem;
  position: relative;
}
.bk-timeline:before {
  content: "";
  position: absolute;
  width: auto;
  border-left: 2px solid #FFCB05;
  left: 2.8rem;
  top: 6.4rem;
  bottom: 6.4rem;
  transform: translateX(-50%);
  z-index: -1;
}
@media (min-width: 768px) {
  .bk-timeline {
    margin-bottom: 0;
  }
  .bk-timeline:before {
    display: none;
  }
}
.bk-timeline a {
  text-decoration: underline;
}
.paragraph-full-width .bk-timeline {
  max-width: 120rem;
  margin: 0.8rem auto;
  padding: 4.8rem 0;
}
.bk-timeline__note {
  margin-bottom: 5.6rem;
}
.bk-timeline__note p a {
  color: #535353;
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.6rem;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .bk-timeline__note p a {
    font-size: 2rem;
  }
}
.paragraph-full-width .bk-timeline__note {
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
}
.bk-timeline.no-line::before {
  display: none;
}
.bk-timeline.no-line .bk-timeline-elem-num::before {
  display: none;
}
.bk-timeline.pictures {
  align-items: center;
}
.bk-timeline.pictures::before {
  display: none;
}
@media (min-width: 768px) {
  .bk-timeline.pictures {
    align-items: flex-start;
  }
}
.bk-timeline.pictures .bk-timeline-elem {
  position: relative;
  flex-direction: column;
  margin-bottom: 6.4rem;
}
@media (min-width: 768px) {
  .bk-timeline.pictures .bk-timeline-elem {
    margin-top: 6.4rem;
    margin-bottom: 4.8rem;
  }
}
.bk-timeline.pictures .bk-timeline-elem-img {
  display: block;
  position: relative;
  z-index: -1;
  width: 100%;
  text-align: center;
}
.bk-timeline.pictures .bk-timeline-elem-img:after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.5) 86.2%, #FFFFFF 100%);
}
.bk-timeline.pictures .bk-timeline-elem-text {
  text-align: center;
  max-width: 26rem;
  margin: 0 auto;
}
.bk-timeline.pictures .bk-timeline-elem-num {
  margin-top: -2.6rem;
  margin-bottom: 1.6rem;
}
.bk-timeline.pictures .bk-timeline-elem-num:before {
  display: none;
}
.bk-timeline.simplified::before {
  left: 1rem;
}
.bk-timeline.simplified .bk-timeline-elem-num p {
  content-visibility: hidden;
  width: 2rem;
  height: 2rem;
  background-color: #FFCB05;
}
@media (min-width: 768px) {
  .bk-timeline {
    flex-direction: row;
  }
}
.bk-timeline-elem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.bk-timeline-elem:not(:last-of-type) {
  margin-bottom: 5.6rem;
}
@media (min-width: 768px) {
  .bk-timeline-elem {
    display: block;
    text-align: center;
    margin-bottom: 3.2rem !important;
  }
}
.bk-timeline-elem-img {
  display: none;
}
.bk-timeline-elem-text {
  padding: 0 2.4rem;
}
.bk-timeline-elem-text p {
  margin-bottom: 0;
}
.bk-timeline-elem-num {
  position: relative;
}
@media (min-width: 768px) {
  .bk-timeline-elem-num:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    border-bottom: 2px solid #FFCB05;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.bk-timeline-elem-num p {
  border: 2px solid #FFCB05;
  border-radius: 50px;
  margin: 0 auto;
  background-color: #FFFDF3;
  width: 5.6rem;
  height: 5.6rem;
  line-height: 5.6rem;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .bk-timeline-elem-num p {
    margin-bottom: 3.2rem;
    width: 7.8rem;
    height: 7.8rem;
    line-height: 7.3rem;
  }
}
@media (min-width: 768px) {
  .bk-timeline-elem:first-of-type .bk-timeline-elem-num:before {
    left: 100%;
  }
}
.bk-timeline-elem:last-of-type .bk-timeline-elem-num:before {
  height: 0;
}
@media (min-width: 768px) {
  .bk-timeline-elem:last-of-type .bk-timeline-elem-num:before {
    left: 0;
  }
}

.bk-comparateur {
  display: none;
}
@media (min-width: 1024px) {
  .bk-comparateur {
    display: block;
  }
}
.bk-comparateur table {
  margin-bottom: 0;
}
.bk-comparateur table thead tr th,
.bk-comparateur table thead tr td,
.bk-comparateur table tbody tr th,
.bk-comparateur table tbody tr td {
  font-size: 1.6rem;
}
.bk-comparateur table thead tr th:not(:first-of-type),
.bk-comparateur table thead tr td:not(:first-of-type),
.bk-comparateur table tbody tr th:not(:first-of-type),
.bk-comparateur table tbody tr td:not(:first-of-type) {
  padding: 2.4rem 1.6rem;
  text-align: center;
  width: 19.5%;
}
.bk-comparateur table thead tr th:nth-child(even),
.bk-comparateur table thead tr td:nth-child(even),
.bk-comparateur table tbody tr th:nth-child(even),
.bk-comparateur table tbody tr td:nth-child(even) {
  background-color: #FAFAFF;
  color: #000FFF;
}
.bk-comparateur table thead tr th:first-of-type,
.bk-comparateur table thead tr td:first-of-type,
.bk-comparateur table tbody tr th:first-of-type,
.bk-comparateur table tbody tr td:first-of-type {
  padding: 0;
  color: #535353 !important;
  width: 30rem;
}
.bk-comparateur table thead th {
  vertical-align: top;
}
.bk-comparateur table thead th.thtitle {
  font-weight: 600;
  font-size: 2rem;
  font-family: "Montserrat", Arial, sans-serif;
  color: #000FFF !important;
  padding-top: 2.4rem;
}
.bk-comparateur table thead th.top {
  position: relative;
  padding-bottom: 7rem !important;
}
.bk-comparateur table thead th.top p {
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
}
.bk-comparateur table thead th.top .col-title {
  font-family: "Montserrat", Arial, sans-serif;
}
.bk-comparateur table thead th.top .subtitle {
  font-weight: 400;
  font-size: 1.2rem;
  display: none;
}
@media (min-width: 1024px) {
  .bk-comparateur table thead th.top .subtitle {
    display: block;
  }
}
.bk-comparateur table thead th.top .btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 24px);
  min-width: 0;
  font-size: 1.4rem;
  font-family: "Montserrat", Arial, sans-serif;
  padding: 1.4rem 3.2rem;
  color: #fff !important;
}
.bk-comparateur table tbody .fold-title,
.bk-comparateur table tbody .fold-item {
  border-bottom: 1px solid #F1F1F1;
}
.bk-comparateur table tbody .fold-title {
  font-size: 1.8rem;
  font-weight: 500;
}
.bk-comparateur table tbody .icon-checked-comp:before {
  font-size: 1.5rem;
}
.bk-comparateur table tbody .fold-item a {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
.bk-comparateur table tbody .fold-item--price {
  font-size: 1.4rem;
  font-weight: 700;
}
@media (min-width: 1024px) {
  .bk-comparateur table tbody .fold-item--price {
    font-size: 2rem;
  }
}
.bk-comparateur table tbody .fold-item p {
  margin-bottom: 0;
}
.bk-comparateur table tbody .fold-item .ttc {
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  margin-top: 0;
  color: #706F6F;
}
.bk-comparateur table tbody .fold-item .ttc:after {
  content: " TTC";
  font-size: 1.4rem;
}
.bk-comparateur table tbody .fold-item .ht {
  font-size: 1.4rem;
  font-weight: 700;
}
@media (min-width: 1024px) {
  .bk-comparateur table tbody .fold-item .ht {
    font-size: 2rem;
  }
}
.bk-comparateur table tbody .fold-item .ht:after {
  content: " HT";
}

.bk-comparateur--mobile {
  display: block;
}
@media (min-width: 1024px) {
  .bk-comparateur--mobile {
    display: none;
  }
}
.bk-comparateur--mobile .subtitles--mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bk-comparateur--mobile .subtitles--mobile .subtitle--mobile {
  flex: 0 1 49%;
  margin: 0 0.8rem 0.8rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
}
.bk-comparateur--mobile .bk-comparateur__select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bk-comparateur--mobile .bk-comparateur__select .styled-select {
  flex: 0 1 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}
.bk-comparateur--mobile .bk-comparateur__select .styled-select:before {
  font-size: 2.2rem;
  max-width: 1.2rem;
  justify-content: flex-end;
}
.bk-comparateur--mobile .bk-comparateur__select .styled-select.--blue select {
  border-color: #000FFF;
}
.bk-comparateur--mobile .bk-comparateur__select .styled-select.--blue:before {
  color: #000FFF;
}
.bk-comparateur--mobile .bk-comparateur__select .styled-select select {
  white-space: break-spaces;
}
.bk-comparateur--mobile .bk-comparateur__btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bk-comparateur--mobile .bk-comparateur__btns .btn {
  flex: 0 1 49%;
  font-size: 1rem;
  padding: 1rem 1.8rem;
  margin: 0 0.4rem 4.8rem;
}
.bk-comparateur--mobile .view .icon-accordion {
  color: #000FFF;
  font-size: 3rem;
  transition: 0.2s;
  margin-left: 2.5rem;
}
.bk-comparateur--mobile .view .icon-accordion:before {
  content: "\e909";
}
@media (min-width: 1024px) {
  .bk-comparateur--mobile .view .icon-accordion {
    display: none;
  }
}
.bk-comparateur--mobile .view.hidden {
  margin-bottom: 1.6rem;
}
.bk-comparateur--mobile .view.hidden .icon-accordion {
  transform: rotate(180deg) !important;
}
.bk-comparateur--mobile .fold {
  display: block;
  margin-bottom: 4rem;
}
.bk-comparateur--mobile .fold.hidden {
  display: none;
}
.bk-comparateur--mobile .fold p {
  margin: 1.6rem 0 0.8rem;
}
.bk-comparateur--mobile .fold span {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0.8rem;
}
.bk-comparateur--mobile .fold span .fold-item-mobile {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  background-color: rgba(112, 111, 111, 0.1);
  padding: 1.2rem 1rem;
  flex: 0 1 49%;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 0.4rem;
}
.bk-comparateur--mobile .fold span .fold-item-mobile:last-of-type {
  background-color: #F2F2FF;
  color: #000FFF;
}
.bk-comparateur--mobile .fold span .fold-item-mobile-content {
  margin: 0;
}
.bk-comparateur--mobile .fold span .fold-item-mobile-content.ht:after {
  content: " HT";
}
.bk-comparateur--mobile .fold span .fold-item-mobile-ttc {
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  margin-top: 0;
  color: #706F6F;
}
.bk-comparateur--mobile .fold span .fold-item-mobile-ttc.empty {
  display: none;
}
.bk-comparateur--mobile .fold span .fold-item-mobile-ttc.empty:after {
  display: none;
}
.bk-comparateur--mobile .fold span .fold-item-mobile-ttc:after {
  content: " TTC";
  font-size: 1.4rem;
}

.bk-comparateur--mobile .view,
.bk-comparateur .view {
  border-bottom: 2px solid #E0E0E0;
}
.bk-comparateur--mobile .view td,
.bk-comparateur .view td {
  padding: 1.2rem 0 1.6rem;
}
.bk-comparateur--mobile .view-title,
.bk-comparateur .view-title {
  color: #000FFF;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  padding-top: 3.2rem;
  padding-bottom: 2.4rem;
  padding-left: 0;
  background: none;
  border: 0;
}
.bk-comparateur--mobile .view-title-mobile,
.bk-comparateur .view-title-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  color: #000FFF;
  font-family: "Montserrat", Arial, sans-serif;
  background: none;
  border: 0;
  padding: 1.2rem 0;
}
.bk-comparateur--mobile .view-title-mobile:after,
.bk-comparateur .view-title-mobile:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e909";
  color: #000FFF;
  font-size: 3rem;
  transition: 0.2s;
}
.bk-comparateur--mobile .view-title-mobile[aria-expanded=false]:after,
.bk-comparateur .view-title-mobile[aria-expanded=false]:after {
  transform: rotate(180deg) !important;
}
.bk-comparateur--mobile .description-mobile,
.bk-comparateur--mobile .fold-item-mobile-ttc,
.bk-comparateur--mobile .fold-item-mobile-content,
.bk-comparateur .description-mobile,
.bk-comparateur .fold-item-mobile-ttc,
.bk-comparateur .fold-item-mobile-content {
  display: none;
}
.bk-comparateur--mobile .description-mobile.visible,
.bk-comparateur--mobile .fold-item-mobile-ttc.visible,
.bk-comparateur--mobile .fold-item-mobile-content.visible,
.bk-comparateur .description-mobile.visible,
.bk-comparateur .fold-item-mobile-ttc.visible,
.bk-comparateur .fold-item-mobile-content.visible {
  display: block;
  margin-bottom: 0;
}

.bk-avantages {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.bk-avantages.centered .bk-card-avantages {
  text-align: center;
}
.bk-avantages.centered .bk-card-avantages__img {
  width: 100%;
}
@media (min-width: 768px) {
  .bk-avantages {
    flex-direction: row;
    justify-content: space-between;
  }
}

.bk-avantages--duo {
  flex-wrap: wrap;
}
.bk-avantages--duo .bk-card-avantages {
  margin: 0 3.2rem 3.2rem 0;
  border: 1px solid #CCCCFF;
  border-radius: 1.2rem;
  padding: 2.4rem;
  flex: 1;
  justify-content: space-between !important;
}
@media (min-width: 768px) {
  .bk-avantages--duo .bk-card-avantages {
    flex-basis: 47%;
  }
}

.bk-herobanner {
  padding: 4rem 2.4rem 0.8rem;
  background: linear-gradient(45deg, #000FFF 90%, #FFCB05 90%);
  color: #fff;
  min-height: 57.5rem;
  position: relative;
}
@media (min-width: 1366px) {
  .bk-herobanner {
    padding: 5.6rem;
    max-height: 57.5rem;
  }
}
.bk-herobanner__content {
  max-width: 120rem;
  margin: 5.5rem auto 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0.8rem;
}
@media (min-width: 1024px) {
  .bk-herobanner__content {
    flex-direction: row;
  }
}
.bk-herobanner__content__right img {
  border-radius: 2rem;
  filter: drop-shadow(0px 5px 24px rgba(27, 65, 142, 0.14));
}
@media (min-width: 1024px) {
  .bk-herobanner__content__right {
    margin-left: 4.8rem;
  }
}
.bk-herobanner__content__left {
  position: relative;
}
.bk-herobanner__content__left.novid {
  max-width: none;
  width: 100%;
}
.bk-herobanner__content__left.novid p,
.bk-herobanner__content__left.novid h1 {
  max-width: none;
}
.bk-herobanner__content__left h1,
.bk-herobanner__content__left p,
.bk-herobanner__content__left a,
.bk-herobanner__content__left .separator {
  margin: 0;
  margin-bottom: 1.2rem;
}
@media (min-width: 768px) {
  .bk-herobanner__content__left h1,
.bk-herobanner__content__left p,
.bk-herobanner__content__left a,
.bk-herobanner__content__left .separator {
    margin-bottom: 2.4rem;
  }
}
.bk-herobanner__content__left p,
.bk-herobanner__content__left h1 {
  color: #fff;
}
@media (min-width: 480px) {
  .bk-herobanner__content__left p,
.bk-herobanner__content__left h1 {
    max-width: 53rem;
  }
}
@media (min-width: 768px) {
  .bk-herobanner__content__left p,
.bk-herobanner__content__left h1 {
    max-width: 65.5rem;
  }
}
.bk-herobanner__content__left h1 {
  padding-right: 2rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .bk-herobanner__content__left h1 {
    padding-right: 0;
  }
}
.bk-herobanner__content__left p {
  font-weight: 500;
  color: #535353;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .bk-herobanner__content__left p {
    font-size: 2rem;
  }
}
@media (min-width: 1024px) {
  .bk-herobanner__content__left p {
    font-size: 2.4rem;
  }
}
.bk-herobanner__content__left .cat {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-bottom: 0.4rem;
}
@media (min-width: 768px) {
  .bk-herobanner__content__left .cat {
    font-size: 2rem;
  }
}
.bk-herobanner__content__left .sub {
  color: #535353;
  text-transform: inherit;
  font-weight: 500;
  font-size: 2.4rem;
}
.bk-herobanner__content__left .btn-wrapper {
  margin-bottom: 2.4rem;
  text-align: center;
}
@media (min-width: 768px) {
  .bk-herobanner__content__left .btn-wrapper {
    text-align: left;
    margin-bottom: auto;
  }
}
.bk-herobanner__content__left .btn-wrapper a {
  border: 1px solid #fff;
  margin-right: 2.4rem;
  z-index: 999;
}
.bk-herobanner.error {
  min-height: auto;
  padding: 10rem 2.4rem;
  background: linear-gradient(45deg, #FFCB05 12%, #000FFF 12%, #000FFF 88%, #FFCB05 88%);
  border-radius: 0;
  text-align: center;
}
.bk-herobanner.error .bk-herobanner--content {
  display: block;
  max-width: 64rem;
  width: 100%;
  margin: 0 auto;
}
.bk-herobanner.error .bk-herobanner--title {
  position: relative;
  font-weight: 600;
  color: #fff;
}
.bk-herobanner.error .bk-herobanner--title:after {
  content: "";
  display: block;
  position: absolute;
  width: 100px;
  height: 4px;
  bottom: -2rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #FFCB05;
}
@media (min-width: 1024px) {
  .bk-herobanner.error .bk-herobanner--title:after {
    bottom: -3rem;
    width: 130px;
  }
}
.bk-herobanner.error .bk-herobanner--text {
  font-size: 1.8rem;
}
@media (min-width: 1024px) {
  .bk-herobanner.error .bk-herobanner--text {
    font-size: 2.4rem;
  }
}

.bk-herobanner--homepage {
  background: linear-gradient(45deg, #000FFF 90%, #FFCB05 90%);
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage {
    background: linear-gradient(145deg, #000FFF 70%, #FFCB05 70%);
    max-height: 57.5rem;
  }
}
.bk-herobanner--homepage__logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.4rem;
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage__logo {
    margin-bottom: 0;
  }
}
.bk-herobanner--homepage__logo img {
  margin-left: 1rem;
}
.bk-herobanner--homepage .bk-herobanner__content {
  margin: 0 auto;
}
.bk-herobanner--homepage .bk-herobanner__content__bg {
  width: 100vw;
  margin-left: -3.2rem;
  margin-bottom: -1.6rem;
  position: relative;
}
@media (min-width: 480px) {
  .bk-herobanner--homepage .bk-herobanner__content__bg {
    width: 100%;
    margin-bottom: auto;
    margin-left: auto;
  }
  .bk-herobanner--homepage .bk-herobanner__content__bg img {
    border-radius: 1.2rem;
  }
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage .bk-herobanner__content__bg {
    position: absolute;
    right: 0;
    top: 0;
    min-height: 57.5rem;
    height: 100%;
    overflow: hidden;
  }
  .bk-herobanner--homepage .bk-herobanner__content__bg img {
    border-radius: 0;
  }
  .bk-herobanner--homepage .bk-herobanner__content__bg .player__btn__link {
    right: 15%;
    left: auto;
    transform: translateY(-50%);
    z-index: 10;
  }
}
.bk-herobanner--homepage .bk-herobanner__content__bg:before {
  content: "";
  background: linear-gradient(215deg, transparent 75%, #FFCB05 75%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  border-bottom-left-radius: 0;
}
@media (min-width: 480px) {
  .bk-herobanner--homepage .bk-herobanner__content__bg:before {
    border-bottom-left-radius: 1.2rem;
  }
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage .bk-herobanner__content__bg:before {
    display: none;
  }
}
.bk-herobanner--homepage .bk-herobanner__content__bg img {
  width: 100%;
  vertical-align: middle;
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage .bk-herobanner__content__bg img {
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 48% 100%);
    position: absolute;
    right: 0;
    width: auto;
  }
}
.bk-herobanner--homepage .bk-herobanner__content__left {
  position: relative;
  top: auto;
  transform: none;
  z-index: 1;
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage .bk-herobanner__content__left {
    position: absolute;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
  }
}
@media (min-width: 1366px) {
  .bk-herobanner--homepage .bk-herobanner__content__left {
    left: auto;
  }
}
.bk-herobanner--homepage .bk-herobanner__content__left h1 {
  padding-right: 2rem;
}
@media (min-width: 768px) {
  .bk-herobanner--homepage .bk-herobanner__content__left h1 {
    padding-right: 0;
  }
}
.bk-herobanner--homepage .bk-herobanner__content__left p {
  color: #fff;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .bk-herobanner--homepage .bk-herobanner__content__left p {
    font-size: 2rem;
  }
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage .bk-herobanner__content__left p {
    font-size: 2.4rem;
  }
}
@media (min-width: 1024px) {
  .bk-herobanner--homepage .bk-herobanner__content__left {
    width: 50%;
  }
}
@media (min-width: 1366px) {
  .bk-herobanner--homepage .bk-herobanner__content__left {
    width: 60%;
  }
}
.bk-herobanner--homepage .bk-herobanner__content__left .btn-wrapper {
  margin-bottom: 1.2rem;
}
.bk-herobanner--homepage .bk-herobanner__content__left .btn-wrapper a {
  margin-bottom: 1.6rem;
}

.bk-herobanner--simple {
  padding-bottom: 3rem;
}
.bk-herobanner--simple .bk-herobanner__content__left h1 {
  max-width: none;
}

.bk-herobanner--transparent {
  min-height: auto;
  background: linear-gradient(45deg, transparent 90%, #FFCB05 90%);
}
@media (min-width: 768px) {
  .bk-herobanner--transparent {
    background: linear-gradient(30deg, transparent 90%, #FFCB05 90%);
  }
}
.bk-herobanner--transparent .cat {
  color: #535353;
  position: absolute;
  bottom: 100%;
  left: 0;
}
@media (min-width: 768px) {
  .bk-herobanner--transparent .cat {
    bottom: 104%;
  }
}
.bk-herobanner--transparent h1 {
  color: #000FFF;
  padding-top: 0;
}
.bk-herobanner--transparent .btn {
  border-color: #000FFF !important;
}

.bk-herobanner--anchors {
  min-height: 0;
}
.bk-herobanner--anchors .bk-herobanner__content__left {
  width: 100%;
}
.bk-herobanner--anchors .bk-herobanner__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
@media (min-width: 768px) {
  .bk-herobanner--anchors .bk-herobanner__list {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.bk-herobanner--anchors .bk-herobanner__list li {
  margin-right: 1.2rem;
}
.bk-herobanner--anchors .bk-herobanner__list li:last-of-type {
  margin-right: 0;
}
.bk-herobanner--anchors .bk-herobanner__list li a {
  font-family: "Barlow", Arial, sans-serif;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.6rem;
}
.bk-herobanner--anchors .bk-herobanner__list li a.current-page {
  color: rgb(255, 255, 255) !important;
}
.bk-herobanner--anchors .bk-herobanner__list li a:hover:not(.-disabled), .bk-herobanner--anchors .bk-herobanner__list li a:active:not(.-disabled) {
  color: #fff;
}
@media (min-width: 768px) {
  .bk-herobanner--anchors .bk-herobanner__list li a {
    margin-bottom: 0;
    font-size: 2rem;
  }
}
@media (min-width: 1024px) {
  .bk-herobanner--anchors .bk-herobanner__list li a {
    font-size: 2.4rem;
  }
}

.bk-herobanner--list {
  background: linear-gradient(45deg, transparent 90%, #FFCB05 90%);
}
@media (min-width: 768px) {
  .bk-herobanner--list {
    background: linear-gradient(30deg, transparent 90%, #FFCB05 90%);
  }
}
.bk-herobanner--list .bk-herobanner__content {
  align-items: center;
}
@media (min-width: 768px) {
  .bk-herobanner--list .bk-herobanner__content {
    align-items: flex-start;
  }
}
.bk-herobanner--list .bk-herobanner__content__left {
  margin-bottom: 3.2rem;
}
.bk-herobanner--list .bk-herobanner__tiles {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
.bk-herobanner--list .bk-herobanner__tiles__item {
  width: 50%;
}
.bk-herobanner--list .bk-herobanner__tiles__item * {
  color: #535353;
  font-size: 1.8rem;
}
.bk-herobanner--list .bk-herobanner__tiles__item p {
  font-weight: 400;
}
.bk-herobanner--list .bk-herobanner__tiles__item__num {
  font-size: 2.8rem;
  font-weight: 600 !important;
  margin-bottom: 0;
  font-family: "Montserrat", Arial, sans-serif;
}
.bk-herobanner--list .cat {
  color: #535353;
}
.bk-herobanner--list p,
.bk-herobanner--list h1 {
  color: #000FFF;
}
.bk-herobanner--list .btn {
  border-color: #000FFF !important;
}

.bk-offer {
  text-align: center;
}
.bk-offer h2 {
  margin-bottom: 4.8rem;
}
.bk-offer__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .bk-offer__wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
}
.bk-offer__card {
  background-color: #FAFAFF;
  border: 2px solid #E5E5FF;
  border-radius: 1.2rem;
  padding: 2.4rem;
  margin-bottom: 3.2rem !important;
  width: 100%;
}
@media (min-width: 768px) {
  .bk-offer__card:not(:last-of-type) {
    margin-right: 2.4rem;
  }
}
@media (min-width: 768px) {
  .bk-offer__card {
    flex-basis: 50%;
    max-width: 58rem;
    margin-bottom: 6.4rem !important;
    padding: 6.4rem;
  }
}
.bk-offer__card .like-h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}
.bk-offer__card .ttc {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin-top: 0.8rem;
  font-family: "Montserrat", Arial, sans-serif;
  color: #706F6F;
}

.bk-links {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.bk-links a {
  margin: 0.8rem 0;
  width: 100%;
}
.bk-links a:hover:not([disabled]), .bk-links a:focus:not([disabled]) {
  background-color: #FAFAFF;
}
.paragraph-full-width .bk-links {
  max-width: 120rem;
  margin: 6.4rem auto;
  padding: 4.8rem 0.8rem;
}
.bk-links.full .bk-link {
  flex-basis: auto;
}
.bk-links.long-arrow .bk-link {
  color: #000FFF;
}
.bk-links.long-arrow .bk-link .icon-arrow-r:before {
  content: "\e907";
}
.bk-links.no-arrow .bk-link .icon-arrow-r:before {
  display: none;
}
.bk-links.large .bk-links {
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  .bk-links {
    flex-direction: row;
    justify-content: space-between;
  }
}
.bk-links__overhead {
  width: 100%;
  margin-bottom: 1.6rem;
}
.bk-links__overhead p {
  margin-bottom: 1.6rem;
}
.bk-links__overhead h2 {
  margin-bottom: 2.4rem;
}

.bk-link {
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 48%;
  margin: 0.8rem 0;
  width: 100%;
}
.bk-link__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600 !important;
  font-size: 1.8rem;
}
.bk-link__title p {
  text-align: left;
  margin-bottom: 0;
  font-weight: 600 !important;
}
.bk-link__content {
  font-weight: 400;
  font-family: "Barlow", Arial, sans-serif;
  font-size: 1.8rem;
  color: #535353;
  margin-top: 1.2rem;
}
.bk-link__content:last-of-type {
  margin-bottom: 0;
}
.bk-link:hover:not([disabled]), .bk-link:focus:not([disabled]) {
  color: #535353;
  background-color: #FAFAFF;
}
.bk-link:hover:not([disabled]) .bk-link__title, .bk-link:hover:not([disabled]) .bk-link__content, .bk-link:focus:not([disabled]) .bk-link__title, .bk-link:focus:not([disabled]) .bk-link__content {
  color: #535353;
}
.bk-link:hover:not([disabled]) span[class*=icon-arrow-r], .bk-link:focus:not([disabled]) span[class*=icon-arrow-r] {
  color: #535353;
  transform: translateX(8px);
  transition-duration: 0.5s;
}
.bk-link:active {
  color: #fff;
  background-color: #001EAE;
}
.bk-link--framed {
  border: 1px solid #E5E5FF;
  padding: 2.4rem;
  border-radius: 1.2rem;
  margin-bottom: 3.2rem;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .bk-link--framed {
    flex-basis: 47%;
  }
}
@media (min-width: 1024px) {
  .bk-link--framed:not(:last-of-type) {
    margin-right: 3.2rem;
  }
}
.bk-link--framed .title {
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Montserrat", Arial, sans-serif;
  color: #000FFF;
  margin: 0;
}
.bk-link--framed .sub {
  margin: 0;
  font-weight: 400;
  font-size: 1.8rem;
  color: #535353;
}
.bk-link--framed__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .bk-link--framed__wrapper {
    flex-direction: row;
    justify-content: center;
  }
}
.bk-link--list {
  padding: 3.2rem 4rem;
  border: 1px solid #CCCCFF;
  border-radius: 8px;
  flex-basis: 48%;
  margin: 0.8rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 4rem !important;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .bk-link--list {
    margin: 0;
  }
}
.bk-link--list:first-of-type {
  margin-left: 0;
}
.bk-link--list:last-of-type {
  margin-right: 0;
}
.bk-link--list a {
  margin-left: 0;
  font-size: 2rem;
  font-family: "Barlow", Arial, sans-serif;
  color: #535353;
  font-weight: 400;
  margin-bottom: 1.2rem;
}
@media (min-width: 768px) {
  .bk-link--list a {
    margin-bottom: 2.4rem;
  }
}
.bk-link--list a:hover:not([disabled]), .bk-link--list a:focus:not([disabled]) {
  background-color: transparent;
}
.bk-link--list a:first-of-type, .bk-link--list a:last-of-type {
  font-weight: 600;
  color: #000FFF;
}
.bk-link--list a:first-of-type {
  font-family: "Montserrat", Arial, sans-serif;
  text-decoration: none;
}
.bk-link--list a:last-of-type {
  margin-bottom: 0;
}

.bk-steps,
.bk-steps-bar {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .bk-steps,
.bk-steps-bar {
    flex-flow: row nowrap;
    align-items: center;
  }
}
.bk-steps__tabs, .bk-steps__scroll,
.bk-steps-bar__tabs,
.bk-steps-bar__scroll {
  margin-left: 0;
}
.bk-steps__tabs a, .bk-steps__scroll a,
.bk-steps-bar__tabs a,
.bk-steps-bar__scroll a {
  font-size: 1.6rem !important;
  color: #000FFF !important;
}
.bk-steps__tabs .scroll__elem, .bk-steps__scroll .scroll__elem,
.bk-steps-bar__tabs .scroll__elem,
.bk-steps-bar__scroll .scroll__elem {
  padding-bottom: 4.8rem;
  min-height: 27.5rem;
}
.bk-steps__tabs .scroll__elem *, .bk-steps__scroll .scroll__elem *,
.bk-steps-bar__tabs .scroll__elem *,
.bk-steps-bar__scroll .scroll__elem * {
  direction: ltr;
}
@media (min-width: 768px) {
  .bk-steps__tabs, .bk-steps__scroll,
.bk-steps-bar__tabs,
.bk-steps-bar__scroll {
    margin-left: 5rem;
  }
}
@media (min-width: 1024px) {
  .bk-steps__tabs, .bk-steps__scroll,
.bk-steps-bar__tabs,
.bk-steps-bar__scroll {
    margin-left: 10rem;
  }
}
.bk-steps__tabs, .bk-steps__panels,
.bk-steps-bar__tabs,
.bk-steps-bar__panels {
  flex-basis: 50%;
  width: 100%;
}
.bk-steps__tabs img, .bk-steps__panels img,
.bk-steps-bar__tabs img,
.bk-steps-bar__panels img {
  border-radius: 12px;
  width: 100%;
}
.bk-steps__tabs.straight-img img, .bk-steps__panels.straight-img img,
.bk-steps-bar__tabs.straight-img img,
.bk-steps-bar__panels.straight-img img {
  border-radius: 0;
}
.bk-steps__item,
.bk-steps-bar__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 3.2rem 0 1.2rem 0;
  text-align: center;
  position: relative;
}
.bk-steps__item p,
.bk-steps-bar__item p {
  margin-top: 0.6rem;
}
@media (min-width: 768px) {
  .bk-steps__item,
.bk-steps-bar__item {
    min-height: 15rem;
    margin: 2.4rem 0;
    padding: 1.6rem 2.4rem;
    text-align: left;
  }
  .bk-steps__item:before,
.bk-steps-bar__item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0.6rem;
    height: 100%;
    background-color: #E0E0E0;
    border-radius: 0.8rem;
  }
  .bk-steps__item:active, .bk-steps__item[aria-selected=true], .bk-steps__item:focus,
.bk-steps-bar__item:active,
.bk-steps-bar__item[aria-selected=true],
.bk-steps-bar__item:focus {
    outline: none;
  }
  .bk-steps__item:active:before, .bk-steps__item[aria-selected=true]:before, .bk-steps__item:focus:before,
.bk-steps-bar__item:active:before,
.bk-steps-bar__item[aria-selected=true]:before,
.bk-steps-bar__item:focus:before {
    background-color: #000FFF;
  }
}
.bk-steps__item:hover,
.bk-steps-bar__item:hover {
  cursor: pointer;
}
.bk-steps__item .like-h3,
.bk-steps__item .like-h4,
.bk-steps-bar__item .like-h3,
.bk-steps-bar__item .like-h4 {
  margin-bottom: 1.2rem;
  color: #535353;
}
@media (min-width: 768px) {
  .bk-steps__item .like-h3,
.bk-steps__item .like-h4,
.bk-steps-bar__item .like-h3,
.bk-steps-bar__item .like-h4 {
    margin-bottom: 0.8rem;
  }
}
.bk-steps__item .content,
.bk-steps-bar__item .content {
  margin: 0;
}
.bk-steps .bk-slider__dots,
.bk-steps-bar .bk-slider__dots {
  margin: 0 auto;
}

.bk-steps-bar {
  align-items: stretch;
  justify-content: space-between;
}
.bk-steps-bar h5 {
  color: #535353;
}
.bk-steps-bar__img {
  flex: 0 0 auto;
  overflow-y: hidden;
  margin: 0 auto 2.4rem;
}
@media (min-width: 768px) {
  .bk-steps-bar__img {
    margin: 0;
  }
}
.bk-steps-bar__img .img-container {
  display: none;
}
@media (min-width: 769px) {
  .bk-steps-bar__img .img-container {
    display: block;
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
  }
}
.bk-steps-bar__img .img-container.visible {
  display: block;
  visibility: hidden;
  height: 0;
  opacity: 0;
}
@media (min-width: 769px) {
  .bk-steps-bar__img .img-container.visible {
    display: block;
    opacity: 1;
    visibility: visible !important;
    height: 100%;
    transition-delay: 0.5s;
  }
}
.bk-steps-bar__img img {
  border-radius: 1.2rem;
  width: 100%;
  max-height: 68.7rem;
}
.bk-steps-bar__item::before {
  display: none;
}
.bk-steps-bar__scroll {
  flex: 0 1 50%;
  background-color: transparent;
  width: 100%;
  height: 68.7rem;
  overflow-x: hidden;
  overflow-y: auto;
  direction: rtl;
  margin-bottom: 5.6rem;
  text-align: left;
}
@media (min-width: 768px) {
  .bk-steps-bar__scroll {
    /* add vertical min-height & horizontal min-width */
    padding-left: 5.6rem;
  }
  .bk-steps-bar__scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 100px;
  }
  .bk-steps-bar__scroll::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
  .bk-steps-bar__scroll::-webkit-scrollbar-track {
    background-color: #FAFAFF;
  }
  .bk-steps-bar__scroll::-webkit-scrollbar-thumb {
    background: #000FFF;
    background-color: #000FFF;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
  }
  .bk-steps-bar__scroll::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.61);
    /* Some darker color when you click it */
    border-radius: 100px;
  }
  .bk-steps-bar__scroll::-webkit-scrollbar-thumb:vertical {
    min-height: 6px;
  }
  .bk-steps-bar__scroll::-webkit-scrollbar-thumb:horizontal {
    min-width: 6px;
  }
}
.bk-steps-bar__scroll h3:first-of-type {
  margin-top: 0;
}
.bk-steps-bar__scroll h4,
.bk-steps-bar__scroll h5 {
  margin-bottom: 0.6rem;
  margin-top: 0;
  padding-top: 3.2rem;
}
.bk-steps-bar__scroll p {
  margin: 0.6rem 0;
}
.bk-steps-bar__scroll .btn {
  color: #fff !important;
  margin-top: 2.4rem;
}
.bk-steps-bar__scroll .min-img-container img {
  border-radius: 1.2rem;
}
@media (min-width: 768px) {
  .bk-steps-bar__scroll .min-img-container {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .bk-steps-bar__scroll__elem:last-of-type {
    margin-bottom: 34.4rem;
  }
}
.bk-steps-bar .progress {
  display: block;
  width: 1rem;
  height: 68.7rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid red;
}

.bk-tarifs .grid__cell {
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  .bk-tarifs .grid__cell {
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  .bk-tarifs {
    margin-top: auto;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.bk-tarifs .bk-card-tarif {
  height: 100%;
  margin-top: 1.6rem;
}
@media (min-width: 768px) {
  .bk-tarifs .bk-card-tarif {
    margin: 0 1.2rem;
  }
}
.bk-tarifs .bk-card-tarif:first-of-type {
  margin-left: 0;
}
.bk-tarifs .bk-card-tarif:last-of-type {
  margin-right: 0;
}
.bk-tarifs .bk-card-offre {
  height: 100%;
  margin: 1.6rem 0;
}
@media (min-width: 768px) {
  .bk-tarifs .bk-card-offre {
    margin: 0 1.2rem;
  }
}

.bk-thirds {
  background-color: #FAFAFF;
  padding: 3.2rem 0;
}
.bk-thirds h2 {
  margin-bottom: 3.2rem;
}
@media (min-width: 768px) {
  .bk-thirds {
    padding: 6.4rem;
  }
}
.bk-thirds__split {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
@media (min-width: 768px) {
  .bk-thirds__split {
    flex-direction: row;
  }
}
.bk-thirds__split .bk-card-third {
  flex-basis: 33%;
}
.bk-thirds__split .bk-card-third:not(:first-of-type) {
  margin-left: 3.2rem;
}
@media (max-width: 767px) {
  .bk-thirds__split .bk-card-third:not(:first-of-type) {
    margin-left: 0;
  }
}
.bk-thirds__split .bk-card-third__link {
  margin-top: 0.8rem;
}
.bk-thirds__split .bk-card-third.solo {
  flex-basis: 100%;
}
.bk-thirds__split .bk-card-third.large {
  flex-basis: 66%;
}
.bk-thirds__split .bk-card-third.medium {
  flex-basis: 50%;
}

.bk-list h2 {
  margin-bottom: 3.2rem;
}
.bk-list.horizontal {
  max-width: 120rem;
  margin: 0 auto;
  padding: 1.6rem 0.8rem;
}
.bk-list.horizontal h2 {
  margin-bottom: 4.8rem;
}
.bk-list.horizontal ul {
  display: flex;
  flex-direction: column;
  margin: 0;
}
@media (min-width: 768px) {
  .bk-list.horizontal ul {
    flex-direction: row;
    justify-content: space-between;
  }
}
.bk-list.horizontal ul li:before {
  color: #000FFF;
  font-size: 1.6rem;
}
.bk-list.horizontal ul li.checked {
  padding-left: 4rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .bk-list.horizontal ul li.checked {
    margin-bottom: 1.2rem;
  }
}
.bk-list.horizontal ul li.checked:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e938";
  color: #fff;
  background-color: #FFCB05;
  height: 2.8rem;
  width: 2.8rem;
  line-height: 2.8rem;
  font-size: 1.8rem;
  text-align: center;
}
.bk-list.horizontal ul li a {
  margin-bottom: 0.8rem;
}
.bk-list.horizontal ul li p {
  margin: 0;
}

.bk-tableau {
  padding: 4rem;
  border: 1px solid #CCCCFF;
  border-radius: 1.2rem;
}
.bk-tableau__row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.bk-tableau__row:not(:last-of-type) {
  border-bottom: 1px solid #CCCCFF;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .bk-tableau__row {
    flex-direction: row;
  }
}
.bk-tableau__cell {
  width: 100%;
}
.bk-tableau__cell .title {
  margin-bottom: 0.8rem;
  color: #535353;
}
.bk-tableau__cell .content {
  max-width: 22.5rem;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 768px) {
  .bk-tableau__cell .content {
    margin-bottom: 0;
  }
}
.bk-tableau__cell .content li {
  font-size: 1.6rem;
}
.bk-tableau__cell .content li::before {
  color: #535353;
}

.bk-expertise {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.bk-expertise .bk-card-pro {
  flex-basis: 100%;
}
@media (min-width: 768px) {
  .bk-expertise .bk-card-pro {
    flex-basis: 22%;
  }
}

.bk-expertise--duo .bk-card-pro {
  margin-bottom: 3.2rem;
}
@media (min-width: 768px) {
  .bk-expertise--duo .bk-card-pro {
    flex-basis: 48%;
  }
}

.bk-certificats {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.bk-certificat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3.2rem;
}
.bk-certificat__elem {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-weight: 400;
}
.bk-certificat__elem span {
  font-size: 1.8rem;
  font-weight: 600;
}
.bk-certificat__elem span:after {
  content: " : ";
}
.bk-certificat__elem a {
  font-size: 1.6rem;
  font-weight: 400;
}
.bk-certificat__elem--list__title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0;
}
.bk-certificat__elem--list__title:after {
  content: " : ";
}
.bk-certificat__elem--list ul {
  margin-top: 0;
}
.bk-certificat__elem--list ul li {
  margin-bottom: 0;
}
.bk-certificat__elem--list ul li::before {
  color: #535353;
}
@media (min-width: 768px) {
  .bk-certificat {
    flex-basis: 48%;
  }
}

.bk-mail-comparaison {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .bk-mail-comparaison {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}
.bk-mail-comparaison__elem {
  text-align: left;
  padding: 4.8rem 0;
}
.bk-mail-comparaison__elem:last-of-type {
  padding-top: 0;
}
@media (min-width: 481px) {
  .bk-mail-comparaison__elem:last-of-type {
    padding: 4.8rem 0;
  }
}
@media (min-width: 768px) {
  .bk-mail-comparaison__elem:last-of-type {
    padding: 4.8rem;
  }
}
@media (min-width: 481px) {
  .bk-mail-comparaison__elem {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .bk-mail-comparaison__elem {
    padding: 4.8rem;
  }
}
.bk-mail-comparaison__elem__title {
  font-weight: 600;
  font-size: 2.2rem;
  color: #000FFF;
  font-family: "Montserrat", Arial, sans-serif;
  margin-bottom: 1.6rem;
}
@media (min-width: 768px) {
  .bk-mail-comparaison__elem__title {
    margin-bottom: 2.4rem;
  }
}
@media (min-width: 1024px) {
  .bk-mail-comparaison__elem__title {
    margin-bottom: 3.2rem;
  }
}

.bk-double-pics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .bk-double-pics {
    flex-direction: row;
  }
}
.bk-double-pics figure {
  margin: 1.6rem;
}
.bk-double-pics figure img {
  border-radius: 1.2rem;
}
@media (min-width: 768px) {
  .bk-double-pics figure img {
    margin: 0 1.6rem;
  }
}
.bk-double-pics figure figcaption p {
  text-align: left;
  margin: 0;
}
@media (min-width: 768px) {
  .bk-double-pics figure figcaption p {
    text-align: center;
  }
}

.wyg {
  margin: 2rem 0;
}