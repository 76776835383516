/*
 * bk-video-wrapper
 */
.bk-video-wrapper {
    @include bk-type;
    position: relative;
    padding-top: 56.25%; /* 16:9 */

    &--ratio-4-3 {
        padding-top: 75%;
    }

    embed,
    iframe,
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
