$a: '.bk-links';
$b: '.bk-link';

#{$a} {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    a {
        // flex-basis: 48%;
        margin: rem($spacer-xxs) 0;
        width: 100%;

        &:hover,
        &:focus {
            &:not([disabled]) {
                background-color: $blue03;
            }
        }
    }

    .paragraph-full-width & {
        max-width: rem($sc-max-width);
        margin: rem($spacer-xxl) auto;
        padding: rem($spacer-l) rem($sc-x-padding);
    }

    // .bg--blue & {
    //     #{$b} {
    //         background: $white;
    //         color: $color-primary;
    //         border: 1px solid $blue50;

    //         span {
    //             &[class*='icon-arrow-r'] {
    //                 color: $color-primary;
    //             }
    //         }
    //     }
    // }

    &.full {
        #{$b} {
            flex-basis: auto;
        }
    }

    &.long-arrow {
        #{$b} {
            color: $blue;

            .icon-arrow-r {
                &:before {
                    content: $icon-arrow-line-r;
                }
            }
        }
    }

    &.no-arrow {
        #{$b} {
            .icon-arrow-r {
                &:before {
                    display: none;
                }
            }
        }
    }

    &.large {
        #{$a} {
            font-size: rem(28px);
        }
    }

    @include media('>=tablet') {
        flex-direction: row;
        justify-content: space-between;
    }

    &__overhead {
        width: 100%;
        margin-bottom: rem($spacer-s);

        p {
            margin-bottom: rem($spacer-s);
        }

        h2 {
            margin-bottom: rem($spacer-m);
        }
    }
}

#{$b} {
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 48%;
    margin: rem($spacer-xxs) 0;
    width: 100%;

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-family: $secondary-fonts;
        font-weight: 600 !important;
        font-size: rem(18px);

        p {
            text-align: left;
            margin-bottom: 0;
            font-weight: 600 !important;
        }
    }

    &__content {
        font-weight: 400;
        font-family: $default-fonts;
        font-size: rem(18px);
        color: $color-text;
        margin-top: rem($spacer-xs);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &:hover,
    &:focus {
        &:not([disabled]) {
            color: $color-text;
            background-color: $blue03;

            #{$b} {
                &__title,
                &__content {
                    color: $color-text;
                }
            }

            span {
                &[class*='icon-arrow-r'] {
                    color: $color-text;
                    transform: translateX(8px);
                    transition-duration: .5s;
                }
            }
        }
    }

    &:active {
        color: $white;
        background-color: #001EAE;
    }

    &--framed {
        border: 1px solid $blue10;
        padding: rem($spacer-m);
        border-radius: rem(12px);
        margin-bottom: rem($spacer-m2);
        text-decoration: none;
        font-size: rem(18px);
        font-weight: 400;

        @include media('>=medium') {
            flex-basis: 47%;
        }

        &:not(:last-of-type) {
            @include media('>=medium') {
                margin-right: rem($spacer-m2);
            }
        }


        .title {
            font-size: rem(22px);
            font-weight: 600;
            font-family: $secondary-fonts;
            color: $color-primary;
            margin: 0;
        }

        .sub {
            margin: 0;
            font-weight: 400;
            font-size: rem(18px);
            color: $color-text;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include media('>=tablet') {
                flex-direction: row;
                justify-content: center;
            }
        }
    }

    &--list {
        padding: rem($spacer-m2) rem(40px);
        border: 1px solid $blue20;
        border-radius: 8px;
        flex-basis: 48%;
        margin: rem($spacer-xxs) 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        margin-bottom: rem(40px) !important;
        justify-content: space-between;

        @include media('>=tablet') {
            margin: 0;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        a {
            margin-left: 0;
            font-size: rem(20px);
            font-family: $default-fonts;
            color: $color-text;
            font-weight: 400;
            margin-bottom: rem($spacer-xs);

            @include media('>=tablet') {
                margin-bottom: rem($spacer-m);
            }

            &:hover,
            &:focus {
                &:not([disabled]) {
                    background-color: transparent;
                }
            }

            &:first-of-type,
            &:last-of-type {
                font-weight: 600;
                color: $color-primary;
            }

            &:first-of-type {
                font-family: $secondary-fonts;
                text-decoration: none;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
