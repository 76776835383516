$a: '.bk-certificats';
$b: '.bk-certificat';

#{$a} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#{$b} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: rem($spacer-m2);

    &__elem {
        margin-bottom: rem($spacer-s);
        font-size: rem(16px);
        font-weight: 400;

        span {
            font-size: rem(18px);
            font-weight: 600;

            &:after {
                content: ' : ';
            }
        }

        a {
            font-size: rem(16px);
            font-weight: 400;
        }

        &--list {
            &__title {
                font-size: rem(18px);
                font-weight: 600;
                margin-bottom: 0;

                &:after {
                    content: ' : ';
                }
            }

            ul {
                margin-top: 0;

                li {
                    margin-bottom: 0;

                    &::before {
                        color: $color-text;
                    }
                }
            }
        }

    }

    @include media('>=tablet') {
        flex-basis: 48%;
    }
}
