$c: '.footer';

#{$c} {
    &__wrapper {
        // border-top: 1px solid $color-primary;
        background-color: $blue03;
    }

    &__top,
    &__middle,
    &__bottom {
        padding: rem($spacer-xl) rem($spacer-m);

        .like-h4 {
            margin-bottom: rem($spacer-xs);

            @include media('>=medium') {
                margin-bottom: rem($spacer-m);
            }
        }

        ul {
            @extend %no-styled-list;

            a {
                text-decoration: none;
                font-family: $default-fonts;
            }
        }

        .inline-list {
            li {
                margin-right: rem($spacer-xxs);

                @include media('>=tablet') {
                    margin-right: rem($spacer-m);
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .site-content {
            @include last-child-no-margin;
        }
    }

    &__top,
    &__middle {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid $light-grey;
        flex-direction: column;

        ul,
        p {
            margin-bottom: rem($spacer-s);
        }

        @include media('>=medium') {
            flex-direction: row;

            ul {
                margin: 0;
            }
        }
    }

    &__top {
        ul {
            li {
                margin-bottom: rem($spacer-xs);

                a {
                    color: $color-text;

                    &:not(.like-h4) {
                        color: $color-text;
                        font-weight: 400;
                    }

                    &:active,
                    &:hover {
                        color: $blue;
                    }
                }

                @include media('>=tablet') {
                    margin-bottom: rem($spacer-s);
                }
            }
        }

        .inline-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            @include media('>=tablet') {
                width: auto;
            }

            a {
                height: rem(64px);
                width: rem(64px);
                position: relative;
                border: 1px solid $light-grey;
                border-radius: rem(50px);
                font-size: rem(20px);

                &:hover {
                    cursor: pointer;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $color-primary;

                    &.icon-youtube,
                    &.icon-twitter,
                    &.icon-facebook {
                        font-size: rem(24px);
                    }
                }
            }
        }
    }

    &__middle {
        align-items: center;

        ul {
            margin-bottom: rem($spacer-m2);

            li {
                margin-bottom: 0;

                @include media('>=tablet') {
                    margin-bottom: rem($spacer-s);
                }
            }
        }
    }

    &__bottom {
        a {
            font-weight: 400;
            font-size: rem(12px);
            color: $color-text !important;

            &:active,
            &:hover {
                color: $blue;
            }

            @include media('>=tablet') {
                font-size: rem(18px);
            }
        }
    }
}
