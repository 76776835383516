$a: '.bk-contact';

#{$a} {
    &-card {
        &__left {
            text-align: right !important;
        }

        &__right {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;

            span {
                margin-top: $spacer-s;
            }
        }
    }

    &-txt {
        &__details {
            background-color: $lightest-grey;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            padding: $spacer-xs;

            @include media('>mobile') {
                padding: $spacer-xxs;
            }

            span {
                margin-top: $spacer-s;
            }
        }

        &__para {
            margin-top: $spacer-s;


            @include media('>mobile') {
                margin-top: 0;
            }
        }
    }
}
