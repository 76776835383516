/*
 * pop-in
 */

.popin {
    position: relative;
    max-width: rem(1200px);
    margin: rem(20px) rem($spacer-xs);
    padding: rem($spacer-m);
    background-color: $white;
    box-shadow: 0 4px 24px 0 rgba($black, .5);
    border-radius: rem(8px);

    @include media('>=mobile') {
        margin: rem(20px) auto;
    }

    @include media('>=medium') {
        padding: rem($spacer-l) rem($spacer-m2);
    }

    > .mfp-close {
        width: rem(40px);
        height: rem(40px);
        top: rem(-44px);
        right: rem(0);
        // border: 1px solid $black;
        border-radius: rem(8px);
        // text-indent: rem(-5000px);
        background: #000FFF;
        color: #fff;

        &:after {
            @include icomoon;
            content: $icon-close;
            position: absolute;
            left: 50%;
            top: 50%;
            // font-size: rem(25px);
            transform: translate(-50%, -50%);
            text-indent: 0;
            font-size: rem(16px);
        }

        @include media('>=medium') {
            color: #535353;
            background: #fff;
            right: rem(-64px);
            top: 0;
        }
    }

    &--s {
        max-width: rem(600px);
    }


    &__head {
        padding: 0 rem($spacer-m) rem($spacer-m);
        font-size: rem(20px);
        font-weight: bold;
        text-align: center;

        h1 {
            font-size: rem(20px);
        }
    }

    &__body {
        @include last-child-no-margin;
    }
}



//Animation
/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all .15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: .8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all .15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

//popins specifics
