$c: '.btn-back-to-top';

#{$c} {
    position: fixed;
    right: 0;
    bottom: 10vh;
    padding: $spacer-xs;
    background: $color-primary;
    color: $white;
    text-decoration: none;
    transform: translateX(100%);
    transition: transform .2s;

    [class^='icon-'] {
        display: inline-block;
        transition: transform .2s;
    }

    &:hover {
        color: $white;

        [class^='icon-'] {
            transform: scale(1.2) translateY(-5px);
        }
    }

    &.is-visible {
        transform: translateX(0);
    }
}
