$c: '.bk-text';

#{$c} {
    &-framed {
        border: 1px solid $blue10;
        padding: rem($spacer-m) rem($spacer-m2);
        border-radius: rem(12px);
        margin-bottom: rem($spacer-m2);

        &:not(:last-of-type) {
            @include media('>=medium') {
                margin-right: rem($spacer-m2);
            }
        }

        .title {
            font-size: rem(32px);
            font-weight: 600;
            font-family: $secondary-fonts;
            margin-bottom: rem($spacer-m);
            color: $blue;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include media('>=medium') {
                flex-direction: row;
                justify-content: center;
            }

            &--extended {
                @include media('>=medium') {
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
        }

        .btn {
            width: 100%;

            @include media('>=tablet') {
                width: auto;
            }
        }
    }

    &-media {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;

        @include media('>=tablet') {
            flex-direction: row;
        }

        &--reverse {
            @include media('>=tablet') {
                flex-direction: row-reverse;
                justify-content: space-between;
            }

            #{$c} {
                &-media {
                    &__textblock {
                        @include media('>=tablet') {
                            margin-left: 0;
                            padding-left: 0;
                        }
                    }

                    &__imageblock {
                        @include media('>=tablet') {
                            @include last-child-no-margin;
                            margin-left: rem($spacer-m);
                            padding-left: rem($spacer-s);
                        }

                        @include media('>=medium') {
                            margin-left: rem($spacer-xxl);
                        }
                    }
                }
            }
        }

        &__imageblock {
            width: 100%;
            height: 100%;
            flex-shrink: 0;

            @include media('>=tablet') {
                max-width: rem(440px);
            }

            img {
                display: block;
                border-radius: 12px;
                width: 100%;

                @include media('>=tablet') {
                    width: auto;
                }
            }

            &--copy {
                margin-top: rem($spacer-xs);
                font-size: rem(12px);
                text-align: right;
            }
        }

        &__textblock {
            margin-bottom: rem($spacer-m);
            margin-left: 0;

            @include media('>=tablet') {
                @include last-child-no-margin;
                margin-bottom: 0;
                margin-left: rem($spacer-m);
                padding-left: rem($spacer-s);
            }

            @include media('>=medium') {
                margin-left: rem($spacer-xxl);
            }

            &__title {
                font-size: rem($spacer-m);
                margin-bottom: rem($spacer-m);
            }

            &__desc {
                margin-bottom: rem($spacer-m);

                p,
                li {
                    @extend %body-medium;
                }
            }
        }
    }
}
