.bk-avantages {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;

    &.centered {
        .bk-card-avantages {
            text-align: center;

            &__img {
                width: 100%;
            }
        }
    }

    @include media('>=tablet') {
        flex-direction: row;
        justify-content: space-between;
    }
}

.bk-avantages--duo {
    flex-wrap: wrap;

    .bk-card-avantages {
        margin: 0 rem($spacer-m2) rem($spacer-m2) 0;
        border: 1px solid $blue20;
        border-radius: rem(12px);
        padding: rem($spacer-m);
        flex: 1;
        justify-content: space-between !important;

        @include media('>=tablet') {
            flex-basis: 47%;
        }
    }
}
