$a: '.bk-herobanner';
$hero-height: 575px;

#{$a} {
    padding: rem(40px) rem($spacer-m) rem($spacer-xxs);
    background: linear-gradient(45deg, $blue 90%, $yellow 90%);
    color: $white;
    min-height: rem($hero-height);
    position: relative;

    @include media('>=desktop') {
        padding: rem($spacer-xl);
        max-height: rem($hero-height);
    }

    &__content {
        max-width: rem($sc-max-width);
        margin: rem(55px) auto 0;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 rem($sc-x-padding);

        @include media('>=medium') {
            flex-direction: row;
        }

        &__right {
            img {
                border-radius: rem(20px);
                filter: drop-shadow(0px 5px 24px rgba(27, 65, 142, .14));
            }

            @include media('>=medium') {
                margin-left: rem($spacer-l);
            }
        }

        &__left {
            position: relative;

            &.novid {
                max-width: none;
                width: 100%;

                p,
                h1 {
                    max-width: none;
                }
            }

            h1,
            p,
            a,
            .separator {
                margin: 0;
                margin-bottom: rem($spacer-xs);

                @include media('>=tablet') {
                    margin-bottom: rem($spacer-m);
                }
            }

            p,
            h1 {
                color: $white;
                // max-width: rem(275px);

                @include media('>=mobile') {
                    max-width: rem(530px);
                }

                @include media('>=tablet') {
                    max-width: rem(655px);
                }
            }

            h1 {
                padding-right: rem(20px);
                font-weight: 600;

                @include media('>=tablet') {
                    padding-right: 0;
                }
            }

            p {
                font-weight: 500;
                color: $dark-grey;
                font-size: rem(16px);
                margin-bottom: rem($spacer-m);

                @include media('>=tablet') {
                    font-size: rem(20px);
                }

                @include media('>=medium') {
                    font-size: rem(24px);
                }
            }

            .cat {
                text-transform: uppercase;
                font-size: rem(14px);
                font-weight: 400;
                color: $white;
                margin: 0;
                margin-bottom: rem(4px);

                @include media('>=tablet') {
                    font-size: rem(20px);
                }
            }

            .sub {
                color: $color-text;
                text-transform: inherit;
                font-weight: 500;
                font-size: rem(24px);
            }

            .btn-wrapper {
                margin-bottom: rem($spacer-m);
                text-align: center;

                @include media('>=tablet') {
                    text-align: left;
                    margin-bottom: auto;
                }

                a {
                    border: 1px solid $white;
                    margin-right: rem($spacer-m);
                    z-index: 999;
                }
            }
        }
    }

    &.error {
        min-height: auto;
        padding: rem(100px) rem($spacer-m);
        background: linear-gradient(45deg, $yellow 12%, $blue 12%, $blue 88%, $yellow 88%);
        border-radius: 0;
        text-align: center;

        #{$a}--content {
            display: block;
            max-width: rem(640px);
            width: 100%;
            margin: 0 auto;
        }

        #{$a}--title {
            position: relative;
            font-weight: 600;
            color: $white;

            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 100px;
                height: 4px;
                bottom: rem(-20px);
                left: 50%;
                transform: translate(-50%, 0);
                background-color: $yellow;

                @include media('>=medium') {
                    bottom: rem(-30px);
                    width: 130px;
                }
            }
        }

        #{$a}--text {
            font-size: rem(18px);

            @include media('>=medium') {
                font-size: rem(24px);
            }
        }
    }
}

#{$a}--homepage {
    background: linear-gradient(45deg, $blue 90%, $yellow 90%);

    @include media('>=medium') {
        background: linear-gradient(145deg, $blue 70%, $yellow 70%);
        max-height: rem($hero-height);
    }

    &__logo {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: rem(24px);

        @include media('>=medium') {
            margin-bottom: 0;
        }

        img {
            margin-left: rem(10px);
        }
    }

    #{$a} {
        &__content {
            margin: 0 auto;

            &__bg {
                width: 100vw;
                margin-left: rem(-$spacer-m2);
                margin-bottom: rem(-$spacer-s);
                position: relative;

                @include media('>=mobile') {
                    width: 100%;
                    margin-bottom: auto;
                    margin-left: auto;

                    img {
                        border-radius: rem(12px);
                    }
                }

                @include media('>=medium') {
                    position: absolute;
                    right: 0;
                    top: 0;
                    min-height: rem($hero-height);
                    height: 100%;
                    overflow: hidden;

                    img {
                        border-radius: 0;
                    }

                    .player__btn {
                        &__link {
                            right: 15%;
                            left: auto;
                            transform: translateY(-50%);
                            z-index: 10;
                        }
                    }
                }

                &:before {
                    // @include icomoon;
                    content: '';
                    background: linear-gradient(215deg, transparent 75%, $yellow 75%);
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 9;
                    border-bottom-left-radius: 0;

                    @include media('>=mobile') {
                        border-bottom-left-radius: rem(12px);
                    }

                    @include media('>=medium') {
                        display: none;
                    }
                }

                img {
                    width: 100%;
                    vertical-align: middle;

                    @include media('>=medium') {
                        // clip-path: polygon(25% 0, 100% 0, 100% 100%, 45% 100%);
                        clip-path: polygon(20% 0,100% 0,100% 100%,48% 100%);
                        position: absolute;
                        right: 0;
                        width: auto;
                    }
                }
            }

            &__left {
                position: relative;
                top: auto;
                transform: none;
                z-index: 1;

                @include media('>=medium') {
                    position: absolute;
                    top: 50%;
                    left: rem($spacer-m);
                    transform: translateY(-50%);
                }

                @include media('>=desktop') {
                    left: auto;
                }

                h1 {
                    padding-right: rem(20px);

                    @include media('>=tablet') {
                        padding-right: 0;
                    }
                }

                p {
                    color: $white;
                    font-size: rem(16px);
                    margin-bottom: rem($spacer-m);

                    @include media('>=tablet') {
                        font-size: rem(20px);
                    }

                    @include media('>=medium') {
                        font-size: rem(24px);
                    }
                }

                @include media('>=medium') {
                    width: 50%;
                }

                @include media('>=desktop') {
                    width: 60%;
                }

                .btn-wrapper {
                    margin-bottom: rem($spacer-xs);

                    a {
                        margin-bottom: rem($spacer-s);
                    }
                }
            }
        }
    }
}

#{$a}--simple {
    padding-bottom: rem(30px);
    #{$a} {
        &__content {
            &__left {
                h1 {
                    max-width: none;
                }
            }
        }
    }
}

#{$a}--transparent {
    min-height: auto;
    background: linear-gradient(45deg, transparent 90%, $yellow 90%);

    @include media('>=tablet') {
        background: linear-gradient(30deg, transparent 90%, $yellow 90%);
    }

    .cat {
        color: $color-text;
        position: absolute;
        bottom: 100%;
        left: 0;

        @include media('>=tablet') {
            bottom: 104%;
        }
    }

    h1 {
        color: $blue;
        padding-top: 0;
    }

    .btn {
        border-color: $blue !important;
    }
}

#{$a}--anchors {
    min-height: 0;

    #{$a} {
        &__content {
            &__left {
                width: 100%;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            @include media('>=tablet') {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            li {
                margin-right: rem($spacer-xs);

                &:last-of-type {
                    margin-right: 0;
                }

                a {
                    font-family: $default-fonts;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: rem(16px);
                    color: rgba(255, 255, 255, .8);
                    margin-bottom: rem($spacer-s);

                    &.current-page {
                        color: rgba(255, 255, 255, 1) !important;
                    }

                    &:hover,
                    &:active {
                        &:not(.-disabled) {
                            color: $white;
                        }
                    }


                    @include media('>=tablet') {
                        margin-bottom: 0;
                        font-size: rem(20px);
                    }

                    @include media('>=medium') {
                        font-size: rem(24px);
                    }
                }
            }
        }
    }
}

#{$a}--list {
    background: linear-gradient(45deg, transparent 90%, $yellow 90%);

    @include media('>=tablet') {
        background: linear-gradient(30deg, transparent 90%, $yellow 90%);
    }

    #{$a} {
        &__content {
            align-items: center;

            @include media('>=tablet') {
                align-items: flex-start;
            }

            &__left {
                margin-bottom: rem($spacer-m2);
            }
        }

        &__tiles {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;

            &__item {
                width: 50%;

                & * {
                    color: $color-text;
                    font-size: rem(18px);
                }

                p {
                    font-weight: 400;
                }

                &__num {
                    font-size: rem(28px);
                    font-weight: 600 !important;
                    margin-bottom: 0;
                    font-family: $secondary-fonts;
                }
            }
        }
    }

    .cat {
        color: $color-text;
    }

    p,
    h1 {
        color: $blue;
    }

    .btn {
        border-color: $blue !important;
    }
}
