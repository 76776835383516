/*
 * bk-video
 */
$c: '.bk-video-et-transcription';

#{$c} {
    &__title {
        margin-bottom: rem(20px);
    }
}
