/* ------------------------------------------------
- Ici, tous les styles communs à toutes les pages du site.
- Bien penser en composants html MODULABLES, de manière à pouvoir déplacer un élément, une classe, un bloc d'une page à une autre sans problème.
- Découper un maximum les scss en composants (/components)
- Si ça n'est pas possible, utiliser ce fichier
- Aucun #Id ici !!!
------------------------------------------------ */
.grid {
    &--xl {
        margin-left: rem(-64px);

        .grid__cell {
            padding-left: rem(64px);
        }
    }

    &__cell {
        margin-bottom: rem($spacer-m);
    }
}
