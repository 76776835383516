$a: '.bk-document';

#{$a} {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacer-l;

    @include media('>mobile') {
        margin-left: 0;
    }

    a {
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }
    }

    &__top {
        margin-bottom: 15px;
        position: relative;

        span {
            position: absolute;
            right: 10%;
            bottom: 10%;
            color: $white;

            &::before {
                font-size: $spacer-m2;
            }
        }
    }

    &__bottom {
        background-color: $light-grey;
        text-align: center;
        padding: $spacer-xs $spacer-m2;

        @include media('>=tablet') {
            width: 100%;
        }
    }
}

$b: '.bk-documents';

#{$b} {
    .bk-slider__controls {
        @include media('>tablet') {
            display: none
        }
    }

    &-list {
        @extend %no-styled-list;

        &__elem {

            margin-bottom: $spacer-m !important;

            a {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                text-decoration: none;

                [class^='icon-'] {
                    margin-left: $spacer-xs;
                }
            }

            span {
                &::before {
                    font-size: 28px;
                }
            }
        }
    }
}
