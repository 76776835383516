////
/// @group bk-accordion
////

$c: '.bk-accordion';

#{$c} {
    &__title {
        font-size: rem(28px);
    }

    &__toggle {
        &-container {
            display: flex;
            justify-content: flex-end;
            margin-bottom: rem(15px);

            #{$c}__toggle-all {
                padding: 0px;

                background: none;
                border: 0;

                transition: .8s;

                &:focus {
                    color: $dark-grey;

                    &:after {
                        opacity: 0;
                    }
                }

                @media (hover: none) {
                    &:after {
                        display: none;
                    }
                }

                &:hover {
                    &:after {
                        opacity: 1;
                    }
                }

                .is-open-text {
                    display: none;
                }

                &[aria-pressed='true'] {
                    color: $color-primary;

                    .default-text {
                        display: none;
                    }

                    .is-open-text {
                        display: inline;
                    }


                    &:after {
                        opacity: 0;
                    }

                    &:hover {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__list {
        @extend %no-styled-list;

        &-item {
            // border-bottom: 1px solid $light-grey;

            h2 {
                margin-bottom: 0;
            }

            &-label {
                border: 0;
                border-bottom: 1px solid $light-grey;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: rem(18px) 0;
                text-align: left;
                background: none;
                color: $color-text;
                font-family: $secondary-fonts;
                // font-size: rem($spacer-m2);

                .icon-accordion {
                    color: $color-primary;
                    font-size: rem(30px);
                    transition: .8s;
                    margin-left: rem(25px);

                    &:before {
                        content: $icon-arrow-b;
                    }

                    &.cross {
                        color: $color-primary;
                        transform: rotate(45deg);
                        transition: .8s;
                        margin-left: rem(25px);
                        font-size: rem(22px);

                        &:before {
                            content: $icon-close;
                            border: 1px solid $light-grey;
                            border-radius: rem(50px);
                            padding: rem($spacer-xxs);
                        }
                    }
                }

                &:hover,
                &:focus {
                    &:not([disabled]) {
                        color: $color-primary;
                    }

                    .cross {
                        &:before {
                            border-color: $color-primary;
                        }
                    }
                }
            }

            &-content {
                display: none;
                padding: rem($spacer-s) 0;
                overflow: hidden;
                border-bottom: 1px solid $light-grey;

                & > div[class^='bk-'] {
                    margin-bottom: rem($spacer-l);
                }

                .paragraph-full-width {
                    width: 100%;
                    background-color: transparent;
                }

                // ol {
                //     li {
                //         list-style: numeric;
                //     }
                // }

                ul {
                    text-align: left;

                    li {
                        @extend %body-medium;
                        margin-bottom: rem($spacer-xxs);
                        padding-left: rem($spacer-s);
                        position: relative;

                        &:before {
                            display: block !important;
                            content: '';
                            position: absolute;
                            left: 0;
                            top: rem($spacer-xs);
                            transform: translate(0, -50%);
                            width: rem(6px);
                            height: rem(6px);
                            color: $color-primary;
                            background-color: currentColor;
                            border-radius: 50%;
                        }
                    }
                }


                *:last-child {
                    &:not(.btn) {
                        margin-bottom: 0;
                    }
                }

                figure {
                    float: none;
                    display: flex;
                    flex-direction: column;

                    &.align-center {
                        align-items: center;
                    }

                    &.align-right {
                        align-items: end;
                    }
                }

                &--list {
                    ul {
                        li {
                            margin-bottom: rem($spacer-m);

                            a {
                                @extend %body-medium;
                                text-decoration: underline;
                                font-family: $secondary-fonts;
                            }

                            .subtitle {
                                @extend %body-xlarge;
                                font-family: $default-fonts;
                            }
                        }
                    }
                }
            }

            &.is-open {
                .bk-accordion__list-item-label {
                    border-bottom: 0;
                }

                [class*='icon-accordion'],
                [class*='icon-arrow'] {
                    transform: rotate(180deg);
                }

                [class*='cross'] {
                    transform: rotate(90deg);
                }
            }

            @include media('>=tablet') {
                &-label {
                    padding: rem($spacer-m) 0;
                    min-height: rem(95px);
                }

                &-content {
                    padding: rem($spacer-xxl) 0 rem($spacer-s) 0;

                    &:first-child {
                        margin-top: 0 !important;
                    }

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }

    &.bordered {
        #{$c} {
            &__list {
                &-item {
                    border: 1px solid $blue20;
                    padding: 0 rem($spacer-m2);
                    border-radius: 8px;
                    margin-bottom: rem($spacer-m);

                    &-label {
                        font-size: rem(22px);
                        color: $blue;
                        border-bottom: 0;
                    }

                    &-content {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
