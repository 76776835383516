.player__btn {
    position: relative;

    &__link {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-decoration: none;
        z-index: 9;

        &:after {
            @include icomoon;
            content: $icon-player-full;
            background: radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(255,86,87,1) 100%);
            color: $additional-coral;
            border-radius: rem(50px);
            font-size: rem(75px);
        }

        &--small {
            &:after {
                font-size: rem(50px);
            }
        }
    }
}

.info__btn {
    text-decoration: none;
    color: $color-text;
    position: relative;
    margin-left: rem($spacer-xs);
    width: rem(20px);
    height: rem(20px);

    span {
        font-size: rem(13px);

        @include media('>=tablet') {
            font-size: rem(20px);
        }
    }
}
