/*
 * Form elements
 */

$input-radius: $global-radius;
$input-border-color: $blue20; //Le champ doit avoir un contraste min de 3:1 avec le fond


@import '../components/inputrange';
@import '../components/inputfile';

.input-range-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    [type='range'] {
        width: 100%;
    }

    > span {
        min-width: rem(100px);
        color: $black;
    }
}

.form-item,
.line {
    @include bk-type-txt;
}

.form-item {
    &--short {
        width: 33%;
    }

    &--bl {
        .input-line--l {
            padding: .9rem 1.6rem;
            border-left: 1px solid $light-grey;
        }
    }

    &--bl--mobile {
        @include media('<tablet') {
            .input-line--l {
                padding: .9rem 1.6rem;
                border-left: 1px solid $light-grey;
            }
        }
    }
}

label,
.label-like {
    display: block;
    margin-bottom: rem($spacer-m);
    color: $blue;
    font-weight: 600;
    font-family: $secondary-fonts;
}

input,
select {
    vertical-align: middle;
}

[type='date'],
[type='datetime-local'],
[type='time'],
[type='month'],
[type='week'] {
    &::-webkit-inner-spin-button {
        display: none;
    }
}

[type='text'],
[type='email'],
[type='date'],
[type='datetime-local'],
[type='time'],
[type='month'],
[type='week'],
[type='password'],
[type='tel'],
[type='url'],
[type='number'],
[type='search'],
textarea {
    appearance: none;
}

[type='text'],
[type='email'],
[type='date'],
[type='datetime-local'],
[type='time'],
[type='month'],
[type='week'],
[type='password'],
[type='tel'],
[type='url'],
[type='number'],
[type='search'],
textarea,
select {
    font-family: $default-fonts;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    padding: rem(20px) rem($spacer-m);
    border: 1px solid $input-border-color;
    border-radius: $input-radius;
    // border-radius: rem(px);
    background-color: $white;
    font-size: rem(14px);
    line-height: 1.5;

    &[disabled] {
        border-color: $white !important;
        background-color: $white !important;
    }

    &:focus {

        //[data-whatinput='keyboard'] & {
        box-shadow: none !important;
        border-color: $dark-grey;
        //}

    }

    &.input-line {
        color: inherit;
        border-radius: 0;
        border-width: 0 0 1px;
        background: transparent;

        &--l {
            color: inherit;
            border-radius: 0;
            border-width: 0 0 0 1px;
            background: transparent;
        }
    }

    &.no-border {
        color: inherit;
        border-radius: 0;
        border-width: 0;
        background: transparent;
    }
}

.ui-datepicker-title {
    select {
        padding: 0;
        border: 0;
        min-height: rem(20px);
    }
}

.styled-select {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 0;
    position: relative;


    &::before {
        @include icomoon;
        content: $icon-arrow-b;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: rem($spacer-xxs);
        height: 100%;
        color: $black;
        font-size: rem(30px);
        pointer-events: none;

        z-index: 1;

        .ie9 & {
            display: none;
        }
    }

    select {
        padding-right: rem(30px);
        position: relative;
        width: 100%;
        max-width: none;
        appearance: none;
        border: 1px solid $grey;
        background-color: $grey10;
        font-family: $secondary-fonts;
        font-size: rem(10px);
        font-weight: 600;
        border-radius: 4px;

        &::-ms-expand {
            display: none;
        }

        .ie9 & {
            padding-right: rem(10px);
        }
    }

    &--secondary {
        &:before {
            color: $color-secondary;
        }
    }

    &.--blue {
        select {
            background: $blue05;
            background-color: $blue05;
            color: $blue;
        }
    }
}

textarea {
    display: block;
    min-height: rem(250px);
}

/* input[type='submit'],
input[type='reset'],
button {
    @extend %btn;
} */

button {
    cursor: pointer;
}

.input-with-button {
    display: flex;
    position: relative;

    [type='text'],
    [type='email'] {
        width: 100%;
    }

    [type='submit'],
    [type='button'],
    .btn {
        display: flex;
        align-items: center;
        padding: 0 rem(10px);
        text-align: center;
    }
}

.radio-check {
    display: block;
    margin-bottom: rem(10px);

    &--inline {
        display: inline-block;
        vertical-align: top;
        margin-right: rem(10px);

        .bk-accordion--quiz & {
            margin: 0;
        }
    }

    span {
        flex-direction: row;
        align-items: baseline;
        display: flex;

        label {
            font-weight: normal;
            text-transform: none;
            color: $color-text;
            font-family: $default-fonts;
        }
    }
}


.inline-inputs {
    label {
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        padding-left: 5px;
        padding-right: 0;
        text-transform: none;
    }
}

.simple-inline-input {
    position: relative;
    padding-left: 15px;

    input {
        position: absolute;
        left: 0;
        top: 3px;
    }

    label {
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        padding-left: 5px;
        padding-right: 0;
        text-transform: none;
    }
}

.legal {
    font-size: rem(11px);
    line-height: normal;
}

// Radio custom
//styled checkboxes and radio


[type='checkbox'],
[type='radio'] {
    vertical-align: baseline;
    width: rem(16px);
    height: rem(16px);
    margin-right: rem(15px);
    accent-color: $color-primary;

    + label {
        display: inline-block;
    }
}

.custom-fields {
    [type='checkbox'],
    [type='radio'] {
        position: absolute;
        opacity: 0;

        + label {
            position: relative;
            padding-left: rem(30px);
            margin: 0 rem(20px) 0 0;
            cursor: pointer;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;

            .bk-professions-list & {
                padding-left: rem($spacer-m);
                margin-right: rem($spacer-xxs);
            }

            &::before {
                content: '';
                display: block;
                width: rem($spacer-m);
                height: rem($spacer-m);
                border: 1px solid $color-primary;
                background-color: $white;
                position: absolute;
                border-radius: 6px;
                top: -2px;
                left: 0;
                transition: ease .2s;

                .bk-professions-list & {
                    top: 0;
                }
            }

            &::after {
                content: '';
                display: block;
                width: rem(12px);
                height: rem(12px);
                font-size: rem(20px);
                position: absolute;
                left: rem(6px);
                top: rem(4px);
                opacity: 0;
                transition: .2s;
                color: $color-primary;
                background-color: currentColor;

                .bk-professions-list & {
                    top: 2px;
                }
            }

            a {
                color: $color-primary;
                display: inline;
            }
        }

        &:checked {
            +label {
                &::after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &:not(:checked):not(:disabled) {
            &:hover {
                +label {
                    &::after {
                        opacity: .3;
                        transform: scale(1);


                        .bk-accordion--quiz & {
                            top: 50%;
                            transform: translate(0, -50%);
                        }
                    }
                }
            }
        }

        &:focus {
            +label {
                &::before {
                    [data-whatinput='keyboard'] & {
                        @include outline;
                    }
                }
            }

        }


        &.error {
            +label {
                color: $color-error;
            }
        }

        &.valid {
            +label {
                color: $color-success;
            }
        }


        &:disabled {
            +label {
                color: $dark-grey;
                cursor: default;

                &::before,
                &::after {
                    border-color: $input-border-color !important;
                }

                &:before {
                    background-color: $lightest-grey !important;
                }

                &:after {
                    color: $dark-grey;
                }

            }

            &[type='checkbox'] {
                +label {
                    &::after {
                        color: $light-grey !important;
                    }
                }
            }

            &[type='radio'] {
                +label {
                    &::after {
                        background-color: $light-grey !important;
                    }
                }
            }
        }
    }

    [type='checkbox'] {
        + label {
            &::after {
                @include icomoon;
                content: $icon-checked;
                background-color: transparent;
                font-size: rem(12px);
                left: 2px;
                top: 8px;
            }
        }

        &:checked {
            +label {
                &:before {
                    background-color: $color-primary;
                    border-color: $color-primary;
                }

                &:after {
                    color: $white;
                }
            }
        }
    }

    [type='radio'] {
        +label {
            &::before {
                border-radius: 50%;
            }

            &::after {
                border-radius: 50%;
            }
        }
    }

    .-big-with-icon {
        [type='checkbox'],
        [type='radio'] {
            +label {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                font-size: rem(14px);
                font-weight: bold;
                width: 100%;
                min-height: rem(220px);
                padding: rem(20px);
                background: $color-primary;
                border: 1px solid $color-primary;
                color: $white;
                transition: .3s ease;
                text-align: center;

                .label-number {
                    display: none;
                }

                @include media('<desktop') {
                    min-height: rem(180px);
                }

                @include media('<medium') {
                    flex-flow: row nowrap;
                    min-height: auto;
                    padding: rem(10px);
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: auto;
                    height: auto;
                    border: 1px solid $color-primary;
                    border-radius: 0;
                    transition: .2s ease;
                    background: transparent;
                }

                &::after {
                    content: none;
                }

                &:hover,
                &:focus {
                    &::before {
                        left: 3px;
                        top: 3px;
                        right: 3px;
                        bottom: 3px;
                        border: 1px solid $white;
                    }
                }

                [class*='icon-'] {
                    display: block;
                    text-align: center;
                    font-size: rem(60px);

                    @include media('<medium') {
                        font-size: rem(40px);
                        margin-right: rem(20px);
                    }
                }
            }

            &:checked {
                +label {
                    position: relative;
                    background: $white;
                    color: $color-primary;

                    .label-number {
                        display: block;
                        padding: rem(3px);
                        position: absolute;
                        left: 50%;
                        top: 0;
                        transform: translate(-50%, -50%);
                        background-color: $color-primary;
                        color: $white;
                    }

                    &::before {
                        border: 1px solid $white;
                    }

                    &:hover,
                    &:focus {
                        &::before {
                            border: 1px solid $color-primary;
                        }
                    }
                }
            }

            &:focus {
                +label {
                    &::before {
                        border: 1px solid $color-primary;
                    }
                }
            }
        }
    }
}


//Switch
.switch-checkbox {
    position: absolute;
    opacity: 0;

    + label {
        position: relative;
        padding-left: rem(55px);
        margin: 0 rem(20px) 0 0;
        cursor: pointer;
        font-size: 16px;
        font-weight: normal;
        line-height: 1;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            transition: ease .2s;
        }

        &::before {
            width: rem($spacer-l);
            height: rem($spacer-m);
            color: $light-grey;
            border: 1px solid currentColor;
            background-color: currentColor;
            position: absolute;
            border-radius: rem($spacer-xs);
            top: -2px;
            left: 0;
        }

        &::after {
            width: rem(15px);
            height: rem(15px);
            left: rem(6px);
            top: rem(2px);
            border-radius: 50%;
            color: $white;
            background-color: currentColor;
        }

        a {
            color: $color-primary;
            display: inline;
        }
    }

    &:checked {
        +label {
            &::before {
                color: $color-primary;
            }

            &::after {
                left: 27px;
            }
        }
    }

    &:focus {
        +label {
            &::before {
                [data-whatinput='keyboard'] & {
                    @include outline
                }
            }
        }

    }


    &.error {
        +label {
            color: $color-error;
        }
    }

    &.valid {
        +label {
            color: $color-success;
        }
    }


    &:disabled {
        +label {
            color: $dark-grey;
            cursor: default;

            &::before,
            &::after {
                border-color: $input-border-color !important;
            }

            &:before {
                background-color: $lightest-grey !important;
            }

            &:after {
                color: $dark-grey;
            }

        }

        &[type='checkbox'] {
            +label {
                &::after {
                    color: $light-grey !important;
                }
            }
        }
    }
}


.js-added-line {
    .js-remove-line {
        display: none;
    }

    &:last-child {
        .js-remove-line {
            display: inline-block;
        }
    }
}


.form-field-inline {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: rem(0 0 30px);

    label,
    .label-like {
        margin: 0;
    }

    input {
        width: auto;
        margin: rem(0 15px);
    }
}

.checkbox-list {
    display: inline-block;
    position: relative;

    &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: rem(50px);
        //min-height: rem(50px);
        min-width: rem(190px);
        padding: 0 rem(15px);

        border: 2px solid $input-border-color;
        border-left: 0;
        border-right: 0;
        background: transparent;

        font-size: rem(20px);

        transition: .2s;

        @include media('<medium') {
            width: 100%;
        }

        &::after {
            @include icomoon;
            display: inline-block;
            content: $icon-arrow-r;
            margin-left: rem(20px);

            color: $color-primary;

            transform: rotate(90deg);
            transition: .2s;
        }

        &[aria-expanded='true'] {
            border-bottom-color: transparent;

            &::after {
                transform: rotate(-90deg);
            }
        }
    }

    &__list {
        margin: 0;
        padding: rem(15px);
        border: 0;
        background-color: $white;

        @include media('<tablet') {
            width: 100%;
            height: 100%;
            padding: rem(20px) rem(50px) rem(20px) rem(20px);
            overflow-y: auto;

        }

        &::before {
            @include media('<tablet') {
                content: attr(aria-label);
                display: block;
                margin-bottom: rem(20px);

                font-size: rem(25px);
            }
        }

        &-wrapper {
            position: absolute;
            top: 110%;
            margin: 0;
            display: none;
            min-width: 100%;
            z-index: 10;

            overflow: auto;
            max-height: rem(300px);

            background-color: $white;
            border: 0;
            border-bottom: 2px solid $input-border-color;

            box-shadow: -2px -2px 2px rgba(0, 0, 0, .05);

            @include media('<tablet') {
                position: fixed;
                top: rem(10px);
                left: rem(10px);
                height: calc(100vh - 20px);
                width: calc(100vw - 20px);
                min-width: 0;
                padding: 0;

                &::before {
                    content: '';
                    position: fixed;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100vw;
                    height: 100vh;

                    background-color: $dark-grey;

                    opacity: .5;
                    z-index: -1;
                }
            }
        }

        &-close {
            position: absolute;
            top: rem(10px);
            right: rem(10px);

            background: transparent;
            border: 0;

            color: $color-primary;
            font-size: rem(20px);

            transition: .2s;

            &:hover {
                color: $color-secondary;
            }
        }

        input {
            &[type='checkbox'],
            &[type='radio'] {
                +label {
                    margin-right: 0;

                    @include media('<tablet') {
                        font-size: rem(20px);
                    }
                }
            }
        }
    }
}

.-with-icon {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: rem(800px);
    margin-bottom: rem(20px);

    input {
        padding-right: rem($spacer-l);
        // border-radius: rem(50px);

        &:active,
        &:focus {
            + button {
                color: $black;
            }
        }
    }

    > [class*='icon-'] {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: rem(10px);
        border: 0;
        background: transparent;
        font-size: rem(18px);
        transition: .2s;
    }

    > button {
        color: $color-primary;

        &:hover {
            color: rgba($color-primary, .8);
        }
    }
}

.-with-unit {
    position: relative;
    display: inline-block;
    width: 100%;

    > input {
        padding-right: rem(50px);
    }

    > span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 rem($spacer-xs);

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: $spacer-xxs;
            bottom: $spacer-xxs;
            border-left: 1px solid $light-grey;
        }
    }
}

.-with-button {
    display: flex;

    &>span,
    &>input {
        flex: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &>.btn {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: $input-radius;
        border-bottom-right-radius: $input-radius;
    }
}

.-with-round-button {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: rem(800px);
    margin: 0 auto;

    input {
        padding-right: rem(48px);
        height: rem(50px);
    }

    &>button {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: rem(10px);
        background-color: $color-secondary;
        color: $white;
        height: rem(42px);
        width: rem(42px);
        padding: 0;
        font-size: rem(22px);
        border-radius: 50%;
        border: 0;
    }
}

//select2 overide
.select2-container--default {
    .select2-search--inline {
        .select2-search__field {
            min-height: 0;
            padding: 0;
            line-height: 1;
        }
    }

    .select2-selection--multiple {
        .select2-selection__choice {
            margin-bottom: 0;

            &:before {
                content: none;
            }
        }
    }

    .select2-selection--single {
        border-color: $input-border-color;

        .select2-selection__rendered {
            padding: rem(9px) rem($spacer-s);
            line-height: 1.5;
        }

        .select2-selection__arrow {
            height: 100%;
            width: rem(40px);
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                @include icomoon;
                content: $icon-arrow-b;
                font-size: rem(18px);
            }

            b {
                display: none;
            }
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
            border-color: $input-border-color;

            &:focus {
                border-color: $dark-grey;
            }
        }
    }
}

.select2-dropdown {
    border-color: $input-border-color;
}

.select2-selection {
    &:focus {
        box-shadow: none !important;
        border-color: $dark-grey;
    }
}


.select2-results__option {
    margin-bottom: 0;

    &:before {
        display: none;
    }
}

.select2-container {
    .select2-selection--single {
        height: auto;
    }
}

//Select2 multi check
.select-2-dropdown-checkboxes {
    .select2-results__option {
        &:not(.select2-results__message) {

            position: relative;
            padding-left: rem(30px);

            &:before,
            &:after {
                content: '';
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: rem(18px);
                height: rem(18px);
                top: 50%;
                transform: translateY(-50%);
                left: rem($spacer-xxs);
            }

            &:before {
                background-color: $white;
                border: 1px solid $input-border-color;
                border-radius: 6px;
            }

            &:after {
                @include icomoon;
                content: $icon-checked;
                color: $white;
                font-size: 12px;
                transform: scale(0) translateY(-50%);
                transition: transform .2s;
            }

            &.select2-results__option--selected {
                &:before {
                    background-color: $color-primary;
                    border-color: $color-primary;
                }

                &:after {
                    transform: scale(1) translateY(-50%);
                }
            }
        }
    }
}


//drupal webform-multiple-table
.webform-multiple-table {
    @include bk-type;

    table {
        width: 100%;

        .form-item {
            margin-bottom: 0;
        }

        .js-form-type-date {
            position: relative;

            &:after {
                @include icomoon;
                // content: $icon-calendar;
                position: absolute;
                top: 50%;
                right: rem($spacer-xxs);
                transform: translateY(-50%);
                pointer-events: none;
            }

            input {
                padding-right: rem(50px);
                box-sizing: border-box;
            }

            img {
                display: none;
            }
        }
    }

    .tabledrag-toggle-weight-wrapper {
        margin-bottom: rem($spacer-s);

        button {
            @extend %btn;
        }
    }
}

.webform-multiple-add {
    @include bk-type;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    input {
        box-sizing: border-box;
    }

    [type='submit'] {
        @extend %btn;
        margin-right: rem($spacer-s);
    }

    .form-item {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.form-actions {
    [type='submit'] {
        @extend %btn;
    }
}

form {
    .container-inline {
        > * {
            display: inline-block;
        }
    }

}

.webform-element--title-inline {
    > label {
        display: block;
    }

    > div {
        .container-inline {
            display: flex;
        }
    }
}

// Tooltip
.webform-element-help {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
}
