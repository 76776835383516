.bk-tarifs {
    // margin-top: rem(90px);
    .grid__cell {
        margin-bottom: 0 !important;

        @include media('>=tablet') {
            margin-bottom: rem(24px) !important;
        }
    }

    @include media('>=tablet') {
        margin-top: auto;
        display: flex;
        align-items: stretch;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .bk-card-tarif {
        height: 100%;
        margin-top: rem($spacer-s);

        @include media('>=tablet') {
            margin: 0 rem($spacer-xs);
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .bk-card-offre {
        height: 100%;
        margin: rem($spacer-s) 0 ;

        @include media('>=tablet') {
            margin: 0 rem($spacer-xs);
        }
    }
}
