////
/// @group buttons
////

button {
    cursor: pointer;
    font-family: $secondary-fonts;
    font-weight: 600;
}

.btn,
%btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: rem(17px) rem(40px) rem(16px) rem(40px);
    background-color: $color-primary;
    border: 0;
    border-radius: rem($btn-radius);
    color: $white;
    font-size: rem(14px);
    line-height: 1.2;
    text-decoration: none;
    text-align: center;
    transition: .2s;
    appearance: none;
    font-family: $secondary-fonts;
    font-weight: 600;
    min-width: none;

    @include media('>=tablet') {
        min-width: rem(272px);
    }

    @include media('>=medium') {
        min-width: rem(307px);
        font-size: rem(16px);
    }

    &:hover,
    &:focus {
        &:not([disabled]) {
            color: $white;
            background-color: #3351E0;

            span {
                &[class*='icon-arrow-r'] {
                    color: $white;
                    transform: translateX(8px);
                    transition-duration: .5s;
                }
            }
        }
    }

    &--primary {
        color: $white;
        background-color: $color-primary;
    }

    &--secondary {
        color: $color-primary;
        background-color: $white;
        border: 1px solid #3351E0;

        &-header {
            @include media('>=tablet') {
                border: none;
                background-color: transparent;
                color: $color-primary;
            }
        }

        &:hover,
        &:focus {
            &:not([disabled]) {
                border-color: #3351E0;
            }
        }
    }

    &--ghost {
        border: none;
        background-color: transparent;
        color: $color-primary;
    }

    &--useful {
        border: 1px solid $color-primary;
        background-color: transparent;
        color: $color-primary;
        border-radius: 12px;
        padding: rem($spacer-s) rem($spacer-m);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: rem(18px);
        margin: rem($spacer-xxs) 0;

        span {
            &[class*='icon-arrow-r'] {
                font-size: rem(32px);
                color: $color-primary;
                transition-duration: .5s;
            }
        }

        &.colored {
            color: $color-primary;

            span {
                &[class*='icon-arrow-r'] {
                    color: $color-primary;
                }
            }

            .bk-link__title {
                font-size: rem(20px);
            }
        }

        &:hover,
        &:focus {
            &:not([disabled]) {
                background-color: transparent;
                color: $color-text;

                span {
                    &[class*='icon-arrow-r'] {
                        color: $color-primary;
                    }
                }
            }
        }

        @include media('>=tablet') {
            margin: rem($spacer-s) 0;
            padding: rem(27.5px) rem($spacer-m2);
        }
    }

    &--disabled,
    &[disabled] {
        cursor: not-allowed;
        opacity: .8;
        background-color: $lightest-grey;
        color: $grey;
        border: none;
    }

    &--small {
        font-size: rem(14px);
        padding: rem(8px) rem(35px);
    }

    &--ir,
    &--il {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--ir {
        [class^='icon-'] {
            margin-left: rem(8px);
        }
    }

    &--il {
        [class^='icon-'] {
            margin-right: rem(8px);
        }
    }
}

.btn-wrapper {
    .btn {
        width: 100%;

        @include media('>=tablet') {
            width: auto;
        }
    }

    &--l-r {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .btn {
            margin-bottom: rem(10px);
        }

        @include media('<tablet') {
            justify-content: center;
        }

        &.-many-btns {
            @include media('<medium') {
                justify-content: center;
            }

            .right-btns {
                @include media('<medium') {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: column nowrap;
                    align-content: normal;
                    width: 100%;
                }

                .btn {
                    @include media('<desktop') {
                        padding: rem(15px) rem(40px);
                    }

                    + .btn {
                        margin-left: rem(10px);
                    }
                }
            }
        }
    }

    &--r {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .btn {
            margin-bottom: rem(10px);
        }
    }

    &--center {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .btn {
            margin: rem(10px);
        }
    }
}
