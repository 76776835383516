$c: '.bk-thirds';

#{$c} {
    background-color: $blue03;
    padding: rem($spacer-m2) 0;

    h2 {
        margin-bottom: rem($spacer-m2);
    }

    @include media('>=tablet') {
        padding: rem($spacer-xxl);
    }

    &__split {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        @include media('>=tablet') {
            flex-direction: row;
        }

        .bk-card-third {
            flex-basis: 33%;

            &:not(:first-of-type) {
                margin-left: rem($spacer-m2);

                @include media('<tablet') {
                    margin-left: 0;
                }
            }

            &__link {
                margin-top: rem($spacer-xxs)
            }

            &.solo {
                flex-basis: 100%;
            }

            &.large {
                flex-basis: 66%;
            }

            &.medium {
                flex-basis: 50%;
            }
        }
    }
}
