/*
 * Tables elements (from bootstap) : http://bootstrapdocs.com/v3.0.3/docs/css/#tables
 */

.bk-table {
    @include bk-type;
    background-color: $lightest-grey;
    padding: rem($spacer-xs) rem($spacer-l);
    font-size: rem(14px);

    .bk-professions-list & {
        padding: 0 rem($spacer-m2);
        margin-top: rem($spacer-m2);
    }

    table {
        margin-bottom: 0;
    }
}

table {
    @include bk-type;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;

    // td,
    // th {
    //     &[class*='col-'] {
    //         position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
    //         float: none;
    //         display: table-cell;
    //     }
    // }

    // [class*='col-'] {
    //     position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
    //     float: none;
    //     display: table-column;
    // }
}

caption {
    padding-top: $gutter-w;
    padding-bottom: $gutter-w;
    text-align: left;
    font-size: $base-font-size;
}

th {
    text-align: left;
}

// Baseline styles

.table {
    width: 100%;
    max-width: 100%;

    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: calc($gutter-w / 2);
                vertical-align: top;
                border-top: 1px solid $color-border;

                .bk-professions-list & {
                    vertical-align: middle;
                    padding: rem($spacer-s) rem($spacer-m);
                    padding-left: 0;
                }
            }

            &:first-child {
                th,
                td {
                    border-top: 0;

                    .bk-professions-list & {
                        border-top: 0;
                    }
                }
            }
        }
    }

    // Bottom align for column headings
    > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 1px solid $color-border;
        // border-top: 0;
        color: $color-primary;

        .bk-professions-list & {
            vertical-align: bottom;
        }
    }

    // Remove top border from thead by default
    > caption + thead,
    > colgroup + thead,
    > thead {
        &:first-child {
            > tr {
                & :first-child {
                    > th,
                    > td {
                        border-top: 0;
                    }
                }
            }
        }
    }

    // Account for multiple tbody instances
    > tbody + tbody {
        border-top: 2px solid $dark-grey;
    }

    // Nesting
    .table {
        background-color: $white;
    }
}

// Condensed table w/ half padding

.table-condensed {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: calc($gutter-w / 4);
            }
        }
    }
}

// Bordered version

// Add borders all around the table and between all the columns.

.table-bordered {
    border: 1px solid $dark-grey;

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border: 1px solid $dark-grey;
            }
        }
    }

    > thead > tr {
        > th,
        > td {
            border-bottom-width: 2px;
        }
    }
}


// Zebra-striping

// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
    > tbody {
        > tr {
            &:nth-of-type(odd) {
                background-color: #ddd;
            }
        }
    }
}


// Hover effect

// Placed here since it has to come after the potential zebra striping

.table-hover {
    > tbody {
        > tr {
            &:hover,
            &:focus {
                background-color: #ddd;
            }
        }
    }
}


// Responsive tables
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
    @extend %bk-type;
    overflow-x: auto;
    min-height: .01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

    @include media('<tablet') {
        width: 100%;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid $dark-grey;

        // Tighten up spacing
        > .table {
            margin-bottom: 0;

            // Ensure the content doesn't wrap
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th,
                    > td {
                        white-space: nowrap;
                    }
                }
            }
        }

        // Special overrides for the bordered tables
        > .table-bordered {
            border: 0;

            // Nuke the appropriate borders so that the parent can handle them
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th,
                    > td {
                        &:first-child {
                            border-left: 0;
                        }

                        &:last-child {
                            border-right: 0;
                        }
                    }
                }
            }

            // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
            // chances are there will be only one `tr` in a `thead` and that would
            // remove the border altogether.
            > tbody,
            > tfoot {
                > tr {
                    &:last-child {
                        > th,
                        > td {
                            border-bottom: 0;
                        }
                    }
                }
            }

        }
    }
}


.guarantees-table {
    .table {
        background: transparent;

        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    position: relative;
                    vertical-align: middle !important;
                    border: 10px solid $white;
                    text-align: center;

                    &:first-child {
                        text-align: left;
                    }
                }
            }
        }
    }
}

.offers-table {
    margin-bottom: rem(40px);

    .table {
        background: transparent;

        @include media('<desktop') {
            border: 1px solid rgba($black, .2);
            border-collapse: inherit;
        }

        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    position: relative;
                    vertical-align: middle !important;
                    border: 10px solid $white;
                    text-align: center;

                    @include media('<desktop') {
                        border: 0;
                        padding: rem(10px);
                    }

                    &:first-child {
                        text-align: left;

                        @include media('<desktop') {
                            border-bottom: 1px solid rgba($black, .2);
                        }
                    }

                    > .mob-visible {
                        @include last-child-no-margin;
                        padding-top: rem(15px);

                        p {
                            margin-bottom: rem(5px);
                        }
                    }
                }
            }
        }

        [type='checkbox']+label,
        [type='radio']+label {
            padding: 0;

            @include media('<desktop') {
                padding-left: rem(30px);
                margin-right: rem(20px);
            }
        }
    }
}

.options-table {
    margin-bottom: rem(40px);

    .table {
        background: transparent;

        @include media('<desktop') {
            border: 1px solid rgba($black, .2);
            border-collapse: inherit;
        }

        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    position: relative;
                    vertical-align: middle !important;
                    border: 10px solid $white;
                    text-align: center;

                    @include media('<desktop') {
                        border: 0;
                        padding: rem(10px);
                    }

                    &:first-child {
                        text-align: left;

                        @include media('<desktop') {
                            border-bottom: 1px solid rgba($black, .2);
                        }
                    }

                    &:nth-child(2),
                    &:last-child {
                        white-space: nowrap;
                    }

                    > .mob-visible {
                        @include last-child-no-margin;
                        padding-top: rem(15px);

                        p {
                            margin-bottom: rem(5px);
                        }
                    }
                }

                &:not(:last-child) {
                    td {
                        @include media('<desktop') {
                            display: block;
                        }

                        &:first-child {
                            border-right: 0;
                        }

                        &:nth-child(2) {
                            border-left: 0;
                        }
                    }
                }

                &:last-child {
                    @include media('<desktop') {
                        display: flex;
                        width: calc(100% + 1px);

                        td {
                            padding: rem(20px);

                            &:first-child {
                                width: 100%;
                            }

                            &:last-child {
                                flex-shrink: 0;
                            }
                        }
                    }
                }
            }
        }

        .uppercase {
            font-size: rem(18px);
        }

        .text-big {
            font-size: rem(30px);
            line-height: 1;

            @include media('<desktop') {
                font-size: rem(20px);
            }
        }

        .lk {
            color: $black;
            margin-top: rem(20px);
        }

        [type='checkbox']+label,
        [type='radio']+label {
            margin-bottom: 0;
            margin-top: rem(-14px);
            padding: 0;
            color: $white;

            @include media('<desktop') {
                padding-left: rem(30px);
                margin-right: rem(20px);
                margin-top: 0;
            }
        }
    }
}
