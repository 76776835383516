$a: '.bk-timeline';

#{$a} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: rem($spacer-l);
    position: relative;

    &:before {
        content: '';
        position: absolute;
        // height: 100%;
        width: auto;
        border-left: 2px solid $yellow;
        left: rem(28px);
        top: rem(64px);
        bottom: rem(64px);
        transform: translateX(-50%);
        z-index: -1;
    }

    @include media('>=tablet') {
        margin-bottom: 0;
        // padding-top: rem($spacer-xl);

        &:before {
            display: none;
        }
    }

    a {
        @extend %body-medium;
        text-decoration: underline;
    }

    .paragraph-full-width & {
        max-width: rem($sc-max-width);
        margin: rem($spacer-xxs) auto;
        padding: rem($spacer-l) 0;
    }

    &__note {
        margin-bottom: rem($spacer-xl);

        p {
            @extend %body-xlarge;

            a {
                color: $color-text;
                font-weight: 400;
                font-family: $default-fonts;
                font-size: rem(16px);
                text-decoration: underline;

                @include media('>=tablet') {
                    font-size: rem(20px);
                }

            }
        }

        .paragraph-full-width & {
            max-width: rem($sc-max-width);
            margin-left: auto;
            margin-right: auto;
        }

    }

    &.no-line {
        &::before {
            display: none;
        }

        #{$a}-elem {
            &-num {
                &::before {
                    display: none;
                }
            }
        }
    }

    &.pictures {
        align-items: center;

        &::before {
            display: none;
        }

        @include media('>=tablet') {
            align-items: flex-start;
        }

        #{$a} {
            &-elem {
                position: relative;
                flex-direction: column;
                margin-bottom: rem($spacer-xxl);

                @include media('>=tablet') {
                    margin-top: rem($spacer-xxl);
                    margin-bottom: rem($spacer-l);
                }

                &-img {
                    display: block;
                    position: relative;
                    // position: absolute;
                    // bottom: rem(142px);
                    // left: 50%;
                    // transform: translateX(-50%);
                    z-index: -1;
                    width: 100%;
                    text-align: center;

                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 90px;
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, .5) 86.2%, #FFFFFF 100%);
                    }
                }

                &-text {
                    text-align: center;
                    max-width: rem(260px);
                    margin: 0 auto;
                }

                &-num {
                    margin-top: rem(-26px);
                    margin-bottom: rem($spacer-s);

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    &.simplified {
        &::before {
            left: rem(10px);
        }

        #{$a} {
            &-elem {
                &-num {
                    p {
                        content-visibility: hidden;
                        width: rem(20px);
                        height: rem(20px);
                        background-color: $yellow;
                    }
                }
            }
        }
    }

    @include media('>=tablet') {
        flex-direction: row;
    }

    &-elem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        &:not(:last-of-type) {
            margin-bottom: rem($spacer-xl);
        }

        @include media('>=tablet') {
            display: block;
            text-align: center;
            margin-bottom: rem($spacer-m2) !important;
        }

        &-img {
            display: none;
        }

        &-text {
            @extend %body-medium;
            padding: 0 rem($spacer-m);

            p {
                margin-bottom: 0;
            }
        }

        &-num {
            position: relative;

            &:before {
                @include media('>=tablet') {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    width: 100%;
                    height: auto;
                    border-bottom: 2px solid $yellow;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            p {
                border: 2px solid $yellow;
                border-radius: 50px;
                margin: 0 auto;
                background-color: #FFFDF3;
                width: rem(56px);
                height: rem(56px);
                line-height: rem(56px);
                margin-bottom: 0;
                text-align: center;

                @include media('>=tablet') {
                    margin-bottom: rem($spacer-m2);
                    width: rem(78px);
                    height: rem(78px);
                    line-height: rem(73px);
                }
            }
        }

        &:first-of-type {
            #{$a}-elem {
                &-num {
                    &:before {
                        @include media('>=tablet') {
                            left: 100%;
                        }
                    }
                }
            }
        }

        &:last-of-type {
            #{$a}-elem {
                &-num {
                    &:before {
                        height: 0;

                        @include media('>=tablet') {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
