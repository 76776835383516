/*
 * bk-media
 */
$c: '.bk-media';

#{$c} {
    &__wrapper {
        img,
        .bk-video-wrapper {
            display: block;
            margin-bottom: rem($spacer-xs);
        }
    }

    &__infos {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        position: relative;
    }

    &__copyright {
        text-align: right;
        font-size: 12px;
    }
}
