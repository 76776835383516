$c: '.bk-tabs';

#{$c} {

    display: flex;
    flex-direction: column;

    &__head {
        display: flex;
        align-items: flex-end;
    }

    &__item {
        padding: $spacer-s;
        border-bottom: 0;
        cursor: pointer;

        &[tabindex='0'] {
            position: relative;
            border-color: $color-primary;
            border-top-width: 3px;
            color: $color-primary;
            cursor: default;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 100%;
                right: 0;
                height: 2px;
            }
        }
    }

    &__content {
        @include last-child-no-margin;
        padding: $spacer-m;
        border: 1px solid $color-border;
        border-top: 0;
    }
}
