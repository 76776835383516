$c: '.bk-columns';

#{$c} {
    h3,
    .like-h4 {
        margin-bottom: rem($spacer-xs);
        color: $color-text;
    }

    p {
        margin-top: 0;
        font-weight: 400;

        &.sub {
            font-weight: 500;
            font-size: rem(20px);
        }
    }

}
