$c: '.custom-tooltip';



.tooltip-hide {
    display: none;
}

#{$c} {
    padding: 0;
    background: none;
    border: 0;
    color: $color-primary;
    display: inline-block;
    margin: 0 rem(calc($spacer-xs / 2));
    position: relative;
    border-radius: 50%;
}

//Tippy theme
.tippy-box {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, .05);

    &[data-theme~='lightgrey'] {
        background-color: $lightest-grey;
        color: $color-text;

        &[data-placement^='top'] {
            > .tippy-arrow {
                &::before {
                    border-top-color: $lightest-grey;
                }
            }
        }

        &[data-placement^='bottom'] {
            > .tippy-arrow {
                &::before {
                    border-bottom-color: $lightest-grey;
                }
            }
        }

        &[data-placement^='left'] {
            > .tippy-arrow {
                &::before {
                    border-left-color: $lightest-grey;
                }
            }
        }

        &[data-placement^='right'] {
            > .tippy-arrow {
                &::before {
                    border-right-color: $lightest-grey;
                }
            }
        }
    }

    &[data-theme~='white'] {
        background-color: $white;
        color: $color-text;

        &[data-placement^='top'] {
            > .tippy-arrow {
                &::before {
                    border-top-color: $white;
                }
            }
        }

        &[data-placement^='bottom'] {
            > .tippy-arrow {
                &::before {
                    border-bottom-color: $white;
                }
            }
        }

        &[data-placement^='left'] {
            > .tippy-arrow {
                &::before {
                    border-left-color: $white;
                }
            }
        }

        &[data-placement^='right'] {
            > .tippy-arrow {
                &::before {
                    border-right-color: $white;
                }
            }
        }
    }

    &[data-theme~='primary'] {
        background-color: $color-primary;
        color: $white;

        &[data-placement^='top'] {
            > .tippy-arrow {
                &::before {
                    border-top-color: $color-primary;
                }
            }
        }

        &[data-placement^='bottom'] {
            > .tippy-arrow {
                &::before {
                    border-bottom-color: $color-primary;
                }
            }
        }

        &[data-placement^='left'] {
            > .tippy-arrow {
                &::before {
                    border-left-color: $color-primary;
                }
            }
        }

        &[data-placement^='right'] {
            > .tippy-arrow {
                &::before {
                    border-right-color: $color-primary;
                }
            }
        }
    }

    .tippy-content {
        padding: rem($spacer-m);
    }
}
