$c: '.bk-steps';

#{$c},
#{$c}-bar {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    @include media('>=tablet') {
        flex-flow: row nowrap;
        align-items: center;
    }

    &__tabs,
    &__scroll {
        margin-left: 0;

        a {
            font-size: rem(16px) !important;
            color: $color-primary !important;
        }

        .scroll__elem {
            padding-bottom: rem($spacer-l);
            min-height: rem(275px);

            * {
                direction: ltr;
            }
        }

        @include media('>=tablet') {
            margin-left: rem(50px);
        }

        @include media('>=medium') {
            margin-left: rem(100px);
        }
    }

    &__tabs,
    &__panels {
        flex-basis: 50%;
        width: 100%;

        img {
            border-radius: 12px;
            width: 100%;
        }

        &.straight-img {
            img {
                border-radius: 0;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        margin: rem($spacer-m2)0 rem($spacer-xs) 0;
        text-align: center;
        position: relative;

        p {
            margin-top: rem(6px);
        }

        @include media('>=tablet') {
            min-height: rem(150px);
            margin: rem($spacer-m) 0;
            padding: rem($spacer-s) rem($spacer-m);
            text-align: left;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: rem(6px);
                height: 100%;
                background-color: $light-grey;
                border-radius: rem(8px);
            }


            &:active,
            &[aria-selected='true'],
            &:focus {
                outline: none;

                &:before {
                    background-color: $blue;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }

        .like-h3,
        .like-h4 {
            margin-bottom: rem($spacer-xs);
            color: $color-text;

            @include media('>=tablet') {
                margin-bottom: rem($spacer-xxs);
            }
        }

        .content {
            @extend %body-medium;
            margin: 0;
        }
    }

    .bk-slider__dots {
        margin: 0 auto;
    }
}

#{$c}-bar {
    align-items: stretch;
    justify-content: space-between;

    h5 {
        color: $color-text;
    }

    &__img {
        flex: 0 0 auto;
        overflow-y: hidden;
        margin: 0 auto rem($spacer-m);

        @include media('>=tablet') {
            margin: 0;
        }

        .img-container {
            display: none;

            @include media('>tablet') {
                display: block;
                visibility: hidden;
                height: 0;
                opacity: 0;
                transition: opacity .5s, visibility .5s;
            }

            &.visible {
                display: block;
                visibility: hidden;
                height: 0;
                opacity: 0;

                @include media('>tablet') {
                    display: block;
                    opacity: 1;
                    visibility: visible !important;
                    height: 100%;
                    transition-delay: .5s;
                }
            }
        }

        img {
            border-radius: rem(12px);
            width: 100%;
            max-height: rem(687px);
        }
    }

    &__item {
        &::before {
            display: none;
        }
    }

    &__scroll {
        flex: 0 1 50%;
        background-color: transparent;
        width: 100%;
        height: rem(687px);
        overflow-x: hidden;
        overflow-y: auto;
        direction: rtl;
        margin-bottom: rem($spacer-xl);
        text-align: left;

        @include media('>=tablet') {
            @include scrollbar(6px);
            padding-left: rem($spacer-xl);
        }

        h3 {
            &:first-of-type {
                margin-top: 0;
            }
        }

        h4,
        h5 {
            margin-bottom: rem(6px);
            margin-top: 0;
            padding-top: rem($spacer-m2);
        }

        p {
            margin: rem(6px) 0;
        }

        .btn {
            color: $white !important;
            margin-top: rem($spacer-m);
        }

        .min-img-container {
            img {
                border-radius: rem(12px);
            }

            @include media('>=tablet') {
                display: none !important;
            }
        }

        &__elem {
            &:last-of-type {
                @include media('>=tablet') {
                    margin-bottom: rem(344px);
                }
            }
        }
    }

    .progress {
        display: block;
        width: rem(10px);
        height: rem(687px);
        border-radius: rem(10px);
        overflow: hidden;
        border: 1px solid red;
    }
}
