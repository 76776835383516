$c: '.bk-slider';

#{$c} {
    position: relative;

    .slick-slider {
        position: relative;

        // &:before,
        // &:after {
        //     content: '';
        //     position: absolute;
        //     display: block;
        //     top: 0;
        //     left: 0;
        //     width: rem(30px);
        //     height: 100%;
        //     background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        //     pointer-events: none;
        //     z-index: 2;
        // }

        // &:after {
        //     left: auto;
        //     right: rem(0);
        //     background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        // }
    }

    img {
        border-radius: 12px;
    }

    &.-s {
        padding: 0;

        @include media('>=tablet') {
            padding: 0 rem(60px);
        }

        @include media('>=medium') {
            padding: 0 rem(120px);
        }
    }

    &.videos {
        #{$c} {
            &__slide {
                span {
                    position: relative;

                    &:after {
                        @include icomoon;
                        content: $icon-player-full;
                        background-color: $white;
                        color: $additional-blue;
                        border-radius: rem(50px);
                        position: absolute;
                        font-size: rem(24px);
                        z-index: 999;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

    &-logo {
        // max-width: 1652px;
        width: 100%;
        margin: 0 auto;

        &__box {
            background-color: $blue03;
            border: 1px solid $blue20;
            border-radius: rem(8px);
            padding: rem($spacer-m);
            width: 100%;

            .bg--blue & {
                background-color: $white;
            }

            img {
                margin: 0 auto;
                filter: grayscale(100%);
                transition: .5s;
            }

            &:hover {
                img {
                    filter: none;
                    transition: .5s;
                }
            }
        }

        .slick-pause-text,
        .slick-play-text,
        .slick-autoplay-toggle-button {
            @extend %out;
        }
    }

    &--videos {
        #{$c} {
            &__prev,
            &__next {
                @include media('>=tablet') {
                    top: rem(88px) !important;
                }
            }
        }
    }

    &__slide {
        margin: 0 rem($spacer-xs);

        img {
            margin: 0 auto;
        }

        .title {
            margin-bottom: rem(32px);
            text-align: center;
            font-weight: 600;
            font-family: $secondary-fonts;
        }

        &--video {
            position: relative;

            .player__btn {
                position: absolute;
                height: rem(191px);
            }

            video {
                border-radius: rem(12px);
                height: 100%;
            }

            img {
                position: absolute;
                top: 0;
            }

            .title {
                position: relative;
                top: rem(191px);
            }
        }
    }

    &__desc {
        margin-bottom: rem($spacer-m2);
        font-size: rem(20px);
        font-weight: 500;
        color: $dark-grey;
        text-align: center;

        &--legende {
            text-align: right;
            font-size: 12px;
            color: $grey;
            margin-bottom: rem($spacer-s);
        }
    }

    // Custom controls (arrows and pagination)
    &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        color: $dark-grey;
    }

    &__prev,
    &__next {
        display: none;

        &:hover,
        &:focus {
            &:not([disabled]) {
                color: $white;
                background-color: #3351E0;

                span {
                    &[class*='icon-arrow'] {
                        &:before {
                            color: $white;
                        }
                    }
                }
            }
        }

        @include media('>=tablet') {
            color: currentColor;
            position: absolute;
            top: 40%;
            transform: translateY(-40%);
            left: 0;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 1px solid $light-grey;
            background-color: $white;
            transition: .2s;
            z-index: 3;
            padding: 0;

            span {
                &[class*='icon-arrow'] {
                    &:before {
                        font-size: 35px;
                        color: $blue;
                        line-height: unset;
                    }
                }
            }
        }
    }

    &__next {
        left: auto;
        right: 0;
    }

    &__dots {
        .slick-dots {
            position: static;
            display: flex;
            align-items: center;
            z-index: auto;
            margin-bottom: rem(2px);


            li {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: rem(3px);
                padding: 0;
                width: rem(26px);
                height: rem(26px);
                border-radius: 50%;
                background-color: transparent;
                opacity: 1;
                cursor: pointer;
                transition: .2s;

                &:before {
                    content: '';
                    left: rem(8px);
                    top: rem(13px);
                    width: rem(10px);
                    height: rem(10px);
                    border-radius: 50%;
                    background-color: $blue10;
                    transition: .2s;
                }

                &.slick-active {
                    &:before {
                        background-color: $color-primary;
                    }

                    &:after {
                        transform: scale(1);
                    }
                }
            }

            button {
                width: rem(26px);
                height: rem(26px);
                font-size: 0;
                appearance: none;
                background: transparent;
                border: 0;
                border-radius: 50%;
            }
        }
    }


    .slick-slide {
        // visibility: hidden;

        &.slick-current {
            visibility: visible;
        }

        @include media('>=tablet') {
            visibility: visible;
        }

        > div {
            display: flex;
        }
    }
}

#{$c}--half {
    position: relative;
    padding: rem($spacer-m2) 0;

    @include media('>=tablet') {
        padding: rem($spacer-xxl) 0;
    }

    @include media('>=medium') {
        padding: rem(96px) 0;
    }

    .slick-slider {
        &:before,
        &:after {
            display: none;
        }
    }

    .site-content {
        display: flex;
        flex-direction: column;

        @include media('>=tablet') {
            flex-direction: row;
        }

        .half {
            &:first-of-type {
                margin-bottom: rem($spacer-m) ;
            }

            @include media('>=tablet') {
                width: 50%;
                margin-bottom: 0;
            }

            h2 {
                margin-bottom: 0;
                text-align: center;

                @include media('>=tablet') {
                    margin-bottom: rem($spacer-m2);
                    text-align: left;
                }
            }

            .legend {
                position: absolute;
                bottom: rem(-10px);
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                width: 100%;

                @include media('>=tablet') {
                    text-align: left;
                    position: relative;
                    left: auto;
                    bottom: auto;
                    transform: none;
                }
            }
        }

        #{$c} {
            margin-left: rem(-28px);
            margin-right: rem(-28px);
            position: relative;

            @include media('>=tablet') {
                position: absolute;
                right: 0;
            }

            &__slide {
                width: rem(385px);
                margin: 0 rem($spacer-xxs);
            }

            &-logo {
                &__box {
                    margin-bottom: rem($spacer-xxs);
                }
            }
        }
    }
}

.bk-custom-slider {
    &__controls {
        visibility: visible;
        width: 100%;
        border: 1px solid $blue20;
        border-radius: rem(4px);
        margin-top: 0;

        @include media('>mobile') {
            top: rem(-$spacer-l);
        }

        @include media('>=tablet') {
            visibility: hidden;
        }
    }

    &__dots {
        @extend %no-styled-list;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        width: 100%;
        padding: 0 rem(6px);
        margin: 0;

        .custom-dot {
            width: 100%;
            height: auto;
            margin: rem(6px) 0;

            &:before {
                display: none;
            }

            &__item {
                width: 100%;
                border-radius: rem(4px);
                padding: rem(8px) rem(14px);
                color: $color-primary;
                text-decoration: none;
                text-align: center;
            }

            &--active {
                .custom-dot__item {
                    background-color: $color-primary;
                    color: $white;
                }
            }
        }


    }
}

.slider-custom {
    .bk-slider {
        &__controls {
            margin: rem($spacer-s) 0;
        }
    }
}

.slider-filtres {
    &.js-slider--mobile {
        .js-tabs {
            margin-left: auto;

            .slick-slide {
                div {
                    width: 100%;

                    @include media('>=tablet') {
                        width: auto;
                    }
                }
            }
        }
    }
}
// .slider-custom {
//     position: relative;
//     #{$c} {
//         &__controls {
//             visibility: visible;
//             width: 100%;
//             border: 1px solid $blue20;
//             border-radius: rem(4px);
//             margin-top: 0;
//             position: absolute;
//             top: rem(-$spacer-xxl);
//             left: 50%;
//             transform: translateX(-50%);

//             @include media('>mobile') {
//                 top: rem(-$spacer-l);
//             }

//             @include media('>=tablet') {
//                 visibility: hidden;
//             }

//         }

//         &__dots {
//             width: 100%;

//             .slick-dots {
//                 justify-content: space-around;
//                 padding: 0 rem(8px);
//                 margin-bottom: 0;

//                 li {
//                     width: 100%;
//                     height: auto;

//                     &:before {
//                         display: none;
//                     }
//                 }

//                 .pager__item {
//                     width: 100%;
//                     border-radius: rem(4px);
//                     padding: rem(8px) rem(14px);
//                     color: $color-primary;
//                     text-decoration: none;
//                     text-align: center;
//                 }

//                 .slick-active {
//                     .pager__item {
//                         background-color: $color-primary;
//                         color: $white;
//                     }
//                 }
//             }
//         }
//     }
// }
