.modal-hidden {
    display: none !important;
}

dialog {
    display: block;
    border: 0;
}

/* enlève le scroll de la page quand la modale est ouverte */
.no-scroll {
    overflow: hidden;
}

/* l’overlay couvre tout */
.simple-modal-overlay,
.simple-animated-modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($dark-grey, .8);
    opacity: .8;
    z-index: 666;
    cursor: pointer;
    text-indent: -9000px;

    &[data-background-click='disabled'] {
        cursor: auto;
    }
}

.simple-animated-modal-overlay {
    animation: fadewhite ease .5s 1 normal;
}

@keyframes fadewhite {
    0% {
        opacity: 0;
    }

    100% {
        opacity: .8;
    }
}

/* modales */
.simple-modal,
.simple-animated-modal {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 5vh;
    max-width: rem(1305px);
    width: 90vw;
    max-height: 90vh;
    border: 1px solid $color-secondary;
    background: #fff;
    z-index: 667;
    padding: rem(20px);
    right: auto;
    overflow: auto;
}

.simple-animated-modal-title {
    margin-bottom: rem(25px);
    font-size: rem(30px);
    line-height: normal;
}

.simple-modal-close,
.simple-animated-modal-close {
    float: right;
    color: $color-secondary;
    background: transparent;
    border: 0;
    font: inherit;
    padding: rem(10px);
    font-size: rem(25px);
    cursor: pointer;
    overflow: hidden;

    &::before {
        @include icomoon;
        display: block;
        position: relative;
        content: $icon-close;
        transition: .3s ease-out;
    }

    .simple-animated-modal-close__text {
        position: absolute;
        left: -5000px;
    }

    &:hover,
    &:focus {
        &::before {
            transform: rotate(90deg);
        }
    }
}

.simple-modal-close {
    &:focus,
    &:hover,
    &:active {
        outline: 1px dotted #fff;
    }

    &:hover,
    &:active {
        background: $color-primary;
    }
}

.simple-animated-modal {
    animation: apparition ease .5s 1 normal;
}

@keyframes apparition {
    0% {
        margin-top: 10vh;
    }

    100% {
        margin-top: 0;
    }
}
