$a: '.bk-tableau';

#{$a} {
    padding: rem(40px);
    border: 1px solid $blue20;
    border-radius: rem(12px);

    &__row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &:not(:last-of-type) {
            border-bottom: 1px solid $blue20;
            margin-bottom: rem(40px);
        }

        @include media('>=tablet') {
            flex-direction: row;
        }
    }

    &__cell {
        width: 100%;

        .title {
            margin-bottom: rem($spacer-xxs);
            color: $color-text;
        }

        .content {
            max-width: rem(225px);
            font-size: rem(16px);
            margin-bottom: rem($spacer-m);

            @include media('>=tablet') {
                margin-bottom: 0;
            }

            li {
                font-size: rem(16px);

                &::before {
                    color: $color-text;
                }
            }
        }
    }
}
